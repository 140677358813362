import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
    @Input() title;
    @Input() left: string;
    @Input() right: string;
    @Input() leftText: string;
    @Input() rightText: string;
    @Input() theme: string;
    @Input() noPadding: boolean = false;
    @Output() leftClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() rightClicked: EventEmitter<any> = new EventEmitter<any>();
}
