import { Component, OnInit } from '@angular/core';
import { BaseClass } from '../../util/base-class';
import { AuthService } from '../../services/auth/auth.service';
import { ANALYTICS } from '../../const/analytics.const';
import { LoadingController, NavController } from '@ionic/angular';
import { DataService } from '../../services/data/data.service';
import { UserService } from '../../services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics';
import { LogoutService } from '../../services/logout/logout.service';
import { environment } from '../../../environments/environment';
import { OriginService } from '../../services/origin/origin.service';
import { NamespaceSelectionService } from '../../services/namespace-selection/namespace-selection.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-namespace-chooser',
    templateUrl: './namespace-chooser.page.html',
    styleUrls: ['./namespace-chooser.page.scss'],
})
export class NamespaceChooserPage extends BaseClass implements OnInit {
    tokens = [];
    logoUrl;
    fromProfile = false;
    private subscriptions = [];

    constructor(
        private authService: AuthService,
        private loadingController: LoadingController,
        private dataService: DataService,
        private navController: NavController,
        private userService: UserService,
        private translate: TranslateService,
        private analytics: AnalyticsService,
        private logoutService: LogoutService,
        private originService: OriginService,
        private namespaceSelectionService: NamespaceSelectionService,
        private route: ActivatedRoute
    ) {
        super('NamespaceChooserPage');

        this.logoUrl = `${environment.apiBase}namespaces/image/logo.png?X-App-Origin=${this.originService.origin}`;

        this.subscriptions.push(
            this.route.queryParams.pipe(take(1)).subscribe((params) => {
                if (params) {
                    this.fromProfile = Boolean(params.fromProfile);
                    this.tokens = params.tokens ? JSON.parse(params.tokens) : [];
                }
            })
        );
    }

    async ngOnInit() {
        if (!this.tokens?.length) {
            const loading = await this.loadingController.create();
            await loading.present();

            this.tokens = await this.namespaceSelectionService.getNamespaceTokens();

            await loading.dismiss();
        }
    }

    async signIn(token) {
        const loading = await this.loadingController.create();
        await loading.present();

        if (this.authService.nsSubscription) {
            await this.logoutService.doLogout(true);
        }

        await this.authService.signIn(token, true);

        await loading.dismiss();

        await this.navController.navigateRoot('start');

        await this.namespaceSelectionService.initAfterLogin();
        this.analytics.trackEvent(ANALYTICS.ACTIONS.LOGIN);

        await this.navController.navigateRoot('main');
    }

    async closePage() {
        await this.navController.navigateBack('main/profile');
    }

    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }

        this.subscriptions = [];
    }
}
