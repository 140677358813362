<ion-row class="h-100 ion-justify-content-center ion-align-items-center">
    <ion-col class="ion-text-center">
        <ion-icon
            name="{{ icon }}"
            (click)="navigate()"
            [class]="size.toString()"
            [class.primary]="routerLink"></ion-icon>
        <container-element [ngSwitch]="size">
            <h1 *ngSwitchCase="NoDataComponentSize.LARGE">
                {{ text | translate }}
            </h1>
            <h2 *ngSwitchCase="NoDataComponentSize.MEDIUM">
                {{ text | translate }}
            </h2>
            <h3 *ngSwitchCase="NoDataComponentSize.SMALL">
                {{ text | translate }}
            </h3>
        </container-element>
    </ion-col>
</ion-row>
