<app-basic-card (expansion)="onExpand()" [header]="'ticket.appointments-title'">
    <div class="warning" *ngIf="!data.appointments.length">
        <ion-icon name="warning" class="warning"></ion-icon>
        <span class="ion-text-left info">{{ 'ticket.appointments-pending' | translate }}</span>
    </div>
    <ion-row *ngFor="let appointment of data.appointments" class="ion-justify-content-between">
        <ion-label class="text-light">
            {{ 'days.' + appointment.day | translate }}
        </ion-label>
        <ion-label class="text-light">
            {{ 'days.' + appointment.time | translate }}
        </ion-label>
    </ion-row>
</app-basic-card>
