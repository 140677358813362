import { Pipe, PipeTransform } from '@angular/core';
import { TicketNode } from '../../models/ticket-node';
import { CustomTranslationPipe } from '../custom-translation/custom-translation';
import { STEP_TYPE } from '../../const/ticket.const';
import { UserService } from '../../services/user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { TicketGeneratorService } from '../../services/ticket-generator/ticket-generator.service';

@Pipe({
    name: 'managerMessage',
})
export class ManagerMessagePipe implements PipeTransform {
    constructor(
        private customTranslation: CustomTranslationPipe,
        private userService: UserService,
        private translate: TranslateService,
        private ticketGenerationService: TicketGeneratorService
    ) {}

    transform(textObj: TicketNode | TicketNode[], manager, data?, invalid?: boolean) {
        const constructed = {
            id: null,
            type: STEP_TYPE.TEXT,
            wasSent: false,
            options: [],
            text: '',
            profilePicture: manager ? manager.profilePicture : undefined,
            invalid: invalid || false,
        };

        if (Array.isArray(textObj)) {
            // options
            const objects = textObj as TicketNode[];
            if (objects.length) {
                if (objects[0].id === STEP_TYPE.APPOINTMENT) {
                    constructed.type = STEP_TYPE.APPOINTMENT;
                } else {
                    // selection or regular ticket tree
                    constructed.type = STEP_TYPE.SELECTION;
                    for (const obj of objects) {
                        constructed.options.push({
                            wasSent: false,
                            id: obj.id,
                            text: this.customTranslation.transform(obj.text),
                            hidden: obj.hidden,
                        });
                    }
                }
            }
        } else if (textObj.id === STEP_TYPE.EMAIL) {
            constructed.id = textObj.id;
            const existingEmail = this.userService?.user?.email;
            if (existingEmail) {
                constructed.text = this.translate.instant('ticket-create.what-email-contact', { email: existingEmail });
            } else {
                constructed.text = this.translate.instant('ticket-create.what-email-contact-none');
            }
        } else if (textObj.id === STEP_TYPE.PHONE) {
            constructed.id = textObj.id;
            const existingNumber = this.userService?.user?.mobile;
            if (existingNumber) {
                constructed.text = this.translate.instant('ticket-create.what-phone-contact', { tel: existingNumber });
            } else {
                constructed.text = this.translate.instant('ticket-create.what-phone-contact-none');
            }
        } else if (textObj.id === STEP_TYPE.DUPLICATE) {
            const similarTickets = this.ticketGenerationService.getSimilarTickets();
            // this step is only reached when there are duplicates
            if (similarTickets.length === 1) {
                if (similarTickets[0].description) {
                    constructed.text = this.translate.instant('ticket-create.duplicate-warning-single-with-desc', {
                        description: this.makeDuplicateDescriptionsText([similarTickets[0].description]),
                    });
                } else {
                    constructed.text = this.translate.instant('ticket-create.duplicate-warning-single-no-desc');
                }
            } else {
                if (similarTickets.some((ticket) => ticket.description)) {
                    constructed.text = this.translate.instant('ticket-create.duplicate-warning-multiple-with-desc', {
                        description: this.makeDuplicateDescriptionsText(
                            similarTickets.map((ticket) => ticket.description)
                        ),
                    });
                } else {
                    constructed.text = this.translate.instant('ticket-create.duplicate-warning-multiple-no-desc');
                }
            }
        } else {
            constructed.id = textObj.id;
            constructed.text = this.customTranslation.transform(textObj.text);
        }
        return constructed;
    }

    private makeDuplicateDescriptionsText(items) {
        let description = '';
        for (const it of items) {
            description += `- ${it}<br>`;
        }
        return description;
    }
}
