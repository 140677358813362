<ion-row class="desktop-view">
    <div class="container">
        <div class="step" *ngFor="let step of steps; let i = index">
            <div class="circle" [ngClass]="step.active ? 'circle-active' : 'circle-inactive'">
                <p *ngIf="!step.complete">{{ i + 1 }}</p>

                <ion-icon style="color: white" *ngIf="step.complete" name="checkmark"></ion-icon>
            </div>

            <div class="desc">
                <p>{{ step.label }}</p>
            </div>

            <div class="line" *ngIf="i + 1 !== stepCount"></div>
        </div>
    </div>
</ion-row>

<ion-row class="mobile-view">
    <div class="canvas-container">
        <canvas #canvas></canvas>
        <div class="numbers">
            {{ currentStep + 1 }} /
            {{ steps.length }}
        </div>
    </div>

    <ion-col class="text">
        <ion-row class="current">{{ steps[currentStep].label }}</ion-row>
        <ion-row *ngIf="steps.length > currentStep + 1" class="next">
            {{ 'stepper.next' | translate }}: {{ steps[currentStep + 1].label }}
        </ion-row>
    </ion-col>
</ion-row>
