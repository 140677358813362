<ion-card class="accent-border" [ngClass]="customClass">
    <ion-card-header (click)="toggleExpansion()">
        <ion-card-subtitle>
            {{ header | translate }}
        </ion-card-subtitle>
        <ion-button size="small" fill="clear" class="button-right">
            <ion-icon slot="icon-only" [ngClass]="{ rotated: expanded }" name="arrow-down" mode="ios"></ion-icon>
        </ion-button>
    </ion-card-header>
    <ion-card-content class="separator-top" [ngClass]="{ visible: expanded }">
        <ng-content></ng-content>
    </ion-card-content>
</ion-card>
