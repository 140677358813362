import { OtherPersonInfoComponent } from './other-person-info.component';
import { NgModule } from '@angular/core';
import { ListWithChipComponentModule } from '../../components/organisms/list-with-chip/list-with-chip.component.module';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { RegexMatchDirectiveModule } from 'src/app/directives/regex-match/regex-match.module';

@NgModule({
    declarations: [OtherPersonInfoComponent],
    exports: [OtherPersonInfoComponent],
    imports: [
        ComponentsSharedModule,
        ListWithChipComponentModule,
        FormsModule,
        ReactiveFormsModule,
        TopbarComponentModule,
        RegexMatchDirectiveModule,
    ],
})
export class OtherPersonInfoComponentModule {}
