<ion-row class="fullscreen default-bg" *ngIf="userService.user">
    <img *ngIf="secondarySplashLogo" [src]="secondarySplashLogo" />
</ion-row>
<ion-row *ngIf="origin === 'woonig.app'" class="fullscreen default-fullscreen-bg-color">
    <img class="looping" src="assets/imgs/default_splash_animated.gif" />
    <img class="static" src="assets/imgs/default_splash_static.jpg" />
</ion-row>
<ion-row *ngIf="userService.user" class="w-100 ion-padding spinner-row ion-justify-content-center">
    <ion-spinner></ion-spinner>
</ion-row>
