import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import TimeAgo from 'javascript-time-ago';
import english from 'javascript-time-ago/locale/en';

@Pipe({
    name: 'timeAgo',
    pure: true,
})
export class TimeAgoDatePipe implements PipeTransform {
    constructor(private userService: UserService) {}

    async transform(value: any) {
        if (!value) {
            return '';
        }
        const userLanguage = this.userService.userLanguage$.value;

        try {
            const lang = await import('node_modules/javascript-time-ago/locale/' + userLanguage + '.json');
            TimeAgo.addLocale(lang);
            return new TimeAgo(userLanguage).format(new Date(value));
        } catch (error) {
            TimeAgo.addLocale(english);
            return new TimeAgo('en').format(new Date(value));
        }
    }
}
