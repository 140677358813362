import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import { Language } from '../../models/language';
import { BaseDataService } from '../../util/base-data-service';
import * as clonedeep from 'lodash.clonedeep';

@Injectable({
    providedIn: 'root',
})
export class LanguageService implements BaseDataService {
    private languages: Language[] = [];

    constructor(private db: AngularFirestore) {}

    searchLanguage(searchTerm: string = ''): Language[] {
        if (!searchTerm.trim()) {
            return this.languages;
        }

        searchTerm = searchTerm.toLowerCase();

        return this.languages.filter((lang) => {
            const currentLang: any = clonedeep(lang);

            for (const key in currentLang) {
                currentLang[key] = currentLang[key].toLowerCase();
            }

            return (
                currentLang.iso639_1.includes(searchTerm) ||
                currentLang.iso639_2.includes(searchTerm) ||
                currentLang.name.includes(searchTerm) ||
                currentLang.nativeName.includes(searchTerm)
            );
        });
    }

    getFullLanguageName(langShort: string): string {
        const lang = this.languages.find((langObj) => {
            return langObj.iso639_1 === langShort;
        });
        return lang ? lang.nativeName : null;
    }

    initialize() {
        return this.db
            .collection('languages')
            .doc(environment.firebase.languageDocId)
            .get()
            .toPromise()
            .then((lang) => {
                this.languages = lang.get('all').sort((a, b) => {
                    if (a.iso639_1 > b.iso639_1) {
                        return 1;
                    }
                    return -1;
                });
            });
    }

    terminate() {
        this.languages = [];
    }
}
