import { LegalDocumentsService } from '../../../services/legal-documents/legal-documents.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import * as clonedeep from 'lodash.clonedeep';
import { BrowserService } from '../../../services/browser/browser.service';
import { NamespaceService } from 'src/app/services/namespace/namespace.service';
import { UserService } from 'src/app/services/user/user.service';
import { DocumentUtilService } from 'src/app/services/document-util/document-util.service';

@Component({
    selector: 'app-legal-documents-accept',
    templateUrl: './legal-documents-accept.component.html',
    styleUrls: ['./legal-documents-accept.component.scss'],
})
export class LegalDocumentsAcceptComponent implements OnInit, OnDestroy {
    @Input() excludedDocuments = [];
    @Input() showOptional = true;
    @Input() options = {
        modalStyle: false,
    };

    @Output() change: EventEmitter<any> = new EventEmitter();

    legalCheckboxes: any = {
        privacyPolicy: false,
        userTermsOfUse: false,
    };

    currentLegalDocuments: any = {
        privacyPolicy: null,
        userTermsOfUse: null,
    };

    private subscriptions: Subscription[] = [];

    constructor(
        private legalDocumentsService: LegalDocumentsService,
        private browserService: BrowserService,
        private namespaceService: NamespaceService,
        private userService: UserService,
        private documentUtilService: DocumentUtilService
    ) {
        const user = this.userService.user$.value;
        const userModules = this.userService.moduleSubscription?.value || [];
        if (user.type === 'owner' && (userModules.includes('assembly') || userModules.includes('assemblyV2'))) {
            this.legalCheckboxes['evoteTermsOfUse'] = false;
            this.currentLegalDocuments['evoteTermsOfUse'] = null;
        }
    }

    /**
     * Initialize needed data for component
     * Get current legal documents from database
     * Compute legalCheckboxes
     */
    async ngOnInit() {
        this.legalCheckboxes = this.removeExcludedDocumentsFromObject(this.legalCheckboxes);

        this.subscriptions.push(
            this.legalDocumentsService.getLatestLegalDocumentsObservable().subscribe(async (legalDocuments) => {
                const domain = await this.namespaceService.getDomain();
                const object = this.removeExcludedDocumentsFromObject(legalDocuments[domain]);

                for (const key of Object.keys(object)) {
                    if (this.currentLegalDocuments.hasOwnProperty(key)) {
                        this.currentLegalDocuments[key] = object[key];
                    }
                }

                this.onChange();
            })
        );
    }

    /**
     * On change of checkboxes or slide-toggles
     * Will emit changes to parent component
     *
     * @returns none
     */
    onChange() {
        if (this.validateCheckboxes()) {
            const accepted = Object.keys(this.legalCheckboxes).filter((cb) => this.legalCheckboxes[cb]);
            return this.change.emit({
                legalDocuments: this.currentLegalDocuments,
                accepted: [...new Set([...accepted, ...this.excludedDocuments])],
                allAccepted: true,
            });
        }

        return this.change.emit({
            legalDocuments: this.currentLegalDocuments,
            allAccepted: false,
        });
    }

    /**
     * Open legal document (PDF) in InAppBrowser
     *
     * @param url url to PDF legal document as string
     */
    async openLegalDocumentByURL(url: string) {
        const document = {
            file: url,
            name: 'Legaldocument.pdf',
            mimetype: 'application/pdf',
        };

        await this.documentUtilService.openDocument(document);
        this.browserService.open(url, '_blank');
    }

    /**
     * Validate if all checkboxes are set
     *
     * @returns all checkboxes set (boolean)
     */
    private validateCheckboxes() {
        const object = clonedeep(this.legalCheckboxes);
        delete object.evoteTermsOfUse;
        return Object.values(object).every(Boolean);
    }

    /**
     * Helper function to remove certain fields from object
     *
     * @param object object where fields should be removed
     * @returns object with removed fields
     */
    private removeExcludedDocumentsFromObject(object: any) {
        const clonedObject = clonedeep(object);

        if (this.excludedDocuments?.length) {
            for (const exludedDocument of this.excludedDocuments) {
                delete clonedObject[exludedDocument];
            }
        }

        return clonedObject;
    }

    /**
     * Clear all subscriptions before destroying component to prevent unused open subscriptions
     *
     */
    ngOnDestroy() {
        for (const subscription of this.subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }
}
