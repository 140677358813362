import { Injector } from '@angular/core';

export class AppInjector {
    private static injector: Injector;
    private static promiseResolve;

    static setInjector(injector: Injector) {
        AppInjector.injector = injector;
        if (AppInjector.promiseResolve) {
            AppInjector.promiseResolve(injector);
        }
    }

    static getInjector(): Promise<Injector> {
        if (this.injector) {
            return Promise.resolve(AppInjector.injector);
        }
        return new Promise((resolve) => {
            AppInjector.promiseResolve = resolve;
        });
    }
}
