import { NgModule } from '@angular/core';
import { OriginalTextComponent } from './original-text';
import { ComponentsSharedModule } from '../../components.shared.module';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [OriginalTextComponent],
    exports: [OriginalTextComponent],
})
export class OriginalTextComponentModule {}
