<app-topbar
    [title]="'general.rating' | translate"
    [left]="'close'"
    (leftClicked)="modalController.dismiss(false)"></app-topbar>

<ion-content class="ion-padding">
    <ion-row *ngIf="managerRating" class="ion-justify-content-center ion-align-items-center">
        <ion-row class="ion-justify-content-center">
            <div class="w-100">
                {{ 'rating.manager' | translate }}
            </div>
            <br />
            <div class="w-100">
                <div>
                    <ion-button
                        *ngFor="let starIdx of [1, 2, 3, 4, 5]"
                        (click)="managerRating.rating = starIdx"
                        fill="clear">
                        <ion-icon
                            [name]="starIdx <= managerRating.rating ? 'star' : 'star-outline'"
                            slot="icon-only"></ion-icon>
                    </ion-button>
                </div>
            </div>
            <ion-row class="w-100">
                <ion-textarea
                    [placeholder]="
                        isUpdate ? (managerRating?.comment | customTranslation) : ('rating.placeholder' | translate)
                    "
                    [(ngModel)]="comment.managerRating"></ion-textarea>
            </ion-row>
        </ion-row>
    </ion-row>
    <ion-row *ngIf="providerRating?.providerId" class="ion-justify-content-center ion-align-items-center">
        <ion-row class="ion-justify-content-center">
            <div class="w-100">
                {{ 'rating.service-provider' | translate }}
            </div>
            <br />
            <div class="w-100">
                <div>
                    <ion-button
                        *ngFor="let starIdx of [1, 2, 3, 4, 5]"
                        (click)="providerRating.rating = starIdx"
                        fill="clear">
                        <ion-icon
                            [name]="starIdx <= providerRating.rating ? 'star' : 'star-outline'"
                            slot="icon-only"></ion-icon>
                    </ion-button>
                </div>
            </div>
            <ion-row class="w-100">
                <ion-textarea
                    [placeholder]="
                        isUpdate ? (providerRating?.comment | customTranslation) : ('rating.placeholder' | translate)
                    "
                    [(ngModel)]="comment.providerRating"></ion-textarea>
            </ion-row>
        </ion-row>
    </ion-row>
</ion-content>
<ion-footer class="ion-padding">
    <ion-button expand="block" (click)="finishRatings()">
        {{ 'ticket.detail-finish' | translate }}
    </ion-button>
</ion-footer>
