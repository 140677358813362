<ion-toolbar [color]="theme" [class.noPadding]="noPadding">
    <ion-buttons slot="start" *ngIf="left || leftText">
        <ion-button (click)="leftClicked.emit()" *ngIf="left">
            <ion-icon slot="icon-only" [name]="left"></ion-icon>
        </ion-button>
        <ion-button fill="clear" (click)="leftClicked.emit()" *ngIf="leftText">
            {{ leftText }}
        </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="title"> </ion-title>
    <ion-buttons slot="end" *ngIf="right || rightText">
        <ion-button (click)="rightClicked.emit()" *ngIf="right">
            <ion-icon slot="icon-only" [name]="right"></ion-icon>
        </ion-button>
        <ion-button fill="clear" (click)="rightClicked.emit()" *ngIf="rightText">
            {{ rightText }}
        </ion-button>
    </ion-buttons>
</ion-toolbar>
