<ion-grid class="ion-no-padding">
    <ion-row>
        <ng-container *ngFor="let image of images; index as index">
            <ion-col [size]="colsize" *ngIf="expanded || index < 2">
                <img
                    (load)="loadedImage(index)"
                    loading="lazy"
                    [ngClass]="{
                        'main-image': images.length === 1,
                        'multi-images ': images.length > 1
                    }"
                    [src]="image.file"
                    (click)="openPhotoViewer(index)" />

                <ion-row
                    class="skeleton-image-overlay ion-justify-content-center ion-align-items-center"
                    *ngIf="!image.loaded">
                    <ion-skeleton-text animated="true"></ion-skeleton-text>
                </ion-row>

                <ion-row
                    (click)="expanded = true"
                    class="dark-image-overlay ion-justify-content-center ion-align-items-center"
                    *ngIf="images.length > 2 && index === 1 && !expanded && image.loaded">
                    <ion-label class="color-wh"> +{{ images.length - 2 }} </ion-label>
                </ion-row>
            </ion-col>
        </ng-container>
    </ion-row>
    <ion-row *ngIf="expanded">
        <ion-button class="w-100" (click)="expanded = false" expand="block">
            {{ 'general.show-less' | translate }}
        </ion-button>
    </ion-row>
</ion-grid>
