import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-basic-card',
    templateUrl: './basic-card.component.html',
    styleUrls: ['./basic-card.component.scss'],
})
export class BasicCardComponent {
    @Input() header;
    @Input() customClass = '';
    @Input() customIcon;
    @Input() expanded = false;
    @Output() expansion: EventEmitter<boolean> = new EventEmitter();

    ngOnDestroy() {
        this.expansion.emit(false);
    }

    toggleExpansion() {
        this.expanded = !this.expanded;
        this.expansion.emit(this.expanded);
    }
}
