import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { PhotoViewerComponent } from './photo-viewer.component';

@NgModule({
    declarations: [PhotoViewerComponent],
    exports: [PhotoViewerComponent],
    imports: [ComponentsSharedModule, TopbarComponentModule],
})
export class PhotoViewComponentModule {}
