<ion-header>
    <app-topbar
        [title]="('ticket-storno.title' | translate) + ' #' + ticket?.ticketNo"
        [left]="'close'"
        (leftClicked)="modalController.dismiss()"></app-topbar>
</ion-header>

<ion-content>
    <app-chat-view
        *ngIf="chatMessages?.length"
        [messages]="chatMessages"
        [inputOptions]="inputOptions"
        (textEntered)="textEntered($event)"
        (optionSelected)="optionSelected($event)"></app-chat-view>
</ion-content>
