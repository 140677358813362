<ion-content [scrollY]="false" color="light">
    <div class="topbar-in-content">
        <app-topbar
            [title]="'ticket-create.header' | translate"
            [left]="'close'"
            (leftClicked)="closePopup()"></app-topbar>
    </div>
    <div class="scroll">
        <app-chat-view
            *ngIf="chatMessages?.length"
            [messages]="chatMessages"
            [inputOptions]="inputOptions"
            (optionSelected)="optionSelected($event)"
            (textEntered)="textEntered($event)"
            (imagesUploaded)="imagesUploaded($event)"
            (imageDeleted)="imageDeleted($event)"></app-chat-view>
    </div>
</ion-content>
