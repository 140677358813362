import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { TicketStatus } from 'src/app/models/ticket-status.enum';

@Component({
    selector: 'app-ticket-note',
    templateUrl: './ticket-note.component.html',
    styleUrls: ['./ticket-note.component.scss'],
})
export class TicketNoteComponent implements OnInit {
    @Input() note: any;
    @Input() mode: 'blackboard' | 'ticket';
    @Input() status;
    @Input() ticket;

    isMobileDevice: boolean = window.innerWidth < 768;
    rows: number = this.isMobileDevice ? 20 : 4;
    allowProviderNote = false;

    constructor(public modalController: ModalController) {}

    ngOnInit() {
        this.note.description = this.note.text ? this.note.text.original.value : '';

        this.allowProviderNote =
            this.mode === 'blackboard' ||
            (this.mode === 'ticket' &&
                [
                    TicketStatus.CM_DATE_SET,
                    TicketStatus.CM_FINISHED,
                    TicketStatus.CM_IN_WORK,
                    TicketStatus.CM_OFFERS,
                    TicketStatus.CM_OFFERS_FINISHED,
                ].some((state) => this.wasTicketInState(state)));
    }

    wasTicketInState(state: string): boolean {
        if (!this.ticket) {
            return false;
        }

        let wasInState = false;
        for (const status of Object.keys(this.ticket.statusUser)) {
            if (status === state && this.ticket.statusUser[status].value) {
                wasInState = true;
            }
        }
        return wasInState;
    }

    onDocumentsChange(documents: { imgs: any[]; pdfs: any[] }) {
        this.note.documents = documents;
    }

    async saveNote(type: string) {
        this.note.type = type;
        await this.modalController.dismiss({
            note: this.note,
        });
    }
}
