import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { ChecklistItemComponent } from './checklist-item.component';
import { TicketTextComponentModule } from '../../organisms/ticket-text/ticket-text.component.module';

@NgModule({
    imports: [ComponentsSharedModule, TicketTextComponentModule],
    declarations: [ChecklistItemComponent],
    exports: [ChecklistItemComponent],
})
export class ChecklistItemComponentModule {}
