<ion-list class="ion-no-margin">
    <ion-list-header *ngIf="header">
        <ion-label>{{ header }}</ion-label>
    </ion-list-header>
    <ion-item
        *ngFor="let document of documents; let i = index"
        [ngClass]="{ 'no-padding': noPadding }"
        button
        [lines]="i === documents.length - 1 ? 'none' : lines"
        (click)="openDocument(document)">
        <ion-icon [name]="document.name | iconForFileName" slot="start"></ion-icon>
        <ion-label>
            {{ document.name }}
        </ion-label>
    </ion-item>
</ion-list>
