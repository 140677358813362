import { ObjectDisplayFormatPipe } from './object-display-format/object-display-format';
import { NgModule } from '@angular/core';
import { CustomTranslationPipe } from './custom-translation/custom-translation';
import { KeepLinesPipe } from './keep-lines/keep-lines';
import { ShortenPipe } from './shorten/shorten';
import { CustomDatePipe } from './custom-date/custom-date';
import { IconForFileNamePipe } from './icon-for-file-name/icon-for-file-name';
import { UserStatusPipe } from './user-status/user-status';
import { TranslateModule } from '@ngx-translate/core';
import { ManagerMessagePipe } from './chat-message/manager-message';
import { UserMessagePipe } from './chat-message/user-message';
import { LocaleDatePipe } from './locale-date/locale-date.pipe';
import { FilePreviewPipe } from './file-preview/file-preview';
import { TimeAgoDatePipe } from './time-ago/time-ago.pipe';
import { ErpFieldsPipe } from './erpFields/erp-fields';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';

@NgModule({
    declarations: [
        CustomTranslationPipe,
        KeepLinesPipe,
        ShortenPipe,
        CustomDatePipe,
        IconForFileNamePipe,
        UserStatusPipe,
        ManagerMessagePipe,
        UserMessagePipe,
        CustomTranslationPipe,
        LocaleDatePipe,
        FilePreviewPipe,
        TimeAgoDatePipe,
        ObjectDisplayFormatPipe,
        ErpFieldsPipe,
        SafeHtmlPipe,
    ],
    imports: [TranslateModule],
    exports: [
        CustomTranslationPipe,
        KeepLinesPipe,
        ShortenPipe,
        CustomDatePipe,
        IconForFileNamePipe,
        UserStatusPipe,
        ManagerMessagePipe,
        UserMessagePipe,
        CustomTranslationPipe,
        LocaleDatePipe,
        FilePreviewPipe,
        TimeAgoDatePipe,
        ObjectDisplayFormatPipe,
        ErpFieldsPipe,
        SafeHtmlPipe,
    ],
    providers: [ManagerMessagePipe, CustomTranslationPipe, UserMessagePipe, ObjectDisplayFormatPipe, FilePreviewPipe],
})
export class PipesModule {}
