<ion-header>
    <app-topbar class="ion-text-center" [title]="'force-update.title' | translate" theme="primary"></app-topbar>
</ion-header>

<ion-content>
    <div class="force-update">
        <div class="message" [innerHTML]="'force-update.message' | translate | safeHtml"></div>
        <div class="image-wrapper" *ngIf="storeUrl && storeImg">
            <a [href]="storeUrl">
                <img class="storeImg" [src]="storeImg" />
            </a>
        </div>
    </div>
</ion-content>
