<div class="grid">
    <div *ngIf="!sent" class="container">
        <ion-row class="text">
            {{ 'voice-record.preview-info' | translate }}
        </ion-row>
        <ion-row class="text"
            ><span *ngIf="!playClicked">{{ durationDisplay }}</span>
            <span *ngIf="playClicked">{{ timeDisplay }} - {{ durationDisplay }}</span>
        </ion-row>
        <ion-row class="ion-justify-content-center">
            <ion-button (click)="playPause()" class="play-button" fill="clear"
                ><ion-icon
                    class="icon"
                    slot="icon-only"
                    [name]="audioElement.paused ? 'play-circle' : 'pause-circle'"></ion-icon
            ></ion-button>
        </ion-row>
    </div>

    <div *ngIf="sent" class="container">
        <ion-row class="text ion-justify-content-center">
            {{ 'voice-record.done-info' | translate }}
        </ion-row>
        <ion-row class="ion-justify-content-center"
            ><ion-icon class="done-icon" name="checkmark-circle"></ion-icon
        ></ion-row>
    </div>

    <ion-row class="bottom-row ion-justify-content-center">
        <ion-button *ngIf="!sent" (click)="retry.emit()" color="light" class="button-side">
            {{ 'voice-record.retry' | translate }}
            <ion-icon slot="end" name="refresh"></ion-icon></ion-button
        ><ion-button *ngIf="!sent" (click)="send()" color="success" class="button-side">
            {{ 'voice-record.send' | translate }}
            <ion-icon slot="end" name="send"></ion-icon></ion-button
        ><ion-button *ngIf="sent" (click)="closeModal.emit()" color="primary" class="button-center">{{
            'voice-record.close' | translate
        }}</ion-button></ion-row
    >
</div>
<audio #audioElement style="display: none"></audio>
