<app-basic-card (expansion)="onExpansion($event)" [header]="'ticket-pictures.title'" [expanded]="expanded">
    <ion-row *ngIf="ticketStatus === TicketStatus.INIT" class="create-button-wrapper">
        <ion-col>
            <ion-fab-button (click)="onOpenAddPicturesModal()" color="primary" class="ion-float-right" size="small">
                <ion-icon name="add"></ion-icon>
            </ion-fab-button>
        </ion-col>
    </ion-row>

    <app-image-gallery
        [calledBy]="trackEventName"
        [images]="images"
        (imageDeleted)="deletingImage($event)"></app-image-gallery>

    <ion-list *ngIf="pdfs?.length">
        <ion-item *ngFor="let pdf of pdfs" (click)="openPdf(pdf)">
            <ion-icon name="document-outline" style="font-size: 34px"></ion-icon>

            <div style="padding-left: 10px">
                {{ pdf.name }}
            </div>
        </ion-item>
    </ion-list>
</app-basic-card>
