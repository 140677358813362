<ion-item lines="none" class="textarea-wrapper">
    <ion-textarea
        debounce="500"
        appAutoFocus
        [(ngModel)]="input"
        (ionChange)="getItems()"
        [placeholder]="'ticket-autocomplete-placeholder' | translate"></ion-textarea>
    <ion-fab-button [disabled]="!hasSelected" class="ion-margin-left" size="small" (click)="submit()">
        <ion-icon name="send"></ion-icon>
    </ion-fab-button>
</ion-item>
<ion-list *ngIf="items.length">
    <ion-item tappable *ngFor="let item of items" (click)="selectSearchResults(item)">
        <ion-icon name="pin-outline"></ion-icon> {{ item.searchLabel }}
    </ion-item>
</ion-list>
