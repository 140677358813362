export enum TicketStatus {
    INIT = 'INIT',
    ACCEPTED = 'ACCEPTED',
    IN_WORK = 'IN_WORK',
    OFFERS = 'OFFERS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
    DECLINED = 'DECLINED',
    CM_OFFERS = 'CM_OFFERS',
    CM_DATE_SET = 'CM_DATE_SET',
    CM_IN_WORK = 'CM_IN_WORK',
    CM_OFFERS_FINISHED = 'CM_OFFERS_FINISHED',
    CM_FINISHED = 'CM_FINISHED',
    CLOSED = 'CLOSED',
}

export enum TicketMainStates {
    INIT = 'INIT',
    ACCEPTED = 'ACCEPTED',
    IN_WORK = 'IN_WORK',
    OFFERS = 'OFFERS',
    FINISHED = 'FINISHED',
    CANCELED = 'CANCELED',
    DECLINED = 'DECLINED',
}
