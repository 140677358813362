<ion-header>
    <app-topbar [title]="'documents.header' | translate"></app-topbar>
</ion-header>
<ion-content color="light">
    <div class="profile-inner">
        <ion-row
            style="text-align: center; color: black; margin-top: 20px"
            class="h-100-l ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-no-padding ion-align-self-center">
                <ion-row class="title ion-justify-content-center">
                    <ion-col>
                        {{ 'token-documents.infotext' | translate }}
                    </ion-col>
                </ion-row>
                <ion-row class="title ion-justify-content-center">
                    <ion-col>
                        <ion-button href="{{ registrationLink }}" color="primary">{{
                            'token-profile.registration.button' | translate
                        }}</ion-button>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
    </div>
</ion-content>
