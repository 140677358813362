import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { UserService } from '../../services/user/user.service';

@Pipe({
    name: 'localeDate',
    pure: false,
})
export class LocaleDatePipe implements PipeTransform {
    constructor(private userService: UserService) {}

    transform(value: any, format?: string) {
        if (!value) {
            return '';
        }

        if (!format) {
            format = 'longDate';
        }

        if (typeof value === 'object' && value.seconds && value.nanoseconds) {
            value = new Date(value.seconds * 1000);
        }

        return this.userService.userLanguage$.value
            ? formatDate(value, format, this.userService.userLanguage$.value)
            : value;
    }
}
