import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { PdfComponent } from './pdf-viewer.component';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
    imports: [ComponentsSharedModule, TopbarComponentModule, NgxExtendedPdfViewerModule],
    declarations: [PdfComponent],
    exports: [PdfComponent, TopbarComponentModule],
})
export class PdfComponentModule {}
