import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { UserListItemComponent } from './user-list-item.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [UserListItemComponent],
    exports: [UserListItemComponent],
})
export class UserListItemComponentModule {}
