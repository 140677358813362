import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { ChatBubbleComponentModule } from '../../atoms/chat-bubble/chat-bubble.component.module';
import { ChatMessageSelectionComponent } from './chat-message-selection.component';

@NgModule({
    imports: [ComponentsSharedModule, ChatBubbleComponentModule],
    declarations: [ChatMessageSelectionComponent],
    exports: [ChatMessageSelectionComponent],
})
export class ChatMessageSelectionComponentModule {}
