import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PipesModule } from '../../pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChatViewComponentModule } from '../../components/templates/chat-view/chat-view.component.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { CreateTicketPage } from './create-ticket.page';
import { TicketEntitySelectionComponentModule } from '../../modals/ticket-entity-selection/ticket-entity-selection.component.module';

const routes: Routes = [
    {
        path: '',
        component: CreateTicketPage,
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        PipesModule,
        TranslateModule,
        ChatViewComponentModule,
        TopbarComponentModule,
        TicketEntitySelectionComponentModule,
    ],
    declarations: [CreateTicketPage],
    exports: [CreateTicketPage],
})
export class CreateTicketPageModule {}
