import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    trackView(viewName, args?) {
        console.log('TrackView', viewName, args);
    }

    trackEvent(eventName, args?) {
        console.log('TrackEvent', eventName, args);
    }
}
