import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NsUtil } from '../../util/ns';
import { TicketsService } from '../tickets/tickets.service';
import { LanguageService } from '../language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../user/user.service';
import { combineLatest } from 'rxjs';
import { Checkpoint } from '../../models/checkpoint';
import { convertFirestoreDate } from '../../util/util';
import { map } from 'rxjs/operators';

export const CHECKLISTTYPES = {
    NODECISION: 'noDecision',
    SIMPLEMORE: 'simpleMore',
    QUALIFIEDMORE: 'qualifiedMore',
    UNANIMITY: 'unanimity',
    TBD: 'tbd',
};

@Injectable({
    providedIn: 'root',
})
export class ChecklistService {
    constructor(
        private firestore: AngularFirestore,
        private ns: NsUtil,
        private ticketsService: TicketsService,
        private languageService: LanguageService,
        private translateService: TranslateService,
        private userService: UserService
    ) {}

    public getCheckpointObservable(assemblyId: string, checkpointId: string) {
        return this.firestore
            .collection(`ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/checkpoints`)
            .doc(checkpointId)
            .valueChanges();
    }

    public async buildCheckpoint(assembldyId: string, checkpoint: Checkpoint, variants = true) {
        await Promise.all(
            ['title', 'description', 'meetingNotes', 'meetingInternalNotes'].map(async (field) => {
                checkpoint[field] = await this.ticketsService.getTicketTextsByKeyAndLanguage(
                    `checkpoint.${checkpoint.id}.${field}`,
                    this.userService.user.language
                );
            })
        );
        if (variants) {
            checkpoint.variants = await this.buildVariants(assembldyId, checkpoint);
        }
        checkpoint.isBuilt = true;
        return checkpoint;
    }

    private async buildVariants(assembldyId: string, checkpoint: Checkpoint) {
        const variants = await Promise.all(
            (checkpoint.variants || []).map((variant) => {
                if (variant?.isBuilt) {
                    return this.getVariantById(assembldyId, variant.id);
                } else {
                    return this.getVariantById(assembldyId, variant);
                }
            })
        );
        return await Promise.all([
            ...variants.map(async (variant: any) => {
                variant.isBuilt = true;
                variant.active = true;
                variant.recommended = (checkpoint.recommendedVariants || []).includes(variant.id);
                variant.label = await this.ticketsService.getTicketTextsByKeyAndLanguage(
                    `variant.${variant.id}.label`,
                    this.userService.user.language
                );
                return variant;
            }),
            this.getNewVariant(),
        ]);
    }

    public getNewVariant() {
        return {
            label: '',
            active: false,
            recommended: false,
            documents: { imgs: [], pdfs: [] },
        };
    }

    private async getVariantById(assemblyId: string, variantId: string) {
        // API?
        const variant = await this.firestore
            .collection(`ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/variants`)
            .doc(variantId)
            .ref.get();
        return variant.data();
    }

    public getCheckpointsObservable(assemblyId: string, checkpointIds: string[]) {
        return combineLatest(checkpointIds.map((id) => this.getCheckpointObservable(assemblyId, id)));
    }

    async getChecklistById(assemblyId: string, checklistId: string) {
        const docSnap = await this.firestore
            .collection(`ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/checklist`)
            .doc(checklistId)
            .get()
            .toPromise();

        const model = docSnap.data();
        convertFirestoreDate(model);
        return model;
    }

    getChecklistObservable(assemblyId: string, checklistId: string) {
        return this.firestore
            .collection(`ns/${this.ns.getNs()}/ownershipAssemblies/${assemblyId}/checklist`)
            .doc(checklistId)
            .valueChanges()
            .pipe(
                map((assembly) => {
                    convertFirestoreDate(assembly);
                    return assembly;
                })
            );
    }
}
