import { Component, Input, OnInit } from '@angular/core';
import { OwnershipAssembly } from '../../../models/ownershipAssembly';
import { PropertyService } from '../../../services/property/property.service';
import { Property } from '../../../models/property';
import * as moment from 'moment';
import { convertFirestoreDate } from '../../../util/util';
import { AssemblyStates } from '../../../models/assemblyStates.enum';
import { ChecklistService } from '../../../services/checklist/checklist.service';
import { PropertyManagerService } from 'src/app/services/propertyManager/property-manager.service';
import { UserService } from 'src/app/services/user/user.service';
import { ApiService } from 'src/app/services/api/api.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { OwnershipAssemblyService } from 'src/app/services/ownership-assembly/ownership-assembly.service';
import { Checklist2Service } from '../../../services/checklist/checklist2.service';
import { OwnershipAssemblyV2Service } from '../../../services/ownership-assembly/ownership-assembly-v2.service';

@Component({
    selector: 'app-vote2-card',
    templateUrl: './vote-card.component.html',
    styleUrls: ['./vote-card.component.scss'],
})
export class Vote2CardComponent implements OnInit {
    @Input() ownershipAssembly: OwnershipAssembly;
    properties: Property[] = [];
    relevantProperty;
    daysUntilEvent: number;
    @Input() isDetailedView = false;
    personalParticipation: boolean;
    locationAvailable = false;
    currentChecklist;
    ASSEMBLYSTATES = AssemblyStates;
    changedCheckpoints;
    delegates;
    delegateIds = [];
    loadingRepresentatives = false;
    delegatesExpanded = true;
    currentUserIsPresent = false;

    constructor(
        private propertyService: PropertyService,
        private checklistService: Checklist2Service,
        private managerService: PropertyManagerService,
        private ownershipAssemblyService: OwnershipAssemblyV2Service,
        private userService: UserService,
        private translate: TranslateService,
        private alertController: AlertController,
        private api: ApiService
    ) {}

    async ngOnInit() {
        await this.initialize();
    }

    async initialize() {
        convertFirestoreDate(this.ownershipAssembly);

        await Promise.all([
            this.loadMyDelegation(),
            this.loadRepresentatives(),
            this.loadChangedCheckpoints(),
            this.loadRelevantProperties(),
        ]);

        this.currentUserIsPresent = Object.values(this.ownershipAssembly.voter.attending).some(
            (attending) => attending
        );
        this.checkLocationAvailable();
        this.daysUntilEvent = this.getDaysUntilEvent();
    }

    async loadMyDelegation() {
        if (this.ownershipAssembly.delegatedTo?.model) {
            switch (this.ownershipAssembly.delegatedTo?.model) {
                case 'manager':
                    const manager = await this.managerService.getManager(this.ownershipAssembly.delegatedTo.id);
                    this.ownershipAssembly.delegatedTo.name = `${manager.firstname} ${manager.lastname}`;
                    break;
                case 'owner':
                    const owner = await this.userService.getTenant(this.ownershipAssembly.delegatedTo.id);
                    if (owner) {
                        this.ownershipAssembly.delegatedTo.name = this.userService.getTenantFullName(owner);
                    }
                    break;
                case 'custom':
                    this.ownershipAssembly.delegatedTo.name = `${this.ownershipAssembly.delegatedTo.customData.firstname} ${this.ownershipAssembly.delegatedTo.customData.lastname}`;
                    break;
            }
        }
    }

    async loadRepresentatives() {
        this.loadingRepresentatives = true;
        this.delegates = await this.ownershipAssemblyService.loadRepresentives(this.ownershipAssembly.id);
        this.delegateIds = Object.keys(this.delegates);
        this.loadingRepresentatives = false;
    }

    async loadChangedCheckpoints() {
        this.currentChecklist = await this.checklistService.getChecklistById(
            this.ownershipAssembly.id,
            this.ownershipAssembly.checklist.releasedRevision
        );

        this.changedCheckpoints = this.currentChecklist.changedCheckpoints
            ? (
                  await Promise.all(
                      this.currentChecklist.changedCheckpoints.map((checkpointId) =>
                          this.checklistService.buildCheckpoint(this.ownershipAssembly.id, { id: checkpointId }, false)
                      )
                  )
              ).map((checkpoint: any) => {
                  return {
                      id: checkpoint.id,
                      name: `${this.currentChecklist.checkpoints.indexOf(checkpoint.id) + 1}. ${checkpoint.title}`,
                  };
              })
            : [];
    }

    async loadRelevantProperties() {
        this.properties = await this.propertyService.getPropertiesByIds(this.ownershipAssembly.properties);
        this.relevantProperty =
            this.propertyService.getProperties().find((rp) => this.properties.find((p) => p.id === rp.id)) ||
            this.properties[0];
    }

    getDaysUntilEvent() {
        const now = moment(new Date());
        const end = moment(new Date(this.ownershipAssembly.startDate));
        const duration = moment.duration(end.diff(now));
        return Math.round(duration.asDays());
    }

    checkLocationAvailable() {
        const assembly = this.ownershipAssembly;
        this.locationAvailable =
            !!`${assembly.locationZipcode}${assembly.locationCity}${assembly.locationStreet}${assembly.locationName}`.trim();
    }

    async rejectRepresentive(event: any, delegateId: string) {
        event.stopPropagation();
        const modal = await this.alertController.create({
            header: this.translate.instant('evote.assembly.reject-delegation.message.title'),
            message: this.translate.instant('evote.assembly.reject-delegation.message'),
            backdropDismiss: false,
            buttons: [
                {
                    text: this.translate.instant('general.cancel'),
                    role: 'cancel',
                },
                {
                    text: this.translate.instant('evote.assembly.reject-delegation.confirm'),
                    role: 'confirm',
                },
            ],
        });

        await modal.present();

        const { role } = await modal.onDidDismiss();

        if (role === 'confirm') {
            await this.api.post(`ownershipAssembliesV2/${this.ownershipAssembly.id}/rejectDelegation/${delegateId}`);
            await this.loadRepresentatives();
        }
    }
}
