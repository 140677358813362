import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Property } from '../../models/property';
import { Flat } from '../../models/flat';
import { PropertyService } from '../../services/property/property.service';
import { NamespaceService } from 'src/app/services/namespace/namespace.service';

@Component({
    selector: 'app-ticket-entity-selection',
    templateUrl: './ticket-entity-selection.component.html',
    styleUrls: ['./ticket-entity-selection.component.scss'],
})
export class TicketEntitySelectionComponent implements OnInit {
    flatsWithProperties: { property: Property; flats: Flat[] }[] = [];
    selectedEntity: Property | Flat;

    constructor(
        private modalController: ModalController,
        private propertyService: PropertyService,
        public ns: NamespaceService
    ) {}

    async ngOnInit() {
        this.flatsWithProperties = await this.propertyService.getMyFlatsWithProperties();
    }

    close(submitData?: boolean) {
        this.modalController.dismiss(submitData ? this.selectedEntity : null);
    }

    entitySelected($event) {
        for (let i = 0; i < this.flatsWithProperties.length; i++) {
            const flwP = this.flatsWithProperties[i];
            if (flwP.property.id === $event) {
                this.selectedEntity = flwP.property;
                break;
            }
            const flat = flwP.flats.find((fl) => fl.id === $event);
            if (flat) {
                this.selectedEntity = flat;
                break;
            }
        }
    }
}
