import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { FlatService } from '../flat/flat.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NsUtil } from '../../util/ns';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as _loadash from 'lodash';
import { OwnershipAssembly } from '../../models/ownershipAssembly';
import { Checkpoint } from '../../models/checkpoint';
import { convertFirestoreDate } from '../../util/util';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api/api.service';
import { Vote } from '../../models/vote';
import { Checklist2Service, CHECKLISTTYPES } from '../checklist/checklist2.service';
import { ElasticService } from '../elastic/elastic.service';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { PopupService } from '../popup/popup.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class OwnershipAssemblyV2Service {
    constructor(
        private userService: UserService,
        private flatService: FlatService,
        private firestore: AngularFirestore,
        private checklistService: Checklist2Service,
        private ns: NsUtil,
        private elastic: ElasticService,
        private http: HttpClient,
        private alertController: AlertController,
        private translate: TranslateService,
        private api: ApiService,
        private barcodeScanner: BarcodeScanner,
        private popupService: PopupService,
        private router: Router
    ) {}

    public getAllOwnershipAssembliesObservable(): Observable<OwnershipAssembly[]> {
        const states = ['invited', 'started', 'closed', 'processed'];
        const user = this.userService.user;
        if (!user.assemblyV2Observable) {
            user.assemblyV2Observable = from(this.flatService.getFlatsByIds(user.ownedFlats)).pipe(
                switchMap((flats) => {
                    const propertyIds = [...new Set(flats.map((flat) => flat.propertyId))];
                    return combineLatest(
                        propertyIds.map((id) =>
                            this.firestore
                                .collection(`ns/${this.ns.getNs()}/ownershipAssembliesV2`, (ref) =>
                                    ref.where('properties', 'array-contains', id).where('state', 'in', states)
                                )
                                .valueChanges()
                        )
                    );
                }),
                switchMap((lists) => of(_loadash.flattenDeep(lists))),
                switchMap((rawAssemblies: OwnershipAssembly[]) => {
                    if (!rawAssemblies.length) {
                        return of([]);
                    }

                    const assemblies = rawAssemblies.reduce(
                        (array, assembly) => [
                            ...array.filter((tempAssembly) => tempAssembly.id !== assembly.id),
                            assembly,
                        ],
                        []
                    );

                    const releasedAssemblies = assemblies.filter((assembly) => assembly.checklist?.releasedRevision);
                    return combineLatest(
                        releasedAssemblies.map((assembly: OwnershipAssembly) => {
                            return this.checklistService
                                .getChecklistObservable(assembly.id, assembly.checklist.releasedRevision)
                                .pipe(
                                    switchMap((checklist: any) => {
                                        return this.checklistService
                                            .getCheckpointsObservable(assembly.id, checklist.checkpoints)
                                            .pipe(
                                                map((checkpoints: Checkpoint[]) => {
                                                    assembly.checkpoints = checkpoints;
                                                    return assembly;
                                                })
                                            );
                                    })
                                );
                        })
                    );
                }),
                switchMap((assemblies) => {
                    return combineLatest(
                        assemblies.map((assembly) => {
                            return this.firestore
                                .doc(`ns/${this.ns.getNs()}/ownershipAssembliesV2/${assembly.id}/voters/${user.id}`)
                                .valueChanges()
                                .pipe(
                                    switchMap((voter: any) => {
                                        const allowedCheckpointsByPrivilegedProperties = this.getAllowedCheckpoints(
                                            assembly.checkpoints
                                        );

                                        assembly.voter = voter;
                                        assembly.voted = assembly.checkpoints
                                            .filter(
                                                (checkpoint: Checkpoint) =>
                                                    checkpoint.type !== 'noDecision' &&
                                                    checkpoint.relevant &&
                                                    allowedCheckpointsByPrivilegedProperties.some(
                                                        (allowedCheckpoint) => allowedCheckpoint.id === checkpoint.id
                                                    )
                                            )
                                            .every(
                                                (checkpoint: Checkpoint) => voter.latestVotes[checkpoint.id] !== null
                                            );
                                        assembly.latestVotes = voter.latestVotes;
                                        assembly.delegatedTo = assembly.voter.represent;
                                        return of(assembly);
                                    })
                                );
                        })
                    );
                }),
                switchMap((assemblies: OwnershipAssembly[]) => {
                    convertFirestoreDate(assemblies);
                    assemblies.sort((a, b) => {
                        return a.startDate - b.startDate;
                    });

                    return of(assemblies);
                })
            );
        }
        return user.assemblyV2Observable;
    }

    getAssemblyObservable(assemblyId: string) {
        return this.firestore
            .collection(`ns/${this.userService.getNamespace()}/ownershipAssembliesV2`)
            .doc(assemblyId)
            .valueChanges();
    }

    async getRawAssembly(assemblyId: string) {
        const snap = await this.firestore
            .collection(`ns/${this.userService.getNamespace()}/ownershipAssembliesV2`)
            .doc(assemblyId)
            .ref.get();
        return snap.data();
    }

    async vote(
        ownershipAssemblyId: string,
        checkpointId: string,
        ownerId: string,
        accepted: boolean,
        value = null,
        delegateToId: string = null,
        delegateToModel: string = null,
        reset: boolean = null,
        customData: any = null,
        disableDelegations = false,
        representiveVote = false
    ) {
        return await this.http
            .post(
                `${environment.apiBase}ownershipAssembliesV2/${ownershipAssemblyId}/checkpoint/${checkpointId}/vote`,
                {
                    ownershipAssemblyId,
                    checkpointId,
                    voteOfId: ownerId,
                    voteOfModel: 'owner',
                    accepted,
                    value,
                    delegateToId,
                    delegateToModel,
                    reset,
                    delegateCustomData: customData || null,
                    disableDelegations,
                    representiveVote,
                }
            )
            .toPromise();
    }

    async updateVoters(ownershipAssemblyId: string, voters: any[]) {
        return this.api.post(`ownershipAssembliesV2/${ownershipAssemblyId}/voters/bulk/update`, {
            voters: voters,
        });
    }

    async voteNewDelegation(
        assembly: OwnershipAssembly,
        delegateToId: string,
        delegateToModel: string,
        reset = null,
        delegateCustomData = null
    ) {
        return await this.api.post(`ownershipAssembliesV2/${assembly.id}/delegate`, {
            createVoteDtos: assembly.checkpoints.map((checkpoint: Checkpoint) => {
                return {
                    ownershipAssemblyId: assembly.id,
                    checkpointId: checkpoint.id,
                    voteOfId: this.userService.user.id,
                    voteOfModel: 'owner',
                    accepted: null,
                    value: null,
                    delegateToId: delegateToId,
                    delegateToModel: delegateToModel,
                    reset,
                    delegateCustomData: delegateCustomData || null,
                };
            }),
        });
    }

    async loadRepresentives(assemblyId: string) {
        const delegates = await this.api.get(
            `ownershipAssembliesV2/${assemblyId}/getDelegation/${this.userService.user.id}`
        );

        const ownerIds = Object.keys(delegates);
        const tenants = await this.userService.getTenants([...new Set(ownerIds)] as string[]);

        for (const id of ownerIds) {
            delegates[id].name = this.userService.getTenantFullName(tenants.find((tenant) => tenant.id === id));
        }

        return delegates;
    }

    async getOwnerOfPropertyAssembly(id: any[], type: string, searchString = '') {
        const searchResult = await this.elastic.searchUser(
            searchString,
            30,
            0,
            {
                'ownedProperties.keyword': id,
                type: type,
            },
            {},
            [],
            { 'id.keyword': 'desc' }
        );

        const ids: any[] = [...new Set(searchResult.ids)];
        const observables = ids.map((id) => this.firestore.collection(`users`).doc(id).valueChanges());
        return !observables.length
            ? of([])
            : combineLatest(...observables).pipe(
                  map((values: any) => {
                      const saneValues = values.filter((val) => Boolean(val));
                      for (const id of searchResult.ids) {
                          if (!saneValues.some((val) => val.id === id)) {
                              console.warn(`Warning: Found id "${id}" via search but not in DB!!!`);
                          }
                      }
                      return saneValues;
                  })
              );
    }

    async togglePresence(ownershipAssemblyId: string) {
        return await this.http
            .post(
                `${environment.apiBase}ownershipAssembliesV2/${ownershipAssemblyId}/presentOwner/${this.userService.user.id}`,
                {}
            )
            .toPromise();
    }

    async getNeverVotedModal() {
        return await this.alertController.create({
            header: this.translate.instant('evote.alert.participate.title'),
            message: this.translate.instant('evote.alert.participate.message'),
            buttons: [
                {
                    text: this.translate.instant('evote.alert.participate.cancel'),
                },
                {
                    text: this.translate.instant('evote.alert.participate.action'),
                    role: 'confirm',
                },
            ],
        });
    }

    async setUserDefaultVotes(assemblyId: string) {
        await this.updateVoters(assemblyId, [
            {
                id: this.userService.user.id,
                evote: true,
            },
        ]);
    }

    async togglePresenceWithQrScanner(): Promise<void> {
        try {
            const scanResult = await this.barcodeScanner.scan();

            if (scanResult.text) {
                await this.togglePresence(scanResult.text);
                await this.popupService.showToast(this.translate.instant('evote.presence.submitted'));
                await this.router.navigateByUrl(`main/evote2/detail/${scanResult.text}`);
            }
        } catch (err) {
            await this.popupService.showToast(this.translate.instant('evote.presence.failed'), true);
        }
    }

    getAllowedCheckpoints(checkpoints: any, propertyIds = this.userService.user.ownedProperties): Checkpoint[] {
        return checkpoints.filter(
            (checkpoint) =>
                !checkpoint.votePrivilegedProperties?.length ||
                checkpoint.votePrivilegedProperties.some((privilegedPropertyId) =>
                    propertyIds.includes(privilegedPropertyId)
                )
        );
    }
}
