import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { MaintenanceComponent } from './maintenance.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [MaintenanceComponent],
    exports: [MaintenanceComponent],
})
export class MaintenanceComponentModule {}
