import { Injectable, Injector } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { ApiTranslationService } from './api-translation.service';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiTranslationLoaderService implements TranslateLoader {
    static apiObservable: Observable<any> = of('');
    static currentLang: string;

    constructor(private injector: Injector) {}

    getTranslation(lang: string): Observable<any> {
        if (lang && lang !== ApiTranslationLoaderService.currentLang) {
            const apiTranslationService = this.injector.get(ApiTranslationService);
            ApiTranslationLoaderService.currentLang = lang;
            ApiTranslationLoaderService.apiObservable = apiTranslationService.getTranslation(lang);
        }

        return ApiTranslationLoaderService.apiObservable;
    }
}
