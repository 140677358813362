/// <reference types="@types/dom-mediacapture-record" />
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { PopupService } from '../../../services/popup/popup.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-recording',
    templateUrl: './recording.component.html',
    styleUrls: ['./recording.component.scss'],
})
export class RecordingComponent implements OnInit, OnDestroy {
    @Output() getAudio = new EventEmitter();

    isRecording = false;
    isMicDenied = false;

    timer = new Subscription();
    time = 0;
    timeDisplay = moment.utc(0).format('mm:ss');

    stream;
    mediaRecorder;
    audioContext = new AudioContext();
    volumes = [];
    interval;

    @Input() maxRecordingTimeInSeconds = 14;

    constructor(private popUpService: PopupService, private translate: TranslateService) {}

    async ngOnInit() {
        await this.getRecordingMedia();
    }

    startStop() {
        this.isRecording = !this.isRecording;

        if (this.isRecording) {
            this.mediaRecorder?.start();
            this.interval = interval(0).subscribe();

            this.timer = timer(0, 1000).subscribe((time) => {
                this.setTime(time);

                if (time >= this.maxRecordingTimeInSeconds) {
                    this.mediaRecorder?.stop();
                }
            });
        } else {
            this.mediaRecorder?.stop();
        }
    }

    setTime(seconds) {
        this.time = seconds;
        this.timeDisplay = moment.utc(seconds * 1000).format('mm:ss');
    }

    async getRecordingMedia() {
        try {
            this.stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
        } catch (err) {
            this.isMicDenied = true;
            await this.popUpService.showToast(this.translate.instant('voice-record.no-permission'), true);
        }

        if (this.stream) {
            this.mediaRecorder = new MediaRecorder(this.stream);

            this.mediaRecorder.onstart = () => {
                this.startAudioContext(this.stream);
            };

            this.mediaRecorder.ondataavailable = (chunk) => {
                const blob = new Blob([chunk.data], {
                    type: this.mediaRecorder.mimeType,
                });

                this.getAudio.emit({
                    blob: blob,
                    duration: this.time,
                });
            };

            this.mediaRecorder.onstop = () => {
                for (const track of this.stream.getTracks()) {
                    track.stop();
                }
            };
        }
    }

    startAudioContext(stream) {
        const analyser = this.audioContext.createAnalyser();
        const microphone = this.audioContext.createMediaStreamSource(stream);

        const scriptProcessor = this.audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(this.audioContext.destination);

        scriptProcessor.onaudioprocess = () => {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            const arraySum = array.reduce((a, value) => a + value, 0);
            const average = (arraySum / array.length / 110) * 78 + 2;
            this.volumes = [average, ...this.volumes.slice(0, 39)];
        };
    }

    ngOnDestroy() {
        this.interval?.unsubscribe();
        this.timer?.unsubscribe();
        this.audioContext?.close();

        if (this.stream) {
            for (const track of this.stream.getTracks()) {
                if (track) {
                    track.stop();
                }
            }
        }
    }
}
