import { Injectable } from '@angular/core';
import { TicketNode } from '../../models/ticket-node';
import {
    OPTIONS,
    STEP_DUPLICATE,
    STEP_TYPE,
    STEP_COMPLETE,
    TICKET_TREE_EXTENSION_ROOT,
} from '../../const/ticket.const';
import { TicketTreeDataService } from '../ticket-tree-data/ticket-tree-data.service';
import { DataService } from '../data/data.service';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class TicketTreeService {
    private fullTicketTree: TicketNode[];
    private currentNode: TicketNode;
    private withoutAppointment = false;

    constructor(
        private ticketTreeDataService: TicketTreeDataService,
        private dataService: DataService,
        private userService: UserService
    ) {}

    async next(selectedChildId): Promise<TicketNode> {
        if (!this.fullTicketTree || !this.fullTicketTree.length) {
            this.fullTicketTree = this.ticketTreeDataService.ticketTree$.getValue();

            if (!this.fullTicketTree || !this.fullTicketTree.length) {
                await this.dataService.initializeAppData();
                this.fullTicketTree = this.ticketTreeDataService.ticketTree$.getValue();
            }
        }

        if (!this.currentNode) {
            this.currentNode = this.fullTicketTree[0];
            return this.currentNode;
        }

        if (!selectedChildId || selectedChildId === OPTIONS.CLOSE) {
            return null;
        } else if (selectedChildId === STEP_TYPE.DUPLICATE) {
            this.currentNode = STEP_DUPLICATE;
        } else {
            if (this.currentNode.children) {
                let nextStep = this.currentNode.children.find((child) => child.id === selectedChildId);
                let stepConfig;

                if (nextStep && nextStep.config) {
                    if (nextStep.config.appointment === false) {
                        this.withoutAppointment = true;
                    }
                    if (nextStep.config.nlapi) {
                        stepConfig = nextStep.config;
                    }
                }

                if (nextStep && nextStep.children && nextStep.children.length) {
                    nextStep = nextStep.children[0];
                    this.currentNode = this.getProcessedNextStep(nextStep);

                    if (stepConfig) {
                        this.currentNode.config = stepConfig;
                    }

                    return this.currentNode;
                } else {
                    this.currentNode = nextStep ? TICKET_TREE_EXTENSION_ROOT : STEP_COMPLETE;
                }
            }
        }

        return this.currentNode;
    }

    getHashtag(id: string) {
        const ht = this.currentNode.children.find((child) => child.id === id);
        const ret: {
            id: string;
            textId: string;
            icon?: string;
            config?: any;
            descriptionId?: string;
        } = {
            id: ht.id,
            textId: ht.textId,
        };
        if (ht.icon) {
            ret.icon = ht.icon;
        }
        if (ht.config) {
            ret.config = ht.config;
        }
        if (ht.descriptionId) {
            ret.descriptionId = ht.descriptionId;
        }
        return ret;
    }

    private getProcessedNextStep(nextStep: TicketNode): TicketNode {
        if (nextStep.id === STEP_TYPE.APPOINTMENT && this.withoutAppointment) {
            // skip the appointments and present person questions,
            // update this if you are changing the order of steps
            nextStep = nextStep.children[0].children[0].children[0].children[0];
        } else if (nextStep.id === STEP_TYPE.PHONE && !this.userService.user.mobile) {
            const existingPhoneIsCorrectIndex = nextStep.children.findIndex((ch) => ch.id === OPTIONS.PHONE_CORRECT);
            if (existingPhoneIsCorrectIndex > -1) {
                nextStep.children[existingPhoneIsCorrectIndex].hidden = true;
            }
        }

        return nextStep;
    }

    reset() {
        this.currentNode = null;
        this.withoutAppointment = false;
    }
}
