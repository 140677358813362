import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('audioElement') audioElement: ElementRef;

    @Output() retry = new EventEmitter<void>();
    @Output() nextStep = new EventEmitter<void>();
    @Output() closeModal = new EventEmitter<void>();
    @Output() audioSent = new EventEmitter<boolean>();

    @Input() namespace: string;
    @Input() language: string;
    @Input() audio: { duration: number; blob: Blob };

    timer = new Subscription();
    time;

    timeDisplay;
    durationDisplay;

    playClicked = false;
    pauseTime: number = 0;

    sent = false;

    constructor(
        private http: HttpClient,
        private loadingController: LoadingController,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.durationDisplay = this.formatTime(this.audio.duration);
        this.language = this.translate.currentLang;
    }

    ngAfterViewInit() {
        this.audioElement.nativeElement.src = URL.createObjectURL(this.audio.blob);
    }

    playPause() {
        const audio = this.audioElement.nativeElement;

        audio.addEventListener('ended', () => {
            this.playClicked = false;
            this.pauseTime = 0;
            this.timer.unsubscribe();
        });

        if (audio.paused) {
            audio.currentTime = this.pauseTime;
            this.playClicked = true;
            audio.play();

            this.timer = timer(0, 1000).subscribe((time) => {
                this.setTime(time + this.pauseTime);
            });
        } else {
            audio.pause();
            this.timer.unsubscribe();
            this.pauseTime = this.time;
        }
    }

    setTime(seconds) {
        this.time = seconds;
        this.timeDisplay = this.formatTime(seconds);
    }

    formatTime(seconds) {
        return moment.utc(seconds * 1000).format('mm:ss');
    }

    async send() {
        const loading = await this.loadingController.create();
        await loading.present();

        const formData = new FormData();
        formData.append('audio', this.audio.blob);

        await this.http
            .post(`${environment.apiBase}requests/audio/?ns=${this.namespace}&lang=${this.language}`, formData)
            .toPromise();

        this.sent = true;
        await loading.dismiss();
        this.nextStep.emit();
        this.audioSent.emit(true);
    }

    ngOnDestroy() {
        if (this.timer) {
            this.timer.unsubscribe();
        }
    }
}
