<app-basic-card header="ticket-appointments.title" (expansion)="onExpansion($event)" [expanded]="expanded">
    <ion-row *ngIf="loading" class="ion-padding ion-justify-content-center">
        <ion-spinner></ion-spinner>
    </ion-row>

    <div *ngIf="!loading">
        <app-no-data
            *ngIf="!appointments.length"
            text="ticket-appointments.no-appointment"
            icon="today-outline"
            size="medium">
        </app-no-data>
        <ion-card
            *ngFor="let appointment of appointments"
            [ngClass]="{
                disabled:
                    appointment.status === TicketAppointmentStatus.CANCELED ||
                    appointment.status === TicketAppointmentStatus.ENDED
            }"
            class="appointment-card">
            <ion-card-header>
                <ion-item lines="none">
                    <!-- ICONS FOR APPOINTMENT TYPES-->
                    <ion-avatar *ngIf="appointment.type === TicketAppointmentType.ADMINISTRATION">
                        <img [src]="data.manager?.profilePicture || DEFAULT_PROFILE_PICTURE" alt="" />
                    </ion-avatar>
                    <ion-icon *ngIf="appointment.type === TicketAppointmentType.PROVIDER" name="construct" size="large">
                    </ion-icon>
                    <!-- ICONS FOR APPOINTMENT STATUS-->
                    <ion-icon
                        *ngIf="appointment.status === TicketAppointmentStatus.OPEN"
                        name="help-circle-outline"
                        color="primary"
                        class="appointment-status-icon">
                    </ion-icon>
                    <ion-icon
                        *ngIf="appointment.status === TicketAppointmentStatus.DECLINED"
                        name="hourglass-outline"
                        class="appointment-status-icon">
                    </ion-icon>

                    <ion-icon
                        *ngIf="appointment.status === TicketAppointmentStatus.CANCELED"
                        name="close-circle-outline"
                        color="danger"
                        class="appointment-status-icon">
                    </ion-icon>
                    <ion-icon
                        *ngIf="appointment.status === TicketAppointmentStatus.ACCEPTED"
                        [name]="appointment.hasStarted ? 'checkmark-done-circle-outline' : 'checkmark-circle-outline'"
                        color="success"
                        class="appointment-status-icon">
                    </ion-icon>
                    <ion-icon
                        *ngIf="appointment.status === TicketAppointmentStatus.ENDED"
                        name="checkmark-done-circle-outline"
                        color="success"
                        class="appointment-status-icon">
                    </ion-icon>
                    <ion-label class="ion-margin-start">
                        <ion-row class="wrap">
                            <b>
                                {{ 'appointment.' + appointment.status | translate }}
                                <span
                                    *ngIf="
                                        appointment.status === TicketAppointmentStatus.CANCELED &&
                                        appointmentLastAuthorKey(appointment)
                                    ">
                                    ({{ appointmentLastAuthorKey(appointment) | translate }})
                                </span>
                            </b>
                        </ion-row>
                        <ion-row class="wrap small">
                            {{
                                appointment.type === TicketAppointmentType.ADMINISTRATION
                                    ? data?.manager?.firstname + ' ' + data?.manager?.lastname
                                    : Object.values(data?.offers).length
                                    ? Object.values(data?.offers)[0].name
                                    : ''
                            }}
                            <br />
                            {{ appointment.updatedOn | timeAgo | async }} |
                            {{ appointment.updatedOn | localeDate : 'dd. MMMM yyyy HH:mm' }}
                        </ion-row>
                    </ion-label>
                </ion-item>
            </ion-card-header>
            <ion-card-content>
                <ion-list
                    class="ion-margin-bottom"
                    [ngClass]="{
                        disabled: [TicketAppointmentStatus.DECLINED].includes(appointment.status)
                    }">
                    <ion-item
                        [lines]="appointment.status === TicketAppointmentStatus.OPEN ? 'full' : 'none'"
                        *ngFor="
                            let recommendation of appointment?.accepted?.length
                                ? appointment.accepted
                                : appointment.recommendations;
                            let i = index
                        ">
                        <div class="ticketAppointmentsCard-appointment">
                            {{ recommendation.start | date : 'dd.MM.y' }}
                            <br />
                            <div class="ticketAppointmentsCard-appointmentTime">
                                {{ recommendation.start | date : 'HH:mm' }}
                                -
                                {{ recommendation.end | date : 'HH:mm' }}
                            </div>
                        </div>
                        <ion-button
                            *ngIf="appointment.status === TicketAppointmentStatus.OPEN"
                            (click)="acceptRecommendation(appointment.id, i)"
                            slot="end"
                            size="small"
                            color="primary"
                            fill="clear">
                            <ion-icon name="thumbs-up"></ion-icon>
                        </ion-button>
                        <ion-button
                            *ngIf="appointment.status === TicketAppointmentStatus.ACCEPTED && !appointment.hasStarted"
                            slot="end"
                            size="small"
                            fill="clear"
                            (click)="cancelAppointment(appointment.id)">
                            <ion-icon name="trash" color="danger"></ion-icon>
                        </ion-button>
                    </ion-item>
                    <ion-item lines="none" *ngIf="appointment.status === TicketAppointmentStatus.OPEN">
                        <div class="ticketAppointmentsCard-appointment">
                            {{ 'ticket-appointments.later-appointment' | translate }}
                        </div>
                        <ion-button
                            (click)="laterAppointments(appointment.id)"
                            slot="end"
                            size="small"
                            color="danger"
                            fill="clear">
                            <ion-icon name="thumbs-down"></ion-icon>
                        </ion-button>
                    </ion-item>
                </ion-list>
                <app-ticket-text
                    [ticketId]="data.id"
                    [key]="'appointment.' + appointment.id + '.description'"
                    [margin]="1"></app-ticket-text>
                <div
                    *ngIf="
                        appointment.status === TicketAppointmentStatus.ACCEPTED ||
                        appointment.presentPerson?.representation
                    ">
                    <ion-label *ngIf="appointment.presentPerson?.representation">
                        <b>
                            {{ 'ticket-appointments.representation.title' | translate }}
                        </b>
                    </ion-label>
                    <ion-item
                        button
                        (click)="onEditPresentPerson(appointment.id)"
                        detail="true"
                        lines="none"
                        *ngIf="
                            appointment.status === TicketAppointmentStatus.ACCEPTED ||
                            appointment.presentPerson?.representation
                        ">
                        <ion-label *ngIf="appointment.presentPerson?.representation; else noPresentPerson">
                            <h3>
                                {{ appointment?.presentPerson?.contact?.name }}
                            </h3>
                            <p>
                                {{ appointment?.presentPerson?.contact?.mobile }}
                            </p>
                        </ion-label>
                        <ng-template #noPresentPerson>
                            <ion-label>
                                {{ 'ticket-appointments.representation.add' | translate }}
                            </ion-label>
                        </ng-template>
                    </ion-item>
                </div>
                <app-ticket-text
                    *ngIf="appointment.status === TicketAppointmentStatus.CANCELED"
                    [ticketId]="data.id"
                    [key]="'appointment.' + appointment.id + '.reason'"
                    [margin]="0"></app-ticket-text>
            </ion-card-content>
        </ion-card>
    </div>
</app-basic-card>
