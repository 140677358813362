<app-basic-card
    *ngIf="serviceProviderRatings?.length || managerRatings?.length"
    (expansion)="onExpand()"
    [header]="'general.rating' | translate">
    <div *ngFor="let managerRating of managerRatings; index as i" (click)="showEditModal(managerRating.id)">
        <div class="item-wrapper">
            <h2 class="text-bold">{{ managerName }} ({{ 'other-person-info.manager' | translate }})</h2>

            <ion-button fill="outline" size="small" color="primary">
                <ion-row class="ion-align-items-center">
                    {{ managerRating.rating }}
                </ion-row>
                <ion-icon slot="end" name="star" color="primary"></ion-icon>
            </ion-button>
        </div>
        <p class="text-light" *ngIf="managerRating?.comment">
            {{ managerRating.comment | customTranslation }}
        </p>
    </div>
    <div *ngIf="serviceProviderRatings">
        <div
            *ngFor="let serviceProviderRating of serviceProviderRatings; index as i"
            (click)="showEditModal(serviceProviderRating.id)">
            <div class="item-wrapper">
                <h2 class="text-bold">{{ serviceProviderName }} ({{ 'general.serviceProvider' | translate }})</h2>

                <ion-button fill="outline" size="small" color="primary">
                    <ion-row class="ion-align-items-center">
                        {{ serviceProviderRating.rating }}
                    </ion-row>
                    <ion-icon slot="end" name="star" color="primary"></ion-icon>
                </ion-button>
            </div>
            <p class="text-light" *ngIf="serviceProviderRating?.comment">
                {{ serviceProviderRating.comment | customTranslation }}
            </p>
        </div>
    </div>
</app-basic-card>
