import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { ChatBubbleComponentModule } from '../../atoms/chat-bubble/chat-bubble.component.module';
import { ChatViewComponent } from './chat-view.component';
import { ChatMessageTextComponentModule } from '../../molecules/chat-message-text/chat-message-text.component.module';
import { ChatMessageSelectionComponentModule } from '../../molecules/chat-message-selection/chat-message-selection.component.module';
import { ChatMessageImageComponentModule } from '../../molecules/chat-message-image/chat-message-image.component.module';
import { FormsModule } from '@angular/forms';
import { OtherPersonInfoComponentModule } from '../../../modals/other-person-info/other-person-info.component.module';
import { AppointmentsComponentModule } from '../../../modals/appointments/appointments.component.module';
import { RegexMatchDirectiveModule } from 'src/app/directives/regex-match/regex-match.module';
import { InputGooglePlacesComponent } from '../../molecules/input-google-places/input-google-places.component';
import { InputAutocompleteComponent } from '../../molecules/input-autocomplete/input-autocomplete.component';
import { AutofocusModule } from 'src/app/directives/autofocus/autofocus.module';

@NgModule({
    imports: [
        ComponentsSharedModule,
        ChatBubbleComponentModule,
        ChatMessageTextComponentModule,
        ChatMessageSelectionComponentModule,
        ChatMessageImageComponentModule,
        FormsModule,
        OtherPersonInfoComponentModule,
        AppointmentsComponentModule,
        RegexMatchDirectiveModule,
        AutofocusModule,
    ],
    declarations: [ChatViewComponent, InputGooglePlacesComponent, InputAutocompleteComponent],
    exports: [ChatViewComponent],
})
export class ChatViewComponentModule {}
