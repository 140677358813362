<app-segment-slider
    #slider
    customTheme="true"
    class="h-100"
    [headerData]="sliderHeaderData"
    [initialSlide]="sliderInitialSlide"
    (slideChanged)="onSlideChanged($event)">
    <app-vote2-view left [ownerShipAssemblies]="assembliesOpen"></app-vote2-view>
    <app-vote2-view middleleft [ownerShipAssemblies]="assembliesClosed"></app-vote2-view>
</app-segment-slider>

<ion-fab *ngIf="isCordova" horizontal="end" vertical="bottom" slot="fixed">
    <ion-fab-button (click)="togglePresenceWithQrScanner()" color="primary">
        <ion-icon class="fab-icon-qrcode" name="qr-code-outline"></ion-icon>
    </ion-fab-button>
</ion-fab>
