import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { LegalDocumentsAcceptComponent } from './legal-documents-accept.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [LegalDocumentsAcceptComponent],
    exports: [LegalDocumentsAcceptComponent],
})
export class LegalDocumentsAcceptComponentModule {}
