import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
    providedIn: 'root',
})
export class ReCaptchaService {
    constructor(private reCaptchaV3Service: ReCaptchaV3Service) {}

    execute(action: string): Observable<string> {
        return this.reCaptchaV3Service.execute(action);
    }
}
