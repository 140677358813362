<ion-toolbar [ngClass]="{ 'custom-theme': customTheme }">
    <ion-segment mode="md" (ionChange)="changeSliderValue($event)">
        <ion-segment-button
            *ngFor="let segment of headerData; index as index"
            [value]="index + ''"
            mode="md"
            [layout]="segment.noLayout ? '' : 'icon-start'"
            [ngClass]="{
                'has-badge': segment.badge
            }">
            <ion-icon *ngIf="segment.icon" [name]="segment.icon"></ion-icon>
            <ion-label *ngIf="segment.label" class="ion-no-margin label" [class.label]="segment.noLayout === true">
                {{ segment.label | translate }}
            </ion-label>
            <ion-badge *ngIf="segment.badge">{{ segment.badge }}</ion-badge>
        </ion-segment-button>
    </ion-segment>
</ion-toolbar>

<ion-slides [options]="slideOpts" (ionSlideTransitionEnd)="onSlideChange()">
    <ion-slide>
        <ion-content>
            <ng-content select="[left]"></ng-content>
        </ion-content>
    </ion-slide>
    <ion-slide>
        <ion-content>
            <ng-content select="[middleleft]"></ng-content>
        </ion-content>
    </ion-slide>
    <ion-slide *ngIf="headerData.length >= 3">
        <ion-content>
            <ng-content select="[middle]"></ng-content>
        </ion-content>
    </ion-slide>
    <ion-slide *ngIf="headerData.length >= 4">
        <ion-content>
            <ng-content select="[middleright]"></ng-content>
        </ion-content>
    </ion-slide>
    <ion-slide *ngIf="headerData.length >= 5">
        <ion-content>
            <ng-content select="[right]"></ng-content>
        </ion-content>
    </ion-slide>
</ion-slides>
