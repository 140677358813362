<ion-header>
    <app-topbar
        [title]="'ticket-pictures.title' | translate"
        [left]="'close'"
        (leftClicked)="modalController.dismiss()"></app-topbar>
</ion-header>

<ion-content>
    <div class="ion-padding-end">
        <app-attachments-add
            [fileTypes]="'image/png, image/jpeg, application/pdf'"
            (documentsChanged)="onImageSelected($event)"></app-attachments-add>
    </div>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-row>
            <ion-col class="total-center">
                <ion-button class="w-100" color="primary" expand="block" (click)="saveImage()">
                    {{ 'ticket-pictures.save' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-toolbar>
</ion-footer>
