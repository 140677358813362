import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chat-message-image',
    templateUrl: './chat-message-image.component.html',
    styleUrls: ['./chat-message-image.component.scss'],
})
export class ChatMessageImageComponent {
    @Input() data;
}
