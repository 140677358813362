import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NamespaceInfo } from '../../models/namespace-info';
import { BaseDataService } from '../../util/base-data-service';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class NamespaceService implements BaseDataService {
    private namespace: NamespaceInfo;
    ticketConfig: any;
    flatTypeConfig: any;

    constructor(private userService: UserService, private db: AngularFirestore, private api: ApiService) {}

    getName() {
        return this.namespace?.name;
    }

    get() {
        return this.namespace;
    }

    async initialize() {
        [this.ticketConfig, this.flatTypeConfig] = await Promise.all([
            (await this.getTicketConfiguration()).data(),
            this.getFlatTypeConfiguration(),
        ]);

        if (this.namespace) {
            return this.namespace;
        }
        return this.db
            .doc(`ns/${this.userService.getNamespace()}`)
            .get()
            .toPromise()
            .then((nsInfo) => {
                this.namespace = nsInfo.data() as NamespaceInfo;
            });
    }

    async getNsSplashLogo() {
        if (this.namespace) {
            return this.namespace.profilePicture || 'assets/imgs/default_splash.png';
        }

        if (!this.userService.user) {
            await this.userService.getUser();
        }
        await this.initialize();
        return this.namespace.profilePicture || 'assets/imgs/default_splash.png';
    }

    async getTicketConfiguration(): Promise<firebase.default.firestore.DocumentSnapshot> {
        return this.db
            .collection(`ns/${this.userService.getNamespace()}/configurations`)
            .doc('tickets')
            .get()
            .toPromise();
    }

    async getBlackboardConfiguration(): Promise<firebase.default.firestore.DocumentSnapshot> {
        return this.db
            .collection(`ns/${this.userService.getNamespace()}/configurations`)
            .doc('blackboard')
            .get()
            .toPromise();
    }

    async getTenantConfiguration(): Promise<any> {
        const snap = await this.db
            .collection(`ns/${this.userService.getNamespace()}/configurations`)
            .doc('tenants')
            .get()
            .toPromise();
        return snap?.data();
    }

    async getFlatTypeConfiguration(): Promise<any> {
        const snap = await this.db
            .collection(`ns/${this.userService.getNamespace()}/configurations`)
            .doc('flatTypes')
            .get()
            .toPromise();
        return snap?.data();
    }

    async getDomain(): Promise<string> {
        const response = await this.api.get('namespaces/domain');
        return response?.domain || null;
    }

    terminate() {
        this.namespace = null;
    }
}
