import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TicketsService } from 'src/app/services/tickets/tickets.service';
import { UserService } from 'src/app/services/user/user.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { TranslateService } from '@ngx-translate/core';
import { convertFirestoreDate } from 'src/app/util/util';
import { LoadingController, ModalController } from '@ionic/angular';
import { PopupService } from 'src/app/services/popup/popup.service';
import { TicketStornoComponent } from 'src/app/modals/ticket-storno/ticket-storno.component';
import { TicketRatingComponent } from 'src/app/modals/ticket-rating/ticket-rating.component';
import { TicketRatingCardComponent } from '../../../components/organisms/ticket-rating-card/ticket-rating-card.component';
import * as moment from 'moment';
import { TicketAppointmentStatus } from 'src/app/models/ticket-appointment';

@Component({
    selector: 'app-token-ticket',
    templateUrl: './token-ticket.page.html',
    styleUrls: ['./token-ticket.page.scss'],
})
export class TokenTicketPage implements OnInit, OnDestroy {
    @ViewChild('notesRef') notesCardRef: ElementRef;
    @ViewChild('notes') notesCard;
    @ViewChild('appointmentsRef') appointmentsCardRef: ElementRef;
    @ViewChild('appointments') appointmentsCard;
    @ViewChild('imagesRef') imagesCardRef: ElementRef;
    @ViewChild('images') imagesCard;
    @ViewChild(TicketRatingCardComponent)
    ratingCardRef: TicketRatingCardComponent;

    header;
    ticket;
    providerId;
    providerName;
    headerParam = {};
    hasUnreadNotes = false;
    init = true;

    languageSubscripion: Subscription;
    tokenTicketSubscription: Subscription;
    tokenLoginTicketIdSubscription: Subscription;

    constructor(
        private loadingController: LoadingController,
        private popupService: PopupService,
        private modalController: ModalController,
        private auth: AuthService,
        private translate: TranslateService,
        private userService: UserService,
        private ticketsService: TicketsService,
        private propertyService: PropertyService
    ) {}

    ngOnInit() {
        this.languageSubscripion = this.userService.userLanguage$.subscribe((language) => {
            if (language) {
                if (this.tokenLoginTicketIdSubscription) {
                    this.tokenLoginTicketIdSubscription.unsubscribe();
                }
                this.tokenLoginTicketIdSubscription = this.auth.tokenLoginTicketId$.subscribe((ticketId) => {
                    if (this.tokenTicketSubscription) {
                        this.tokenTicketSubscription.unsubscribe();
                    }
                    if (ticketId) {
                        this.tokenTicketSubscription = this.ticketsService
                            .getTicketObservable(ticketId)
                            .subscribe(async (ticket: any) => {
                                const translationLoadedSubscription = this.translate
                                    .getTranslation(language)
                                    .subscribe((translation) => {
                                        this.header = this.translate.instant('ticket-details.header', {
                                            ticketId: ticket.ticketNo,
                                            status: this.translate.instant(
                                                `ticket.state-${this.ticketsService.getCurrentInfoState(ticket)}`
                                            ),
                                            priority: this.translate.instant(`ticket.priority-${ticket.priority}`),
                                        });

                                        translationLoadedSubscription?.unsubscribe();
                                    });

                                convertFirestoreDate(ticket);
                                await this.ticketsService.getTicketTextsByTicketAndKey(ticket);
                                await this.ticketsService.setOffers(ticket);
                                if (ticket.propertyId) {
                                    ticket.property = await this.propertyService.getPropertyById(ticket.propertyId);
                                }
                                ticket.manager = await this.propertyService.getManager(ticket.propertyManagerIds[0]);
                                if (ticket.offers && Object.keys(ticket.offers).length) {
                                    const offer = ticket.offers[Object.keys(ticket.offers)[0]];
                                    this.providerId = offer.providerId;
                                    this.providerName = offer.name;
                                }
                                this.ticket = ticket;
                                this.ticket.user = await this.userService.getUser();
                                this.hasUnreadNotes = await this.ticketsService.hasUnreadNotes(ticketId);
                            });
                    }
                });
            }
        });
    }

    setAppointmentStatus(event) {
        if (
            event?.id &&
            event?.managerAppointmentStatus &&
            ![TicketAppointmentStatus.ACCEPTED, TicketAppointmentStatus.ENDED].includes(
                event.managerAppointmentStatus
            ) &&
            this.init
        ) {
            this.appointmentsCard.onExpansion(true);
            this.init = false;
        }
    }

    ngOnDestroy() {
        if (this.languageSubscripion) {
            this.languageSubscripion.unsubscribe();
        }
        if (this.tokenTicketSubscription) {
            this.tokenTicketSubscription.unsubscribe();
        }
        if (this.tokenLoginTicketIdSubscription) {
            this.tokenLoginTicketIdSubscription.unsubscribe();
        }
    }

    tabClicked(card: string) {
        if (card) {
            switch (card) {
                case 'notes':
                    this.notesCardRef.nativeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                    this.notesCard.onExpansion(true);
                    break;

                case 'images':
                    this.imagesCardRef.nativeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                    this.imagesCard.onExpansion(true);
                    break;
                case 'appointments':
                    this.appointmentsCardRef.nativeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                    this.appointmentsCard.onExpansion(true);
                    break;

                default:
                    console.warn(`${card} is not a known card!`);
            }
        }
    }

    wasTicketInState(state: string): boolean {
        let wasInState = false;
        for (const status of Object.keys(this.ticket.statusUser)) {
            if (status === state && this.ticket.statusUser[status].value) {
                wasInState = true;
            }
        }
        return wasInState;
    }

    getKeys(data: any) {
        return Object.keys(data)[0];
    }

    async updateImages(event: any) {
        const loader = await this.loadingController.create();
        await loader.present();
        try {
            const images = [...event.pdfs, ...event.imgs];
            await this.ticketsService.uploadTicketImages(this.ticket.id, images);
            const { documents } = await this.ticketsService.getTicket(this.ticket.id);

            this.ticket.documents.imgs = documents.imgs;
            await loader.dismiss();
        } catch (err) {
            this.popupService.showToast(this.translate.instant('general.error'), true);
            await loader.dismiss();
        }
    }

    async deleteImage(event) {
        const loader = await this.loadingController.create();
        await loader.present();

        try {
            await this.ticketsService.deleteTicketImage(this.ticket.id, event);
            const { documents } = await this.ticketsService.getTicket(this.ticket.id);
            this.ticket.documents.imgs = documents.imgs;
            await loader.dismiss();
        } catch (err) {
            this.popupService.showToast(this.translate.instant('general.error'), true);
            await loader.dismiss();
        }
    }

    wasTicketInManagerState(state: string): boolean {
        let wasInState = false;
        for (const status of Object.keys(this.ticket.statusManager)) {
            if (status === state && this.ticket.statusManager[status].value) {
                wasInState = true;
            }
        }
        return wasInState;
    }

    canFinish() {
        let canFinish = false;
        if (
            (this.ticket?.flatId &&
                !this.wasTicketInState('CLOSED') &&
                !this.wasTicketInState('DECLINED') &&
                !this.ticket.contact.type.includes('property') &&
                this.wasTicketInManagerState('CM_FINISHED')) ||
            (this.ticket?.flatId &&
                this.ticket.status === 'IN_WORK' &&
                this.wasTicketInState('FINISHED_BY_MANAGER') &&
                !this.wasTicketInState('CLOSED'))
        ) {
            canFinish = true;
        }

        return canFinish;
    }

    offerAppointmentSet(offers: any) {
        if (Object.keys(offers).length) {
            const offer = offers[Object.keys(offers)[0]];
            if (offer.newDates && offer.newDates[0] && offer.newDates[0].from) {
                if (moment().isAfter(moment(offer.newDates[0].from))) {
                    return true;
                }
            }
        }
        return false;
    }

    async presentStornoModal() {
        const modal = await this.modalController.create({
            component: TicketStornoComponent,
            componentProps: { ticket: this.ticket },
        });
        await modal.present();
    }

    async presentRatingModal() {
        const manager = await this.propertyService.getManager(this.ticket.propertyManagerIds[0]);

        const modal = await this.modalController.create({
            component: TicketRatingComponent,
            componentProps: {
                providerRating: {
                    providerId: this.providerId,
                },
                managerRating: {
                    managerId: this.ticket.propertyManagerIds[0],
                },
                managerName: `${manager.firstname} ${manager.lastname}`,
                ticketId: this.ticket.id,
                providerName: this.providerName,
            },
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();

        if (data) {
            await this.ratingCardRef.loadRatings();
            await this.ticketsService.finishTicket(this.ticket.id);
        }
    }
}
