<div class="grid">
    <ion-row *ngIf="!isRecording && !isMicDenied" class="text ion-justify-content-center">
        <ion-icon class="middle-icon" name="mic"></ion-icon>
        {{ 'voice-record.info-text' | translate }}
    </ion-row>

    <ion-row *ngIf="isMicDenied" class="text ion-justify-content-center">
        <ion-icon class="middle-icon" name="close-circle-outline"></ion-icon>
        {{ 'voice-record.no-permission' | translate }}
    </ion-row>

    <div class="recording" *ngIf="isRecording">
        <div class="volume-bar">
            <div
                *ngFor="let volume of volumes; let i = index"
                [style.height.px]="volume"
                [style.right.px]="i * 6"
                class="volume"></div>
        </div>
        <ion-row class="time ion-justify-content-center">
            {{ timeDisplay }}
        </ion-row>
        <ion-row class="max-time">
            <span> ({{ 'max.record.time' | translate }}: 00:{{ maxRecordingTimeInSeconds }}) </span>
        </ion-row>
    </div>

    <ion-row class="ion-justify-content-center">
        <ion-button (click)="startStop()" [disabled]="isMicDenied" class="record-button" fill="clear"
            ><ion-icon class="icon" slot="icon-only" [name]="isRecording ? 'stop-circle' : 'radio-button-on'"></ion-icon
        ></ion-button>
    </ion-row>
</div>
