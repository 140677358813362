import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    set(key: string, value: any) {
        let valueToStore = JSON.parse(JSON.stringify(value));
        if (Array.isArray(value) || typeof value === 'object') {
            valueToStore = JSON.stringify(value);
        }

        localStorage.setItem(environment.storagePrefix + key, valueToStore);
    }

    get(key) {
        const valueToReturn = localStorage.getItem(key);
        if (!valueToReturn) {
            return null;
        }
        if (valueToReturn[0] === '[' || valueToReturn[0] === '{') {
            return JSON.parse(valueToReturn);
        }

        return valueToReturn;
    }

    remove(key) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }
}
