import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chat-message-text',
    templateUrl: './chat-message-text.component.html',
    styleUrls: ['./chat-message-text.component.scss'],
})
export class ChatMessageTextComponent {
    @Input() data;
}
