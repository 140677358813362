import { Component, Input, OnInit } from '@angular/core';
import { RatingService } from '../../../services/rating/rating.service';
import { ModalController } from '@ionic/angular';
import { TicketRatingComponent } from '../../../modals/ticket-rating/ticket-rating.component';
import { AnalyticsService } from '../../../services/analytics';
import { ANALYTICS } from '../../../const/analytics.const';

@Component({
    selector: 'app-ticket-rating-card',
    templateUrl: './ticket-rating-card.component.html',
    styleUrls: ['./ticket-rating-card.component.scss'],
})
export class TicketRatingCardComponent implements OnInit {
    @Input() ticketId: string;
    @Input() managerName: string;
    @Input() serviceProviderName: string;

    managerRatings: any[];
    serviceProviderRatings: any[];

    constructor(
        private ratingService: RatingService,
        private modalCtrl: ModalController,
        private analytics: AnalyticsService
    ) {}

    async ngOnInit() {
        await this.loadRatings();
    }

    async loadRatings() {
        this.managerRatings = await this.ratingService.getRatingsByTypeAndTicketId('manager', this.ticketId);

        this.serviceProviderRatings = await this.ratingService.getRatingsByTypeAndTicketId('provider', this.ticketId);
    }

    async showEditModal(id: string) {
        const managerRating = this.managerRatings.find((managerRating) => managerRating.id === id);

        const serviceProviderRating = this.serviceProviderRatings.find((providerRating) => providerRating.id === id);

        let propsForModal = {};

        if (managerRating) {
            propsForModal = {
                managerRating: managerRating,
                managerName: this.managerName,
                ticketId: this.ticketId,
                isUpdate: true,
            };
        } else if (serviceProviderRating) {
            propsForModal = {
                providerRating: serviceProviderRating,
                providerName: this.serviceProviderName,
                ticketId: this.ticketId,
                isUpdate: true,
            };
        }

        const editModal = await this.modalCtrl.create({
            component: TicketRatingComponent,
            componentProps: propsForModal,
        });

        await editModal.present();

        const { data } = await editModal.onDidDismiss();

        if (data) {
            await this.loadRatings();
        }
    }

    onExpand() {
        this.analytics.trackEvent(ANALYTICS.ACTIONS.ON_EXPAND_TICKET_RATING_CARD);
    }
}
