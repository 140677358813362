<app-basic-card (expansion)="onExpand()" [header]="'ticket-contact.title'">
    <ion-item *ngIf="ticketContact.name" lines="none">
        <ion-icon name="person" slot="start"></ion-icon>
        <ion-label>{{ ticketContact.name }}</ion-label>
    </ion-item>

    <app-contact-phone-mail
        [mail]="ticketFrom || ticketContact.email"
        [phone]="ticketContact.phone"
        [ticketNo]="ticketNo"
        [showTitle]="false"></app-contact-phone-mail>
</app-basic-card>
