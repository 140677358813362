import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-legal-documents-accept-modal',
    templateUrl: './legal-documents-accept-modal.component.html',
    styleUrls: ['./legal-documents-accept-modal.component.scss'],
})
export class LegalDocumentsAcceptModalComponent {
    @Input() excludedDocuments = [];
    @Input() showOptional = true;

    hasAccepted = false;
    acceptedLegalDocuments = false;
    accepted;

    constructor(private modalController: ModalController) {}

    /**
     * Gets triggered when toggle state in child component changes
     *
     * @param event object with two properties : allAccepted (boolean) and legalDocuments (object)
     */
    acceptToggleChange(event: any) {
        this.hasAccepted = event.allAccepted;
        this.acceptedLegalDocuments = event.legalDocuments;
        this.accepted = event.accepted?.length ? event.accepted : null;
    }

    /**
     * Confirm accept of legal documents
     * Close modal and return data to parent component (modal creator)
     */
    confirm() {
        if (this.hasAccepted) {
            this.modalController.dismiss({
                acceptedLegalDocuments: this.acceptedLegalDocuments,
                accepted: this.accepted,
            });
        }
    }
}
