import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import firebase from 'firebase/app';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user: Observable<any>;
    tokenLogin$: Subject<boolean> = new BehaviorSubject(null);
    restrictedTokenLogin$: Subject<boolean> = new BehaviorSubject(false);
    tokenLoginTicketId$: Subject<string> = new BehaviorSubject(null);
    maintenance$: Subject<boolean> = new BehaviorSubject(false);
    nsMaintenance$: Subject<boolean> = new BehaviorSubject(false);
    nsSubscription: Subscription;
    namespaceTokens$ = new BehaviorSubject([]);

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private translate: TranslateService,
        private http: HttpClient,
        private userService: UserService
    ) {
        this.user = this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    this.afAuth.currentUser.then((user) => {
                        user.getIdTokenResult().then((idTokenResult) => {
                            const isTokenLogin = Boolean(idTokenResult?.claims?.tokenLogin);
                            this.tokenLogin$.next(isTokenLogin);

                            if (isTokenLogin) {
                                this.restrictedTokenLogin$.next(Boolean(idTokenResult?.claims?.restricted));
                            }
                        });
                    });

                    return this.afs.doc<any>(`users/${user.uid}`).valueChanges();
                } else {
                    return of(null);
                }
            })
        );

        this.afs
            .collection('public')
            .doc('maintenance')
            .valueChanges()
            .subscribe((maintenance: { active: boolean }) => {
                this.maintenance$.next(Boolean(maintenance && maintenance.active));
            });

        this.user.subscribe((user) => {
            this.registerNamespaceMaintenance(user);
        });
    }

    private registerNamespaceMaintenance(user) {
        if (this.nsSubscription) {
            this.nsSubscription.unsubscribe();
        }

        if (user) {
            this.nsSubscription = this.afs
                .collection('ns')
                .doc(user.ns)
                .valueChanges()
                .subscribe((namespace: any) => {
                    this.nsMaintenance$.next(Boolean(namespace && namespace.maintenance));
                });
        }
    }

    getCurrentUser() {
        return this.user.pipe(take(1)).toPromise();
    }

    async authenticate(email: string, password: string, token = null) {
        const authResponse: any = await this.http
            .post(`${environment.apiBase}users/authenticate`, {
                email,
                password,
                token,
            })
            .toPromise();

        this.namespaceTokens$.next(authResponse.tokens);
        return this.namespaceTokens$.value;
    }

    signIn(token = null, reauth = false) {
        if (token?.ticket) {
            this.tokenLoginTicketId$.next(token.ticket?.id || null);
        }

        return new Promise((resolve, reject) => {
            this.afAuth
                .setPersistence(token && !reauth ? 'none' : 'local')
                .then(async () => {
                    this.afAuth
                        .signInWithCustomToken(token.token)
                        .then(async (firebaseUserObject) => {
                            const idTokenResult: any = await firebaseUserObject.user.getIdTokenResult();
                            this.userService.moduleSubscription.next(idTokenResult?.claims?.modules || []);
                            if (idTokenResult.claims.pm) {
                                const errorObj = {
                                    code: 'auth/user-no-credentials',
                                    message: this.translate.instant('login.error-no-credentials'),
                                };
                                reject(errorObj);
                            } else {
                                resolve(firebaseUserObject);
                            }
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err: any) => {
                    reject(err);
                });
        });
    }

    logout(keepTokens = false) {
        if (!keepTokens) {
            this.namespaceTokens$.next([]);
        }

        this.tokenLogin$.next(null);
        this.tokenLoginTicketId$.next(null);
        return this.afAuth.signOut();
    }

    get timestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    sendPasswordResetMail(email: string) {
        return this.http.post(`${environment.apiBase}users/resetPassword/${email}`, {}).toPromise();
    }
}
