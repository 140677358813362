import { NgModule } from '@angular/core';
import { TicketEntitySelectionComponent } from './ticket-entity-selection.component';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';

@NgModule({
    imports: [ComponentsSharedModule, TopbarComponentModule],
    declarations: [TicketEntitySelectionComponent],
    exports: [TicketEntitySelectionComponent],
})
export class TicketEntitySelectionComponentModule {}
