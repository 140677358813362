import { NgModule } from '@angular/core';
import { ShowMoreComponent } from './show-more';
import { ComponentsSharedModule } from '../../components.shared.module';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
    imports: [ComponentsSharedModule, PipesModule],
    declarations: [ShowMoreComponent],
    exports: [ShowMoreComponent],
})
export class ShowMoreComponentModule {}
