<ion-header>
    <app-topbar
        [left]="'save-outline'"
        (leftClicked)="download()"
        [right]="'close'"
        (rightClicked)="modalController.dismiss()"></app-topbar>
</ion-header>
<div class="pdf-viewer-wrapper">
    <ngx-extended-pdf-viewer
        [(src)]="this.document.file"
        [zoom]="'page-width'"
        [useBrowserLocale]="true"
        [showBorders]="false"
        [showOpenFileButton]="false"
        [showSpreadButton]="false"
        [showScrollingButton]="false"
        [showRotateButton]="false"
        [showDownloadButton]="false"
        [showZoomButtons]="false"
        [mobileFriendlyZoom]="'150%'"
        [showSidebarButton]="false"
        [showPagingButtons]="false"
        [showDrawEditor]="false"
        [showTextEditor]="false"
        [showPropertiesButton]="false"
        [showFindButton]="false"
        [showToolbar]="false"
        (pageRendered)="dismissLoaderFromDocumentsDetail()"
        (pdfLoadingFailed)="loadingFailed()">
    </ngx-extended-pdf-viewer>
</div>
