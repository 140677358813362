import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class OffersService {
    constructor(private http: HttpClient) {}

    async getOffers(ticketId: string): Promise<any> {
        return await this.http.get(`${environment.apiBase}offers/${ticketId}`).toPromise();
    }
}
