import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListWithChipComponentModule } from '../components/organisms/list-with-chip/list-with-chip.component.module';
import { LegalDocumentsAcceptModalComponentModule } from './legal-documents/legal-documents-accept-modal.component.module';
import { VoteDelegationModalComponentModule } from './vote-delegation-modal/vote-delegation-modal.component.module';
import { DocumentSearchComponentModule } from './document-search/document-search.module';
import { Vote2DelegationModalComponentModule } from './vote2-delegation-modal/vote-delegation-modal.component.module';

@NgModule({
    declarations: [],
    exports: [],
    imports: [
        CommonModule,
        IonicModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ListWithChipComponentModule,
        DocumentSearchComponentModule,
        VoteDelegationModalComponentModule,
        Vote2DelegationModalComponentModule,
        LegalDocumentsAcceptModalComponentModule,
    ],
})
export class ModalsModule {}
