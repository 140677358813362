export interface TicketAppointment {
    id: string;
    accepted: TicketAppointmentOption[];
    recommendations: TicketAppointmentOption[];
    status: TicketAppointmentStatus | string;
    ticketId: string;
    createdOn: Date | any;
    updatedOn: Date | any;
    presentPerson: {
        representation: boolean;
        contact: {
            name: string;
            mobile: string;
        };
    };
    updatedBy?: TicketAppointmentAuthor;
    hasStarted?: boolean;
    reasonTitle?: string;
    type: string;
}

export interface TicketAppointmentOption {
    start: Date | any;
    end: Date | any;
}

export enum TicketAppointmentStatus {
    OPEN = 'open',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    CANCELED = 'canceled',
    ENDED = 'ended',
}

export interface TicketAppointmentAuthor {
    model: string; // manager | user
    id: string;
}
export enum TicketAppointmentType {
    ADMINISTRATION = 'administration',
    PROVIDER = 'provider',
}
