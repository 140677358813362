export const ANALYTICS = {
    VIEWS: {
        TICKET_DETAIL: 'ticket-detail',
        BULLETIN_BOARD_PAGE: 'bulletin-board-page',
        PROFILE_PAGE: 'profile-page',
        NOTIFICATIONS_MODAL: 'notifications-modal',
        CHANGE_PASSWORD_MODAL: 'change-password-modal',
        CANCEL_TICKET_MODAL: 'cancel-ticket-modal',
        LOGIN_PAGE: 'login-page',
        CREATE_TICKET_PAGE: 'create-ticket-page',
        TICKETS_PAGE_PROPERTY: 'ticket-page-property',
        TICKETS_PAGE_FLATS: 'ticket-page-flats',
        TICKETS_PAGE_TENANT: 'ticket-page-tenant',
        DOCUMENTS_PAGE_PROPERTY: 'documents-page-property',
        DOCUMENTS_PAGE_FLATS: 'documents-page-flats',
        DOCUMENTS_PAGE_TENANT: 'documents-page-tenant',
        DOCUMENTS_PAGE_GROUP: 'documents-page-group',
    },
    ACTIONS: {
        CHANGE_PROFILE_PICTURE: 'change-profile-picture',
        CHANGE_LANGUAGE: 'change-language',
        VIEW_DOCUMENT: 'view-document',
        CHANGE_NOTIFICATIONS: 'change-notifications',
        CHANGE_PASSWORD: 'change-password',
        TICKET_SUBMIT: 'ticket-submit',
        TICKET_CANCELED: 'ticket-canceled',
        RATING_CREATED: 'rating-created',
        RATING_EDITED_SERVICE_PROVIDER: 'rating-edited-service-provider',
        RATING_EDITED_MANAGER: 'rating-edited-manager',
        OPEN_TERMS: 'open-terms',
        MOBILE_NUMBER_CHANGED: 'mobile-number-changed',
        LOGIN: 'login',
        LOGOUT: 'logout',
        ON_EXPAND_TICKET_STATUS_CARD: 'on-expand-ticket-status-card',
        ON_EXPAND_TICKET_NOTES_CARD: 'on-expand-ticket-notes-card',
        ON_EXPAND_TICKET_APPOINTMENT_CARD: 'on-expand-ticket-appointment-card',
        ON_EXPAND_TICKET_APPOINTMENT_SERVICE_PROVIDER: 'on-expand-ticket-appointment-service-provider',
        ON_EXPAND_TICKET_DESCRIPTION_CARD: 'on-expand-ticket-description-card',
        ON_EXPAND_TICKET_PICTURES_CARD: 'on-expand-ticket-pictures-card',
        ON_EXPAND_TICKET_RATING_CARD: 'on-expand-ticket-rating-card',
        ON_EXPAND_TICKET_MANAGER_CARD: 'on-expand-ticket-manager-card',
        ON_EXPAND_TICKET_CONTACT_CARD: 'on-expand-ticket-contact-card',
        DELETE_PROFILE_PICTURE: 'delete-profile-picture',
        VIEW_TICKET_PICTURES: 'view-ticket-pictures',
        VIEW_BULLETIN_BOARD_PICTURES: 'view-bulletin-board-pictures',
    },
};
