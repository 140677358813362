<ion-header>
    <app-topbar
        [title]="'other-person-info.header' | translate"
        [left]="'close'"
        (leftClicked)="modalController.dismiss(null, 'dismiss')"></app-topbar>
</ion-header>
<ion-content>
    <form *ngIf="form" [formGroup]="form">
        <ion-card>
            <ion-card-content class="ion-no-padding">
                <div>
                    <ion-item>
                        <ion-label position="stacked"> {{ 'other-person-info.other-name' | translate }}* </ion-label>
                        <ion-input formControlName="otherName" (ionBlur)="validateInput('otherName')"></ion-input>
                    </ion-item>
                    <ion-item>
                        <ion-label position="stacked"> {{ 'other-person-info.other-tel' | translate }}* </ion-label>
                        <ion-input
                            type="tel"
                            [match]="mobileRegex"
                            formControlName="otherTel"
                            (ionBlur)="validateInput('otherTel')"></ion-input>
                    </ion-item>
                </div>
            </ion-card-content>
        </ion-card>
        <ion-button expand="block" color="primary" (click)="submit(true)" [disabled]="form.invalid">
            {{ 'other-person-info.button-submit' | translate }}
        </ion-button>

        <ion-button *ngIf="presentPerson?.representation" expand="block" color="danger" (click)="submit(false)">
            {{ 'other-person-info.button-delete' | translate }}
        </ion-button>
    </form>
</ion-content>
