import { Injectable } from '@angular/core';
import { BuildInfo } from '@ionic-native/build-info/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class OriginService {
    private cordovaMapping = {
        'ch.woonig.tenant': 'woonig.app',
        'ch.woonig.tenant.cip': 'ci-platform.app',
        'ch.woonig.tenant.emeria': 'linkathome.app',
        'ch.woonig.tenant.dl': 'dl.woonig.app',
    };

    private urlMapping = {
        'https://portal.dl.woonig.app': 'dl.woonig.app',
        'https://test.portal.dl.woonig.app': 'dl.woonig.app',
        'https://test.portal.ci-platform.app': 'ci-platform.app',
        'https://test.mobile.ci-platform.app': 'ci-platform.app',
        'https://mobile.ci-platform.app': 'ci-platform.app',
        'https://portal.ci-platform.app': 'ci-platform.app',
        'https://mobile.linkathome.app': 'linkathome.app',
        'https://portal.linkathome.app': 'linkathome.app',
    };

    // e.g. for key postfixes
    private whiteLabelMapping = {
        'linkathome.app': 'linkathome',
    };

    public pluginReady = false;
    public origin = null;
    public whiteLabelKey: string = null;

    constructor(private platform: Platform) {}

    initialize() {
        if (this.platform.is('cordova') && !BuildInfo?.prototype?.packageName) {
            setTimeout(() => {
                // workaround for lazy build info when app is deployed to device
                return this.initialize();
            }, 25);
        }

        if (BuildInfo?.prototype?.packageName) {
            this.origin = this.cordovaMapping[BuildInfo?.prototype?.packageName || 'ch.woonig.tenant'];
        } else if (!this.platform.is('cordova')) {
            this.origin = this.urlMapping[window.location.origin] || 'woonig.app';
        }

        this.whiteLabelKey = this.whiteLabelMapping[this.origin] || null;
        this.pluginReady = true;
        return this.origin;
    }
}
