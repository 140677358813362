import { CraftsmanService } from '../../../services/craftsman/craftsman.service';
import { Component, Input, OnInit } from '@angular/core';
import { ANALYTICS } from '../../../const/analytics.const';
import { AnalyticsService } from '../../../services/analytics';

@Component({
    selector: 'app-ticket-appointment-service-provider',
    templateUrl: './ticket-appointment-service-provider.component.html',
    styleUrls: ['./ticket-appointment-service-provider.component.scss'],
})
export class TicketAppointmentServiceProviderComponent implements OnInit {
    @Input() offers;
    @Input() ticketNo;

    public provider;
    constructor(private analytics: AnalyticsService, private craftsmanService: CraftsmanService) {}

    async ngOnInit() {
        this.provider = await this.craftsmanService.getCraftsman(this.offers.providerId);
    }

    onExpand() {
        this.analytics.trackEvent(ANALYTICS.ACTIONS.ON_EXPAND_TICKET_APPOINTMENT_SERVICE_PROVIDER);
    }
}
