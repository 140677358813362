import { Injectable } from '@angular/core';
import { ThemeService } from '../theme/theme.service';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../data/data.service';
import { UserService } from '../user/user.service';
import { TranslateService } from '@ngx-translate/core';
import { NsUtil } from '../../util/ns';

@Injectable({
    providedIn: 'root',
})
export class LogoutService {
    constructor(
        private themeService: ThemeService,
        private auth: AuthService,
        private dataService: DataService,
        private userService: UserService,
        private translateService: TranslateService,
        private ns: NsUtil
    ) {}

    async doLogout(keepTokens = false) {
        this.dataService.clearAppData();
        this.ns.setNs(null);
        this.translateService.reloadLang(this.translateService.currentLang);
        this.translateService.resetLang(this.translateService.currentLang);
        await this.auth.logout(keepTokens);
        this.userService.user = null;
        this.themeService.setDefault();
    }
}
