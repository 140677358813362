<div *ngIf="show">
    <span (click)="showOriginal($event)" class="color-primary">
        <span *ngIf="toggled">{{ 'general.hide-original-text' | translate }}</span>
        <span *ngIf="!toggled">{{ 'general.show-original-text' | translate }}</span>
    </span>
    <div class="original-text" *ngIf="toggled">
        <h3 *ngIf="title">
            <b>{{ title.original.value }}</b>
        </h3>
        <p *ngIf="text" class="ion-text-wrap" [innerHTML]="text.original.value | safeHtml"></p>
    </div>
</div>
