import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { ShowAttachmentsComponent } from './show-attachments.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [ShowAttachmentsComponent],
    exports: [ShowAttachmentsComponent],
})
export class ShowAttachmentsComponentModule {}
