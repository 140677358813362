import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'iconForFileName',
})
export class IconForFileNamePipe implements PipeTransform {
    transform(name: string) {
        if (!name || !name.length) {
            return null;
        }
        const splitParts = name.split('.');
        const end = splitParts[splitParts.length - 1];
        switch (end) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return 'image';
            case 'pdf':
                return 'document';
        }

        return 'document';
    }
}
