import { Component, OnInit } from '@angular/core';
import { NamespaceService } from '../../services/namespace/namespace.service';
import { UserService } from '../../services/user/user.service';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { OriginService } from 'src/app/services/origin/origin.service';

@Component({
    selector: 'app-start',
    templateUrl: './start.page.html',
    styleUrls: ['./start.page.scss'],
})
export class StartPage implements OnInit {
    secondarySplashLogo;
    origin;

    constructor(
        private namespaceService: NamespaceService,
        private originService: OriginService,
        public userService: UserService,
        public network: Network,
        public platform: Platform
    ) {
        this.origin = this.originService.origin;
    }

    async ngOnInit() {
        this.secondarySplashLogo = await this.namespaceService.getNsSplashLogo();
    }
}
