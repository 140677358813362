import { DocumentsRequests } from './util/documents-requests';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MainPageModule } from './pages/main/main.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';

import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ModalsModule } from './modals/modals.module';
import { ApiTranslationLoaderService } from './services/translation/api-translation-loader.service';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { LoginPageModule } from './pages/login/login.module';
import { StartPageModule } from './pages/start/start.module';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Network } from '@ionic-native/network/ngx';
import { CreateTicketPageModule } from './pages/create/create-ticket.page.module';
import { MaintenanceComponentModule } from './components/templates/maintenance/maintenance.component.module';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { TokenInterceptor } from './services/http-interceptor/token-interceptor';
import { ContactFormPageModule } from './pages/contact-form/contact-form.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { VoiceRecordModule } from './pages/voice-record/voice-record.module';
import { ForceUpdateModalComponentModule } from './modals/force-update/force-update-modal.component.module';
import { PdfComponentModule } from './modals/pdf-viewer/pdf-viewer.component.module';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

export function ApiTranslationLoaderFactory(injector: Injector) {
    return new ApiTranslationLoaderService(injector);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        IonicModule.forRoot(),
        StartPageModule,
        MainPageModule,
        LoginPageModule,
        ContactFormPageModule,
        VoiceRecordModule,
        CreateTicketPageModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        AngularFireAuthModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: ApiTranslationLoaderFactory,
                deps: [Injector],
            },
        }),
        ModalsModule,
        BrowserModule,
        BrowserAnimationsModule,
        MaintenanceComponentModule,
        RecaptchaV3Module,
        ForceUpdateModalComponentModule,
        PdfComponentModule,
    ],
    providers: [
        EmailComposer,
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: REGION, useValue: environment.region },
        FirebaseX,
        DocumentViewer,
        File,
        Camera,
        Geolocation,
        NativeGeocoder,
        Keyboard,
        WebView,
        InAppBrowser,
        Network,
        Deploy,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        DocumentsRequests,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.reCaptcha.webKey,
        },
        BarcodeScanner,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
