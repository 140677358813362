import { Injectable } from '@angular/core';
import { TextObjAggregated } from '../../models/text-obj-aggregated';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { TextObj } from '../../models/text-obj';
import { UserService } from '../user/user.service';
import { BaseDataService } from '../../util/base-data-service';

@Injectable({
    providedIn: 'root',
})
export class TextService implements BaseDataService {
    private sub: Subscription = new Subscription();
    texts$: BehaviorSubject<TextObjAggregated> = new BehaviorSubject<TextObjAggregated>({});

    constructor(private db: AngularFirestore, private userService: UserService) {}

    initialize() {
        return new Promise<void>((resolve) => {
            this.sub = this.db
                .collection(`ns/${this.userService.getNamespace()}/ticketTreeTexts`)
                .snapshotChanges()
                .pipe(
                    map((snaps) => {
                        const mappedTexts: TextObjAggregated = {};
                        for (const snap of snaps) {
                            mappedTexts[snap.payload.doc.id] = snap.payload.doc.data() as TextObj;
                        }
                        return mappedTexts;
                    })
                )
                .subscribe((texts: TextObjAggregated) => {
                    this.texts$.next(texts);
                    resolve();
                });
        });
    }

    get texts(): Observable<TextObjAggregated> {
        return this.texts$.asObservable();
    }

    terminate() {
        this.sub.unsubscribe();
    }
}
