import { Injectable } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class BrowserService {
    constructor(private iab: InAppBrowser, private emailComposer: EmailComposer, private platform: Platform) {}

    callPhone(phoneNumber: string) {
        if (this.platform.is('cordova')) {
            this.iab.create(`tel:${phoneNumber}`);
        } else {
            window.location.href = `tel:${phoneNumber}`;
        }
    }

    async sendMail(emailAddress: string, subject = '', message = '') {
        if (this.platform.is('cordova')) {
            const emailObj = {
                to: emailAddress,
                subject,
                body: message,
                isHtml: false,
            };

            try {
                await this.emailComposer.open(emailObj);
            } catch (err) {
                console.error('Error launching dialer', err);
            }
        } else {
            window.location.href = `mailto:${emailAddress}`;
        }
    }

    open(url: string, target = '_system') {
        let options = '';

        if (target === '_blank') {
            options = 'location=no,hidenavigationbuttons=yes,hideurlbar=yes';
        }

        return this.iab.create(url, target, options);
    }
}
