import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { VoteRepresentationAuthorityComponent } from './vote-representation-authority.component';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [VoteRepresentationAuthorityComponent],
    exports: [VoteRepresentationAuthorityComponent],
    imports: [TranslateModule, ComponentsSharedModule, TopbarComponentModule, ReactiveFormsModule],
})
export class VoteRepresentationAuthorityComponentModule {}
