<ion-row *ngIf="showTitle" class="sub-header ion-margin-top ion-align-items-center">
    {{ 'ticket.appointments-contact' | translate }}
</ion-row>

<ion-list lines="none">
    <ion-item *ngIf="mail" (click)="sendMail(mail)">
        <ion-icon name="mail" slot="start"></ion-icon>
        <ion-label>{{ mail }}</ion-label>
    </ion-item>
    <ion-item *ngIf="phone" (click)="callUser(phone)">
        <ion-icon name="call" slot="start"></ion-icon>
        <ion-label>{{ phone }}</ion-label>
    </ion-item>
</ion-list>
