import { NgModule } from '@angular/core';
import { DocumentSearchComponent } from './document-search.component';
import { ComponentsSharedModule } from 'src/app/components/components.shared.module';
import { TopbarComponentModule } from 'src/app/components/molecules/topbar/topbar.component.module';
import { SearchComponentModule } from 'src/app/components/atoms/search/search.component.module';

@NgModule({
    declarations: [DocumentSearchComponent],
    exports: [DocumentSearchComponent],
    imports: [ComponentsSharedModule, TopbarComponentModule, SearchComponentModule],
})
export class DocumentSearchComponentModule {}
