<ion-header>
    <app-topbar class="ion-text-center" [title]="'legal-documents.modal.tab-header' | translate"></app-topbar>
</ion-header>

<ion-content>
    <ion-card class="accent-border">
        <ion-card-header>
            <ion-card-subtitle>
                {{ 'legal-documents.modal.title' | translate }}
            </ion-card-subtitle>

            <p>
                {{ 'legal-documents.modal.subtitle' | translate }}
            </p>
        </ion-card-header>
        <ion-card-content>
            <app-legal-documents-accept
                (change)="acceptToggleChange($event)"
                [excludedDocuments]="excludedDocuments"
                [showOptional]="showOptional"></app-legal-documents-accept>
        </ion-card-content>
    </ion-card>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-button (click)="confirm()" [disabled]="!hasAccepted" color="primary" class="full">
            {{ 'legal-documents.modal.confirm' | translate }}
        </ion-button>
    </ion-toolbar>
</ion-footer>
