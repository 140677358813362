import { Component, EventEmitter, NgZone, Output } from '@angular/core';
import { TicketGeneratorService } from '../../../services/ticket-generator/ticket-generator.service';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../../environments/environment';

declare let google;

@Component({
    selector: 'app-input-google-places',
    templateUrl: './input-google-places.component.html',
    styleUrls: ['./input-google-places.component.scss'],
})
export class InputGooglePlacesComponent {
    @Output() selected: EventEmitter<string> = new EventEmitter<string>();
    @Output() submitText: EventEmitter<any> = new EventEmitter<any>();
    autocomplete: { input: string };
    autocompleteItems: any[];
    GoogleAutocomplete: any;
    GooglePlaceService: any;
    hasSelected: boolean = false;

    constructor(public zone: NgZone, private ticketGeneratorService: TicketGeneratorService) {
        const mapsLoader = new Loader({
            apiKey: environment.googleMapsApiKey,
            libraries: ['places'],
        });

        mapsLoader
            .load()
            .then(() => {
                this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
                this.GooglePlaceService = new google.maps.places.PlacesService(document.createElement('div'));
            })
            .catch((err) => {
                console.error(err);
            });

        this.autocomplete = { input: '' };
        this.autocompleteItems = [];
    }

    updateSearchResults() {
        if (!this.autocomplete.input?.trim()) {
            this.hasSelected = false;
        }

        if (!this.hasSelected) {
            if (this.autocomplete.input == '') {
                this.autocompleteItems = [];
                return;
            }
            this.GoogleAutocomplete.getPlacePredictions({ input: this.autocomplete.input }, (predictions) => {
                if (predictions?.length) {
                    this.autocompleteItems = [];
                    this.zone.run(() => {
                        for (const prediction of predictions) {
                            this.autocompleteItems.push(prediction);
                        }
                    });
                }
            });
        }
    }

    clearAutoComplete() {
        this.autocompleteItems = [];
        this.autocomplete.input = '';
    }

    async selectSearchResults(item) {
        this.selected.emit(item.description);
        this.setLocation(item.place_id);
        this.clearAutoComplete();
        this.autocomplete.input = item.description;
        this.hasSelected = true;
    }

    async setLocation(place_id: string) {
        const place_details: any = await this.getGeoLocationByPlaceId(place_id);
        this.ticketGeneratorService.setLocation(
            place_details.formatted_address,
            place_details.geometry.location.lng(),
            place_details.geometry.location.lat()
        );
    }

    getGeoLocationByPlaceId(place_id: string) {
        return new Promise((resolve) => {
            this.GooglePlaceService.getDetails({ placeId: place_id }, function (place) {
                resolve(place);
            });
        });
    }

    submit() {
        this.submitText.emit();
    }
}
