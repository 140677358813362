import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { FlatService } from '../flat/flat.service';
import { BehaviorSubject, Subscription, combineLatest, Observable } from 'rxjs';
import { Property } from '../../models/property';
import { BaseDataService } from '../../util/base-data-service';
import { arrayRemoveDuplicates } from '../../util/util';
import { ApiService } from '../api/api.service';
import { PropertyService } from '../property/property.service';

@Injectable({
    providedIn: 'root',
})
export class GroupService implements BaseDataService {
    private propertiesSubscription: Subscription = new Subscription();
    private groupsSubscription: Subscription = new Subscription();
    groups$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>(null);

    constructor(
        private userService: UserService,
        private db: AngularFirestore,
        private flatService: FlatService,
        private http: HttpClient,
        private apiService: ApiService,
        private propertyService: PropertyService
    ) {}

    initialize() {
        this.propertiesSubscription = this.propertyService.properties$.subscribe((properties: Property[]) => {
            if (properties) {
                const propertiesWithUniqueGroupId: any[] = arrayRemoveDuplicates(properties, 'groupId');

                const groupObservables = propertiesWithUniqueGroupId
                    .filter((property) => property?.groupId)
                    .map((property) => this.getObservableById(property.groupId));

                if (groupObservables.length) {
                    this.groupsSubscription = combineLatest(groupObservables).subscribe((groups: any[]) => {
                        this.groups$.next(groups);
                    });
                } else {
                    this.groups$.next([]);
                }
            }
        });
    }

    getObservableById(id: string): Observable<any> {
        return this.db.doc(`ns/${this.userService.user.ns}/groups/${id}`).valueChanges();
    }

    async getById(id: string) {
        const snap = await this.db.doc(`ns/${this.userService.user.ns}/groups/${id}`).get().toPromise();

        return snap.data();
    }

    isOwnerOfGroupById(groupId: string): boolean {
        return this.propertyService
            .getProperties()
            .filter((property) => property.groupId === groupId)
            .some((property) => {
                return this.userService.user.ownedProperties.some((ownedProperty) => {
                    return property.id === ownedProperty.id;
                });
            });
    }

    terminate() {
        this.groups$.next(null);
        this.propertiesSubscription.unsubscribe();
        this.groupsSubscription.unsubscribe();
    }
}
