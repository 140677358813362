import { FileDocument } from '../../../models/file-document';
import { DesktopFilepickerService } from '../../../services/desktop-filepicker/desktop-filepicker.service';
import { DocumentUtilService } from '../../../services/document-util/document-util.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PhotoViewerComponent } from '../../../modals/photo-viewer/photo-viewer.component';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-attachments-add',
    templateUrl: './attachments-add.component.html',
    styleUrls: ['./attachments-add.component.scss'],
})
export class AttachmentsAddComponent {
    @Output() documentsChanged: EventEmitter<{
        imgs: FileDocument[];
        pdfs: FileDocument[];
    }> = new EventEmitter<{ imgs: FileDocument[]; pdfs: FileDocument[] }>();
    @Input() documents: { pdfs: FileDocument[]; imgs: FileDocument[] } = {
        pdfs: [],
        imgs: [],
    };
    @Input() fileTypes = 'application/pdf, image/jpeg, image/jpg, image/png';
    @Input() label = 'general.attachments';
    @Input() editable = false;

    constructor(
        private modalController: ModalController,
        private filePicker: DesktopFilepickerService,
        private documentUtilService: DocumentUtilService
    ) {}

    async onClickFileInput() {
        const pickedFiles = await this.filePicker.pickMultiple(this.fileTypes);

        for (const key of Object.keys(pickedFiles)) {
            const file = pickedFiles[key];
            if (file.mimetype === 'application/pdf') {
                this.documents.pdfs.push(file);
            } else if (file.mimetype.indexOf('image/') === 0) {
                this.documents.imgs.push(file);
            }
        }

        this.documentsChanged.emit(this.documents);
    }

    onDeletePdf(index: number) {
        this.documents.pdfs.splice(index, 1);
        this.documentsChanged.emit(this.documents);
    }

    onDeleteImage(index: number) {
        this.documents.imgs.splice(index, 1);
        this.documentsChanged.emit(this.documents);
    }

    async showPhotoPreview(photoIndex) {
        const data = {
            images: [this.documents.imgs[photoIndex]],
            index: 0,
        };

        const modal = await this.modalController.create({
            component: PhotoViewerComponent,
            componentProps: data,
        });
        await modal.present();
    }

    async openDocument(document: FileDocument) {
        await this.documentUtilService.openDocument(document);
    }
}
