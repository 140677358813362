import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { StepperComponent } from '../../components/organisms/stepper/stepper.component';

@Component({
    selector: 'app-voice-record',
    templateUrl: './voice-record.page.html',
    styleUrls: ['./voice-record.page.scss'],
})
export class VoiceRecordPage implements OnInit {
    @ViewChild('stepper') stepper: StepperComponent;

    @Input() language: string;
    @Input() nsData: any;

    isCompleted = false;
    isRecorded = false;
    steps;
    audio;

    constructor(
        private renderer: Renderer2,
        public modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {}

    async ngOnInit() {
        this.steps = [
            {
                label: this.translate.instant('voice-record.record'),
                active: true,
                complete: false,
            },
            {
                label: this.translate.instant('voice-record.preview'),
                active: false,
                complete: false,
            },
            {
                label: this.translate.instant('voice-record.done'),
                active: false,
                complete: false,
            },
        ];

        this.renderer.addClass(document.body, 'show-recaptcha-badge');
    }

    getAudio(audio) {
        this.isRecorded = true;
        this.stepper.next();
        this.audio = audio;
    }

    retry() {
        this.isRecorded = false;
        this.stepper.previous();
        this.audio = null;
    }

    isAudioComplete(status) {
        this.isCompleted = status;
    }

    async closePopup() {
        if (this.isRecorded && !this.isCompleted) {
            const alert = await this.alertCtrl.create({
                message: this.translate.instant('voice.record.quit-message'),
                buttons: [
                    {
                        text: this.translate.instant('voice.record.cancel'),
                    },
                    {
                        text: this.translate.instant('voice.record.quit'),
                        handler: () => {
                            this.modalCtrl.dismiss();
                        },
                    },
                ],
            });

            await alert.present();
        } else {
            await this.modalCtrl.dismiss();
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'show-recaptcha-badge');
    }
}
