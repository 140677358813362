import { Component, EventEmitter, Output } from '@angular/core';
import { TicketGeneratorService } from '../../../services/ticket-generator/ticket-generator.service';
import { PropertyService } from '../../../services/property/property.service';
import { UserService } from '../../../services/user/user.service';
import { ElasticService } from '../../../services/elastic/elastic.service';

@Component({
    selector: 'app-input-autocomplete',
    templateUrl: './input-autocomplete.component.html',
    styleUrls: ['./input-autocomplete.component.scss'],
})
export class InputAutocompleteComponent {
    isItemAvailable = false;
    items = [];
    hasSelected = false;
    input: string;
    @Output() selected: EventEmitter<string> = new EventEmitter<string>();
    @Output() submitText: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private ticketGeneratorService: TicketGeneratorService,
        private propertyService: PropertyService,
        private userService: UserService,
        private elasticService: ElasticService
    ) {}

    initializeItems() {
        this.propertyService
            .searchPropertyByAddress(this.input, this.userService.getNamespace())
            .subscribe((data: any) => {
                this.items = this.elasticService.mapResult(data);
            });
    }

    getItems() {
        if (!this.hasSelected) {
            this.initializeItems();
        }
    }

    selectSearchResults(address: any) {
        this.selected.emit(address.searchLabel);
        this.ticketGeneratorService.setLocation(
            address.searchLabel,
            address.geopoint?._longitude,
            address.geopoint?._latitude,
            address.zipcode,
            address.city,
            address.id
        );
        this.clearAutoComplete();
        this.input = address.searchLabel;
        this.hasSelected = true;
    }

    submit() {
        this.submitText.emit();
    }

    clearAutoComplete() {
        this.items = [];
        this.input = '';
    }
}
