import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Manager } from '../../models/manager';
import { TicketNode } from '../../models/ticket-node';
import { TicketTreeService } from '../../services/ticket-tree/ticket-tree.service';
import { TicketGeneratorService } from '../../services/ticket-generator/ticket-generator.service';
import { ManagerMessagePipe } from '../../pipes/chat-message/manager-message';
import { UserMessagePipe } from '../../pipes/chat-message/user-message';
import { ValidationService } from '../../services/validation/validation.service';
import { BrowserService } from '../../services/browser/browser.service';
import { AnalyticsService } from '../../services/analytics';
import { ANALYTICS } from '../../const/analytics.const';
import { Timeout } from '../../util/timeout';
import {
    CREATE_ERROR,
    CREATE_WAIT,
    INVALID_ADDRESS,
    INVALID_EMAIL,
    OPTIONS,
    STEP_CONTACT_ADDRESS,
    STEP_CONTACT_EMAIL,
    STEP_CONTACT_NAME,
    STEP_SUMMARY,
    STEP_TYPE,
    WHICH_ADDRESS,
    WHICH_EMAIL,
    WHICH_NAME,
    INVALID_NAME,
} from '../../const/ticket.const';
import { Appointment } from '../../models/appointment';
import { AppointmentsComponent } from '../../modals/appointments/appointments.component';
import { getLastElement } from '../../util/util';
import { InputOptions, InputType } from '../../const/input.const';
import { UserService } from '../../services/user/user.service';
import { ReCaptchaService } from '../../services/recaptcha/recaptcha.service';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.page.html',
    styleUrls: ['./contact-form.page.scss'],
})
export class ContactFormPage implements OnInit, OnDestroy {
    @Input() language: string;
    @Input() nsData: any;
    @Input() embedded: boolean = false;

    manager: Manager;
    chatMessages: any[] = [];
    currentStep: TicketNode;
    similarTickets: { total: number; items: string[] };
    ticketType;
    private subscriptions: Subscription[] = [];
    private filterDuplicateTickets: boolean;
    private objectTypeSelected = false;
    inputOptions: InputOptions = {
        visible: false,
        imageUpload: false,
        type: InputType.Text,
    };

    constructor(
        private ticketTreeService: TicketTreeService,
        private ticketGeneratorService: TicketGeneratorService,
        private managerMessage: ManagerMessagePipe,
        private userMessage: UserMessagePipe,
        private modalCtrl: ModalController,
        private validator: ValidationService,
        public navController: NavController,
        private browser: BrowserService,
        private translate: TranslateService,
        private analytics: AnalyticsService,
        private userService: UserService,
        private alertCtrl: AlertController,
        private reCaptchaService: ReCaptchaService,
        private renderer: Renderer2
    ) {}

    async ngOnInit() {
        this.renderer.addClass(document.body, 'show-recaptcha-badge');
        this.embedded && this.renderer.addClass(document.body, 'top-recaptcha-badge');

        this.analytics.trackView(ANALYTICS.VIEWS.CREATE_TICKET_PAGE);

        this.userService.setNameSpaceFromUrl(this.nsData.id);
        this.language = this.language || this.translate.getBrowserLang();

        await this.initializeChat();
    }

    private async initializeChat() {
        await this.ticketGeneratorService.init();
        this.ticketGeneratorService.setOriginalLanguage(this.language);

        this.currentStep = WHICH_NAME;
        this.chatMessages.push(this.managerMessage.transform(this.currentStep, null));
        await Timeout(250);

        this.handleInputOptionsForNextStep(STEP_CONTACT_NAME);
    }

    private async handleStepComplete(selected: TicketNode = { id: null, text: '' }) {
        if (selected.id === OPTIONS.RESTART) {
            await this.reset();
            return;
        }

        const nextStep: TicketNode =
            selected.id && selected.id.includes(OPTIONS.CONTINUE) && !this.ticketType
                ? STEP_SUMMARY
                : await this.ticketTreeService.next(selected.id);

        if (nextStep) {
            this.currentStep = nextStep;

            if (nextStep.config && nextStep.config.nlapi) {
                this.filterDuplicateTickets = true;
            }

            if (!this.chatMessages.length) {
                // initial wait
                await Timeout(250);
            } else {
                // delete last chat message (options)
                const indexToRemove = this.getLastMessageIndexWithButtons();

                if (indexToRemove !== -1) {
                    this.chatMessages.splice(indexToRemove, 1);
                }
                // add user response
                const userResponse = this.userMessage.transform(selected);

                if (userResponse) {
                    this.chatMessages.push(userResponse);
                    await Timeout(250);
                }
            }

            if (selected.id === OPTIONS.SEND) {
                this.chatMessages.push(this.managerMessage.transform(CREATE_WAIT, this.manager));

                this.chatMessages = this.chatMessages.slice();

                const reCaptchaToken = await this.reCaptchaService.execute('createTicketRequest').toPromise();

                const createSuccess = await Promise.all([
                    this.ticketGeneratorService.createTicketRequest(reCaptchaToken),
                    Timeout(250),
                ])
                    .then(() => {
                        this.analytics.trackEvent(ANALYTICS.ACTIONS.TICKET_SUBMIT);
                        return true;
                    })
                    .catch(async (err) => {
                        await Timeout(250);

                        const message = CREATE_ERROR;

                        if (err.status === 520) {
                            message.text = `[520] ${this.translate.instant('general.contact-administration')}`;
                        } else if (err.status === 406) {
                            message.text = this.translate.instant('ticket-request.errors.captcha-failed');
                        }

                        this.chatMessages.push(this.managerMessage.transform(message, this.manager));
                        this.chatMessages.push(this.managerMessage.transform(message.children, null));

                        this.chatMessages = this.chatMessages.slice();
                        return null;
                    });

                if (!createSuccess) {
                    return;
                }
            }

            // write manager text
            this.chatMessages.push(this.managerMessage.transform(nextStep, this.manager));
            // trigger ngOnChanges in ChatViewComponent
            this.chatMessages = this.chatMessages.slice();
            if (nextStep?.children?.length) {
                await Timeout(250);
                // write options
                this.chatMessages.push(this.managerMessage.transform(nextStep.children, null));
                // trigger ngOnChanges in ChatViewComponent
                this.chatMessages = this.chatMessages.slice();
            }

            this.handleInputOptionsForNextStep(nextStep);
        } else {
            this.close();
        }
    }

    async optionSelected(option: TicketNode) {
        if (!this.objectTypeSelected) {
            this.ticketGeneratorService.setObjectType(option.text);
            this.objectTypeSelected = true;
        }
        if (option.id === OPTIONS.DESCRIPTION_CONTINUE) {
            this.inputOptions.visible = false;
            this.ticketGeneratorService.finishDescription();
        } else if (option.id === OPTIONS.CREATE_APPOINTMENT) {
            const appointments = await this.getAppointments();
            if (appointments) {
                this.ticketGeneratorService.setAppointments(appointments);
            }
        } else if (option.id === OPTIONS.NO_APPOINTMENT) {
            this.ticketGeneratorService.setAppointments([]);
        } else if (option.id === OPTIONS.PHONE || option.id === OPTIONS.NO_CONTACT) {
            this.ticketGeneratorService.setContactMethod(option);
        } else if (
            option.id === OPTIONS.SEND ||
            option.id === OPTIONS.RESTART ||
            option.id === OPTIONS.NO_APPOINTMENT ||
            option.id === OPTIONS.CONTINUE
        ) {
            this.ticketGeneratorService.next(option);
        } else if (option.id === OPTIONS.CLOSE) {
            await this.reset();
            this.close();
        } else if (option.id === OPTIONS.EMAIL_CORRECT) {
            this.inputOptions.visible = false;
            this.ticketGeneratorService.setContactEmail();
        } else if (option.id === OPTIONS.PHONE_CORRECT) {
            this.inputOptions.visible = false;
            this.ticketGeneratorService.setContactPhone();
        } else if (option.id === OPTIONS.EMAIL_MANAGER) {
            await this.browser.sendMail(this.manager.email);
        } else if (option.id === OPTIONS.EMAIL) {
            this.ticketGeneratorService.setContactEmail();
        } else {
            this.ticketGeneratorService.hashSelected(option);
        }
    }

    textEntered(text) {
        if (this.currentStep.id === STEP_TYPE.DESCRIPTION) {
            if (text) {
                this.chatMessages.splice(this.chatMessages.length - 1, 0, {
                    type: STEP_TYPE.TEXT,
                    text,
                    wasSent: true,
                });
                this.ticketGeneratorService.addDescription(text);
            } else {
                this.inputOptions.visible = false;
                this.ticketGeneratorService.finishDescription();
            }
        } else if (this.currentStep.id === STEP_TYPE.ADDRESS) {
            if (text && text.trim()) {
                if (!this.ticketGeneratorService.newTicket?.contact.location) {
                    this.ticketGeneratorService.setLocation(text);
                }
                this.subscriptions.push(
                    this.ticketGeneratorService.stepComplete$.subscribe((selectedId) => {
                        this.handleStepComplete(selectedId);
                    })
                );
            } else {
                this.chatMessages.push(this.managerMessage.transform(INVALID_ADDRESS, this.manager, null, true));
                this.handleInputOptionsForNextStep(STEP_CONTACT_ADDRESS);
            }
        } else if (this.currentStep.id === STEP_TYPE.EMAIL) {
            text = text.trim();

            if (this.validator.isValidEmail(text)) {
                this.inputOptions.visible = false;
                this.currentStep = STEP_CONTACT_ADDRESS;
                this.handleInputOptionsForNextStep(STEP_CONTACT_ADDRESS);
                this.chatMessages.push(this.userMessage.transform(text));
                this.chatMessages.push(this.managerMessage.transform(WHICH_ADDRESS, this.manager));
                this.ticketGeneratorService.setContactEmail(text);
            } else {
                this.chatMessages.push(this.managerMessage.transform(INVALID_EMAIL, this.manager, null, true));
                this.handleInputOptionsForNextStep(STEP_CONTACT_EMAIL);
            }
        } else if (this.currentStep.id === STEP_TYPE.NAME) {
            if (text && text.trim()) {
                this.ticketGeneratorService.setName(text);
                this.chatMessages.push(this.userMessage.transform(text));
                this.currentStep = WHICH_EMAIL;
                this.chatMessages.push(this.managerMessage.transform(this.currentStep, null));
                this.handleInputOptionsForNextStep(WHICH_EMAIL);
            } else {
                this.chatMessages.push(this.managerMessage.transform(INVALID_NAME, this.manager, null, true));
                this.handleInputOptionsForNextStep(STEP_CONTACT_NAME);
            }
        }
    }

    imagesUploaded(images) {
        for (const image of images) {
            if (typeof image === 'string') {
                // mobile app
                this.chatMessages.splice(this.chatMessages.length - 1, 0, {
                    type: STEP_TYPE.IMAGE,
                    src: image,
                    wasSent: true,
                });
                this.ticketGeneratorService.addImage({
                    name: `${new Date().getTime().toString()}.jpeg`,
                    file: image,
                    mimetype: 'image/jpeg',
                });
            } else {
                // web/desktop
                this.chatMessages.splice(this.chatMessages.length - 1, 0, {
                    type: STEP_TYPE.IMAGE,
                    src: image.file,
                    wasSent: true,
                });
                this.ticketGeneratorService.addImage(image);
            }
        }
        this.chatMessages = this.chatMessages.slice();
    }

    imageDeleted(messageIndex: number) {
        const imageToDeleteIndex = this.chatMessages.findIndex((cm) => cm.type === STEP_TYPE.IMAGE);
        this.ticketGeneratorService.removeImage(messageIndex - imageToDeleteIndex);
        this.chatMessages.splice(messageIndex, 1);
        this.chatMessages = this.chatMessages.slice();
    }

    private async getAppointments(): Promise<Appointment[]> {
        const modal = await this.modalCtrl.create({
            component: AppointmentsComponent,
            cssClass: 'appointments-modal',
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data as Appointment[];
    }

    private getLastMessageIndexWithButtons() {
        return getLastElement(this.chatMessages, 'type', STEP_TYPE.SELECTION);
    }

    private handleInputOptionsForNextStep(nextStep: TicketNode) {
        switch (nextStep.id) {
            case STEP_TYPE.DESCRIPTION:
                this.inputOptions = {
                    visible: true,
                    imageUpload: true,
                    type: InputType.Text,
                };
                break;
            case STEP_TYPE.NAME:
                this.inputOptions = {
                    visible: true,
                    imageUpload: false,
                    type: InputType.Text,
                };
                break;
            case STEP_TYPE.EMAIL:
                this.inputOptions = {
                    visible: true,
                    imageUpload: false,
                    type: InputType.Text,
                };
                break;
            case STEP_TYPE.PHONE:
                this.inputOptions = {
                    visible: true,
                    imageUpload: false,
                    type: InputType.Phone,
                };
                break;
            case STEP_TYPE.ADDRESS:
                this.inputOptions = {
                    visible: true,
                    imageUpload: false,
                    type: this.nsData?.autocompleteMode || InputType.NONE,
                };
        }
    }

    close() {
        return this.modalCtrl.dismiss();
    }

    private async reset() {
        await this.resetData();
        await this.initializeChat();
    }

    private async resetData() {
        this.manager = null;
        this.currentStep = null;
        this.chatMessages = [];
        this.similarTickets = null;
        this.filterDuplicateTickets = false;
        await this.ticketGeneratorService.reset();
        this.ticketTreeService.reset();

        for (const sub of this.subscriptions) {
            if (sub) {
                sub.unsubscribe();
            }
        }
    }

    ngOnDestroy(): void {
        this.resetData();
        this.renderer.removeClass(document.body, 'show-recaptcha-badge');
    }

    async closePopup() {
        if (this.currentStep?.id != 'complete') {
            const alert = await this.alertCtrl.create({
                message: this.translate.instant('chat.quit-message'),
                buttons: [
                    {
                        text: this.translate.instant('chat.cancel'),
                    },
                    {
                        text: this.translate.instant('chat.quit'),
                        handler: () => {
                            this.close();
                        },
                    },
                ],
            });
            alert.present();
        } else {
            this.close();
        }
    }
}
