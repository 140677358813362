import { TicketNode } from '../models/ticket-node';

export enum STEP_TYPE {
    EMAIL = 'email',
    NAME = 'name',
    PHONE = 'phone',
    ADDRESS = 'address',
    TEXT = 'text',
    SELECTION = 'selection',
    DESCRIPTION = 'description',
    APPOINTMENT = 'appointment',
    SUMMARY = 'summary',
    IMAGE = 'image',
    COMPLETE = 'complete',
    CONTACT_METHOD = 'contact_method',
    DUPLICATE = 'duplicate',
}

export enum OPTIONS {
    PICK_TICKET_OBJECT = 'pick_ticket_object',
    EMAIL_MANAGER = 'call_manager',
    EMAIL_CREATE = 'email_create',
    CONTINUE = 'continue',
    RESTART = 'restart',
    SEND = 'send',
    EMAIL_CORRECT = 'email_correct',
    ADDRESS_CORRECT = 'address_correct',
    PHONE_CORRECT = 'phone_correct',
    NAME_CORRECT = 'name_correct',
    EMAIL = 'email',
    PHONE = 'phone',
    BOTH = 'both',
    NO_CONTACT = 'no_contact',
    CREATE_APPOINTMENT = 'create_appointment',
    NO_APPOINTMENT = 'no_appointment',
    PRESENT_MYSELF = 'me',
    PRESENT_OTHER = 'other',
    DESCRIPTION_CONTINUE = 'desc_continue',
    CLOSE = 'close',
}

export const STEP_COMPLETE = {
    id: STEP_TYPE.COMPLETE,
    text: 'ticket-create.creation-complete',
    children: [
        {
            id: OPTIONS.CLOSE,
            text: 'ticket-create.close',
        },
    ],
};
export const WHICH_NAME = {
    id: STEP_TYPE.NAME,
    text: 'ticket-create.which-name',
};
export const WHICH_EMAIL = {
    id: STEP_TYPE.EMAIL,
    text: 'ticket-create.which-email',
};

export const WHICH_ADDRESS = {
    id: STEP_TYPE.ADDRESS,
    text: 'ticket-create.which-address',
};

export const WHICH_OBJECT_TICKET = {
    id: '',
    text: 'ticket-create.which-object-ticket',
    children: [
        {
            id: OPTIONS.PICK_TICKET_OBJECT,
            text: 'ticket-create.select',
        },
    ],
};

export const INVALID_EMAIL = {
    id: '',
    text: 'ticket-create.invalid-email',
};

export const INVALID_NAME = {
    id: '',
    text: 'ticket-create.invalid-name',
};

export const INVALID_ADDRESS = {
    id: '',
    text: 'ticket-create.invalid-address',
};

export const INVALID_PHONE = {
    id: '',
    text: 'ticket-create.invalid-phone',
};

export const CREATE_WAIT = {
    id: '',
    text: 'ticket-create.create-wait',
};

export const CREATE_ERROR = {
    id: '',
    text: 'ticket-create.create-error',
    children: [
        {
            id: OPTIONS.EMAIL_MANAGER,
            text: 'ticket-create.send-email',
        },
        {
            id: OPTIONS.CLOSE,
            text: 'ticket-create.close',
        },
    ],
};

export const CREATE_WAIT_DUPLICATES = {
    id: '',
    text: 'ticket-create.create-wait-duplicates',
};

export const STEP_SUMMARY = {
    id: STEP_TYPE.SUMMARY,
    text: 'ticket-create.summary',
    children: [
        {
            id: OPTIONS.RESTART,
            text: 'ticket-create.restart',
        },
        {
            id: OPTIONS.SEND,
            text: 'ticket-create.send',
            children: [STEP_COMPLETE],
        },
    ],
};

export const STEP_CONTACT_EMAIL = {
    id: STEP_TYPE.EMAIL,
    text: '', // texts are loaded later in manager-message.ts
};
export const STEP_CONTACT_NAME = {
    id: STEP_TYPE.NAME,
    text: '', // texts are loaded later in manager-message.ts
};
export const STEP_CONTACT_PHONE = {
    id: STEP_TYPE.PHONE,
    text: '', // texts are loaded later in manager-message.ts
};

export const STEP_CONTACT_ADDRESS = {
    id: STEP_TYPE.ADDRESS,
    text: '', // texts are loaded later in manager-message.ts
};
export const STEP_CONTACT_METHOD = {
    id: STEP_CONTACT_PHONE.id,
    text: STEP_CONTACT_PHONE.text,
    children: [
        {
            id: OPTIONS.PHONE_CORRECT,
            text: 'ticket-create.correct',
            children: [STEP_SUMMARY],
        },
    ],
};

export const STEP_APPOINTMENT = {
    id: STEP_TYPE.APPOINTMENT,
    text: 'ticket-create.appointment-message',
    children: [
        {
            id: OPTIONS.CREATE_APPOINTMENT,
            text: 'ticket-create.add-appointments',
            children: [STEP_CONTACT_METHOD],
        },
        {
            id: OPTIONS.NO_APPOINTMENT,
            text: 'ticket-create.no-appointments',
            children: [STEP_CONTACT_METHOD],
        },
    ],
};

export const STEP_DUPLICATE = {
    id: STEP_TYPE.DUPLICATE,
    text: '', // text is added in manager-message.ts
    children: [
        {
            id: OPTIONS.CONTINUE,
            text: 'ticket-create.duplicate-continue',
            children: [STEP_APPOINTMENT],
        },
        {
            id: OPTIONS.CLOSE,
            text: 'ticket-create.close',
        },
    ],
};

export const STEP_DESCRIPTION: TicketNode = {
    id: STEP_TYPE.DESCRIPTION,
    text: 'ticket-create.description-message',
    children: [
        {
            id: OPTIONS.DESCRIPTION_CONTINUE,
            text: 'ticket-create.description-continue',
            children: [STEP_APPOINTMENT],
        },
    ],
};

export const TICKET_TREE_EXTENSION_ROOT = STEP_DESCRIPTION;
