import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[match]',
    host: {
        '(input)': 'handleInput($event.target.value)',
    },
})
export class RegexMatchDirective {
    @Input('match') match: RegExp;

    constructor(private elementRef: ElementRef) {}

    handleInput(value) {
        this.elementRef.nativeElement.value = value.replace(this.match, '');
    }
}
