import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { StepperComponent } from './stepper.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [StepperComponent],
    exports: [StepperComponent],
})
export class StepperComponentModule {}
