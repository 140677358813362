import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { ChangePasswordComponent } from './change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';

@NgModule({
    declarations: [ChangePasswordComponent],
    exports: [ChangePasswordComponent],
    imports: [ComponentsSharedModule, ReactiveFormsModule, FormsModule, TopbarComponentModule],
})
export class ChangePasswordComponentModule {}
