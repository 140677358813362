import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FlatService } from '../flat/flat.service';
import { UserService } from '../user/user.service';
import { PropertyService } from '../property/property.service';
import { STORAGE } from '../../const/storage.const';
import { StorageService } from '../storage/storage.service';
import { Document } from '../../models/document';
import { BaseDataService } from '../../util/base-data-service';
import { Flat } from '../../models/flat';
import { Property } from '../../models/property';
import { GroupService } from '../group/group.service';
import { User } from 'src/app/models/user';

@Injectable({
    providedIn: 'root',
})
export class DocumentService implements BaseDataService {
    private user: User;

    private groupSub: Subscription = new Subscription();
    private propertySub: Subscription = new Subscription();
    private flatSub: Subscription = new Subscription();
    private userSub: Subscription = new Subscription();

    groupDocuments$: BehaviorSubject<{ group: any; documents: Document[] }[]> = new BehaviorSubject<
        { group: any; documents: Document[] }[]
    >(null);
    propertyDocuments$: BehaviorSubject<{ property: Property; documents: Document[] }[]> = new BehaviorSubject<
        { property: Property; documents: Document[] }[]
    >(null);
    flatsDocuments$: BehaviorSubject<{ flat: Flat; documents: Document[] }[]> = new BehaviorSubject<
        { flat: Flat; documents: Document[] }[]
    >(null);
    personalDocuments$: BehaviorSubject<Document[]> = new BehaviorSubject<Document[]>(null);
    unseenDocuments$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        private flatService: FlatService,
        private propertyService: PropertyService,
        private userService: UserService,
        private storageService: StorageService,
        private groupService: GroupService
    ) {}

    initialize() {
        this.groupSub = this.groupService.groups$.subscribe((groups) => {
            if (groups) {
                const newDocs = [];

                for (const group of groups) {
                    newDocs.push({ group, documents: this.processFiles(group) });
                }

                this.groupDocuments$.next(newDocs);
                this.unseenDocuments$.next(this.getUnseenDocuments());
            }
        });

        this.propertySub = this.propertyService.properties$.subscribe((properties: Property[]) => {
            if (properties) {
                const newDocs = [];
                for (const property of properties) {
                    newDocs.push({
                        property,
                        documents: this.processFiles(property),
                    });
                }

                this.propertyDocuments$.next(newDocs);
                this.unseenDocuments$.next(this.getUnseenDocuments());
            }
        });

        this.flatSub = this.flatService.flats$.subscribe((flats: Flat[]) => {
            if (flats) {
                const newDocs = [];
                for (const flat of flats) {
                    newDocs.push({ flat, documents: this.processFiles(flat) });
                }
                this.flatsDocuments$.next(newDocs);
                this.unseenDocuments$.next(this.getUnseenDocuments());
            }
        });

        this.userSub = this.userService.user$.subscribe((user) => {
            this.user = user;
            this.personalDocuments$.next(this.processFiles(this.user));
            this.unseenDocuments$.next(this.getUnseenDocuments());
        });
    }

    async combineAllDocuments() {
        const propertyDocuments = this.propertyDocuments$.getValue() || [];
        const flatsDocuments = this.flatsDocuments$.getValue() || [];
        const userDocuments = this.personalDocuments$.getValue() || [];
        const groupDocuments = this.groupDocuments$.getValue() || [];

        const allDocs = []
            .concat(
                ...propertyDocuments.map((fde) => {
                    fde.documents.map((doc) => {
                        doc.model = 'property';
                        doc.modelId = fde.property.id;
                        doc.modelName = fde.property.name;
                        return doc;
                    });
                    return fde.documents;
                }),
                ...flatsDocuments.map((fde) => {
                    fde.documents.map((doc) => {
                        doc.model = 'flat';
                        doc.modelId = fde.flat.id;
                        doc.modelName = fde.flat.name;
                        return doc;
                    });
                    return fde.documents;
                }),
                ...groupDocuments.map((fde) => {
                    fde.documents.map((doc) => {
                        doc.model = 'group';
                        doc.modelId = fde.group.id;
                        doc.modelName = fde.group.name;
                        return doc;
                    });
                    return fde.documents;
                }),
                ...userDocuments.map((doc) => {
                    doc.model = 'user';
                    doc.modelName = this.user?.searchLabel;
                    return doc;
                })
            )
            .filter((doc) => {
                if (doc) {
                    return doc;
                }
            });

        return allDocs;
    }

    markDocumentsSeen(documents: string[]) {
        if (this.unseenDocuments$.getValue()) {
            let seenDocuments = this.storageService.get(STORAGE.SEEN_DOCUMENTS) as string[];
            if (!seenDocuments) {
                seenDocuments = [];
            }
            seenDocuments = seenDocuments.concat(documents);
            const unique = seenDocuments.filter((item, pos) => {
                return seenDocuments.indexOf(item) === pos;
            });

            this.storageService.set(STORAGE.SEEN_DOCUMENTS, unique);
        }
    }

    private getUnseenDocuments(): number {
        const groupDocuments = this.groupDocuments$.getValue() || [];
        const propertyDocuments = this.propertyDocuments$.getValue() || [];
        const flatsDocuments = this.flatsDocuments$.getValue() || [];
        const userDocuments = this.personalDocuments$.getValue() || [];

        const allDocs = []
            .concat(
                ...propertyDocuments.map((fde) => fde.documents),
                ...flatsDocuments.map((fde) => fde.documents),
                ...groupDocuments.map((fde) => fde.documents),
                ...userDocuments
            )
            .filter((doc) => {
                return doc !== null;
            });
        let count = 0;
        if (allDocs.length) {
            const seenDocuments = this.storageService.get(STORAGE.SEEN_DOCUMENTS) || [];
            for (const doc of allDocs) {
                if (seenDocuments.indexOf(doc.name) === -1) {
                    count++;
                }
            }
        }
        return count;
    }

    private processFiles(unit) {
        if (!unit || !unit.documents) {
            return [];
        }
        return [...(unit.documents.imgs || []), ...(unit.documents.pdfs || [])].filter((doc) => !!doc);
    }

    terminate() {
        this.groupDocuments$.next(null);
        this.propertyDocuments$.next(null);
        this.personalDocuments$.next(null);
        this.flatsDocuments$.next(null);
        this.unseenDocuments$.next(0);
        this.groupSub.unsubscribe();
        this.propertySub.unsubscribe();
        this.flatSub.unsubscribe();
        this.userSub.unsubscribe();
    }
}
