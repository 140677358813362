import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { AppInjector } from './app/services/app-injector/app-injector.service';
import * as Sentry from '@sentry/browser';

if (environment.production) {
    enableProdMode();
}

function fixKeyboardAppearance(keyboard) {
    keyboard.hideFormAccessoryBar(false);
}

function initLogger() {
    if (environment['sentry']) {
        Sentry.init(environment['sentry']);
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((module) => {
        AppInjector.setInjector(module.injector);
        fixKeyboardAppearance(module.injector.get(Keyboard));
        initLogger();
    });
