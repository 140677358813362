import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { ChatBubbleComponent } from './chat-bubble.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [ChatBubbleComponent],
    exports: [ChatBubbleComponent],
})
export class ChatBubbleComponentModule {}
