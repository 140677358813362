import { LoggerService } from '../services/logger/logger.service';
import { AppInjector } from '../services/app-injector/app-injector.service';
import { AnalyticsService } from '../services/analytics';

export class BaseClass {
    protected logger: LoggerService;
    protected name = 'test';

    constructor(className) {
        this.logger = new LoggerService(className);
    }
}
