import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TicketNode } from '../../../models/ticket-node';

@Component({
    selector: 'app-chat-message-selection',
    templateUrl: './chat-message-selection.component.html',
    styleUrls: ['./chat-message-selection.component.scss'],
})
export class ChatMessageSelectionComponent implements OnInit {
    @Input('data') options;
    @Output() selected: EventEmitter<TicketNode> = new EventEmitter<TicketNode>();
    filteredOptions: TicketNode[];

    ngOnInit() {
        this.filteredOptions = this.options.filter((op) => !op.hidden);
    }

    optionClicked(option: TicketNode) {
        this.selected.emit(option);
    }
}
