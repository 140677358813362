import { AfterContentChecked, Component, Input } from '@angular/core';
import { TextObj } from '../../../models/text-obj';
import { UserService } from '../../../services/user/user.service';

@Component({
    selector: 'original-text',
    templateUrl: 'original-text.html',
    styleUrls: ['original-text.scss'],
})
export class OriginalTextComponent implements AfterContentChecked {
    @Input() title?: TextObj;
    @Input() text?: TextObj;

    show = false;
    toggled = false;

    constructor(private userService: UserService) {}

    ngAfterContentChecked() {
        if (this.title || this.text) {
            const originalTextLanguage = this.text ? this.text.original.language : undefined;
            const originalTitleLanguage = this.title ? this.title.original.language : undefined;
            const userLanguage = this.userService.user.language;

            if (
                (originalTextLanguage && originalTextLanguage !== userLanguage) ||
                (originalTitleLanguage && originalTitleLanguage !== userLanguage)
            ) {
                this.show = true;
            }
        }
    }

    showOriginal($event: any) {
        $event.stopPropagation();
        this.toggled = !this.toggled;
    }
}
