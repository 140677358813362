<app-basic-card (expansion)="onExpand()" [header]="'other-person-info.manager'">
    <div *ngFor="let manager of managers" class="manager-entry" [ngClass]="{ transparent: manager.vacation }">
        <ion-item lines="none">
            <ion-avatar>
                <img [src]="manager.profilePicture || DEFAULT_PROFILE_PICTURE" alt="" />
            </ion-avatar>
            <ion-label> {{ manager.firstname }} {{ manager.lastname }} </ion-label>
            <ion-text *ngIf="manager.vacation" color="danger">
                {{ 'other-person-info.manager-absent' | translate }}
            </ion-text>
        </ion-item>

        <app-contact-phone-mail [mail]="manager.email" [phone]="manager.mobile" [ticketNo]="data.ticketNo">
        </app-contact-phone-mail>
    </div>
</app-basic-card>
