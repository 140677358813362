import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OriginService } from '../../../services/origin/origin.service';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
    maintenanceLogo = `${environment.apiBase}namespaces/image/logo_maintenance.png?X-App-Origin=${this.originService.origin}`;
    constructor(private originService: OriginService) {}
}
