import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { AudioComponent } from './audio.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [AudioComponent],
    exports: [AudioComponent],
})
export class AudioComponentModule {}
