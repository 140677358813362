import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { ForceUpdateModalComponent } from './force-update-modal.component';

@NgModule({
    declarations: [ForceUpdateModalComponent],
    exports: [ForceUpdateModalComponent],
    imports: [ComponentsSharedModule, TopbarComponentModule],
})
export class ForceUpdateModalComponentModule {}
