import { Directive, ElementRef, OnInit } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { Timeout } from '../../util/timeout';

@Directive({
    selector: '[appAutoFocus]',
})
export class AutofocusDirective implements OnInit {
    constructor(private el: ElementRef<IonInput>) {}

    async ngOnInit() {
        await Timeout(800);
        const inputEl = await this.el.nativeElement.getInputElement();
        inputEl.focus();
    }
}
