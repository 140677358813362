import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient) {}

    head(endpoint: string, options?: any): Promise<any> {
        return this.http.head(`${environment.apiBase}${endpoint}`, options).toPromise();
    }

    get(endpoint: string): Promise<any> {
        return this.http.get(`${environment.apiBase}${endpoint}`).toPromise();
    }

    post(endpoint: string, data?: any): Promise<any> {
        return this.http.post(`${environment.apiBase}${endpoint}`, data).toPromise();
    }

    put(endpoint: string, data?: any): Promise<any> {
        return this.http.put(`${environment.apiBase}${endpoint}`, data).toPromise();
    }

    delete(endpoint: string): Promise<any> {
        return this.http.delete(`${environment.apiBase}${endpoint}`).toPromise();
    }
}
