import { Component, Input } from '@angular/core';
import { Document } from '../../../models/document';
import { DocumentUtilService } from 'src/app/services/document-util/document-util.service';
import { PopupService } from '../../../services/popup/popup.service';
import { AnalyticsService } from '../../../services/analytics';
import { ANALYTICS } from '../../../const/analytics.const';

@Component({
    selector: 'app-document-view',
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.scss'],
})
export class DocumentViewComponent {
    @Input() documents: Document[];
    @Input() header: string;
    @Input() lines = 'full';
    @Input() noPadding = false;
    progress = 0;

    constructor(private documentUtilService: DocumentUtilService, private analytics: AnalyticsService) {}

    async openDocument(document: Document) {
        this.analytics.trackEvent(ANALYTICS.ACTIONS.VIEW_DOCUMENT);
        await this.documentUtilService.openDocument(document);
    }
}
