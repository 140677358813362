<ion-header>
    <app-topbar [title]="'voice-record.header' | translate" [left]="'close'" (leftClicked)="closePopup()"></app-topbar>
</ion-header>
<ion-content [scrollY]="false">
    <div class="container">
        <app-stepper [steps]="steps" #stepper></app-stepper>

        <app-recording
            *ngIf="!isRecorded"
            [maxRecordingTimeInSeconds]="nsData?.multichannel?.voiceRecordingMaxSeconds || 14"
            (getAudio)="getAudio($event)"></app-recording>
        <app-audio
            *ngIf="isRecorded"
            [audio]="audio"
            [namespace]="nsData.id"
            [language]="language"
            (nextStep)="stepper.next()"
            (retry)="retry()"
            (closeModal)="modalCtrl.dismiss()"
            (audioSent)="isAudioComplete($event)"></app-audio>
    </div>
</ion-content>
