import { AppointmentsComponent } from './appointments.component';
import { NgModule } from '@angular/core';
import { ListWithChipComponentModule } from '../../components/organisms/list-with-chip/list-with-chip.component.module';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';

@NgModule({
    declarations: [AppointmentsComponent],
    exports: [AppointmentsComponent],
    imports: [ComponentsSharedModule, ListWithChipComponentModule, TopbarComponentModule],
})
export class AppointmentsComponentModule {}
