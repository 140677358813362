<ion-header>
    <app-topbar
        [title]="'ticket.notes.create' | translate"
        [left]="'close'"
        (leftClicked)="modalController.dismiss()"></app-topbar>
</ion-header>

<ion-content>
    <div class="ion-padding-horizontal ion-padding-bottom">
        <ion-textarea
            [(ngModel)]="note.description"
            [rows]="rows"
            [autoGrow]="!isMobileDevice"
            [placeholder]="'ticket.notes.add-description' | translate"></ion-textarea>
    </div>
    <div class="ion-padding-start">
        <app-attachments-add
            [fileTypes]="'image/png, image/jpeg, application/pdf'"
            [documents]="note.documents || { imgs: [], pdfs: [] }"
            (documentsChanged)="onDocumentsChange($event)"></app-attachments-add>
    </div>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-row *ngIf="mode === 'ticket'">
            <ion-col class="total-center">
                <ion-button
                    class="w-100"
                    color="primary"
                    expand="block"
                    (click)="saveNote('manager')"
                    [disabled]="!note.description || note.description.length < 1"
                    ><ion-icon name="person-circle"></ion-icon>
                    {{ 'ticket.notes.recipient-manager' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="mode === 'ticket'">
            <ion-col class="total-center">
                <ion-button
                    *ngIf="allowProviderNote"
                    class="w-100"
                    color="primary"
                    expand="block"
                    (click)="saveNote('provider')"
                    [disabled]="!note.description || note.description.length < 1"
                    ><ion-icon name="construct"></ion-icon>
                    {{ 'ticket.notes.recipient-provider' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="mode === 'blackboard'">
            <ion-col class="total-center">
                <ion-button
                    class="w-100"
                    color="primary"
                    expand="block"
                    (click)="saveNote('tenant')"
                    [disabled]="!note.description || note.description.length < 1">
                    {{ 'blackboard.send-comment' | translate }}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-toolbar>
</ion-footer>
