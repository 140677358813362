import { Injectable } from '@angular/core';
import { Appointment } from '../../models/appointment';
import { BehaviorSubject } from 'rxjs';
import { OPTIONS } from '../../const/ticket.const';
import { TicketsService } from '../tickets/tickets.service';
import { UserService } from '../user/user.service';
import { TicketTreeService } from '../ticket-tree/ticket-tree.service';
import { TicketNode } from '../../models/ticket-node';
import { Document } from '../../models/document';

export enum TicketContactType {
    USER = 'user',
    MANAGER = 'manager',
    MANAGER_OTHER = 'manager_other',
    OTHER = 'other',
    PROPERTY = 'property',
}

export interface TicketContact {
    id?: string;
    type: TicketContactType;
    name?: string;
    phone?: string;
    email?: string;
}

@Injectable({
    providedIn: 'root',
})
export class TicketGeneratorService {
    stepComplete$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public newTicket;
    private similarTickets: any[];

    constructor(
        private ticketsService: TicketsService,
        private userService: UserService,
        private ticketTree: TicketTreeService
    ) {}

    async init() {
        this.newTicket = await this.emptyTicket();
    }

    hashSelected(hashtag) {
        const ht = this.ticketTree.getHashtag(hashtag.id);
        if (ht) {
            this.newTicket.hashtags.push(ht);
        }
        this.stepComplete$.next(hashtag);
    }

    getDescription() {
        return this.newTicket.description;
    }

    setAppointments(appointments: Appointment[]) {
        this.newTicket.appointments = appointments;
        if (appointments.length) {
            this.stepComplete$.next({
                id: OPTIONS.CREATE_APPOINTMENT,
                text: '',
            });
        } else {
            this.newTicket.pendingAppointment = true;
            this.stepComplete$.next({
                id: OPTIONS.NO_APPOINTMENT,
                text: '',
            });
        }
    }

    getAppointments() {
        return this.newTicket.appointments;
    }

    private async emptyTicket() {
        return {
            appointments: [],
            description: '',
            hashtags: [],
            pictures: [],
            contact: {
                id: null,
                type: null,
            },
            originalLanguage: null,
        };
    }

    getContact() {
        if (this.newTicket.contact.type === 'user') {
            return OPTIONS.PRESENT_MYSELF;
        }
        return {
            canEnter: this.newTicket.contact.type || null,
            otherPerson: {
                name: this.newTicket.contact.name || null,
                mobile: this.newTicket.contact.phone || null,
            },
        };
    }

    setContactMethod(option) {
        this.newTicket.contact.phone = option.id === OPTIONS.PHONE;
        this.stepComplete$.next({
            id: option.id,
            text: option.text,
        });
    }

    createTicket() {
        return this.ticketsService.createTicket(this.newTicket);
    }

    createTicketRequest(reCaptchaToken: string) {
        return this.ticketsService.createTicketRequest(this.newTicket, reCaptchaToken);
    }

    setContactEmail(email?: string) {
        if (email || this.userService.user?.email) {
            this.newTicket.contact.email = email || this.userService.user.email;
            this.stepComplete$.next({ id: OPTIONS.EMAIL_CORRECT, text: email });
        }
    }

    setContactAddress(address?: string) {
        this.newTicket.contact.address = address || this.userService.user.email;
        this.stepComplete$.next({ id: OPTIONS.ADDRESS_CORRECT, text: address });
    }

    setName(name) {
        this.newTicket.name = name;
        this.stepComplete$.next({ id: OPTIONS.NAME_CORRECT, text: name });
    }

    setLocation(name: string, longitude?: string, latitude?: string, zipcode?: string, city?: string, id?: string) {
        this.newTicket.contact.location = {
            name: name,
            long: longitude || null,
            lat: latitude || null,
            zipcode: zipcode || null,
            city: city || null,
        };

        this.newTicket.propertyId = id || null;

        this.stepComplete$.next({
            id: OPTIONS.ADDRESS_CORRECT,
            text: name,
        });
    }

    getContactEmail() {
        return this.newTicket.contact.email;
    }

    setContactPhone(mobile?: string) {
        this.newTicket.contact.phone = mobile || this.userService.user.mobile;
        this.stepComplete$.next({ id: OPTIONS.PHONE_CORRECT, text: mobile });
    }

    setContactType(value: TicketContactType) {
        const tenantName = `${this.userService.user.firstname ? this.userService.user.firstname + ' ' : ''}${
            this.userService.user.lastname || ''
        }`;

        const contact: TicketContact = {
            id: this.userService.user.id,
            name: tenantName || null,
            email: this.userService.user.email || null,

            phone: this.userService.user.mobile || null,
            type: value,
        };
        this.newTicket.contact = Object.assign(this.newTicket.contact, contact);
    }

    next(option: TicketNode) {
        this.stepComplete$.next({ id: option.id, text: option.text });
    }

    getHashtagIds() {
        return this.newTicket.hashtags.map((ht) => ht.id);
    }

    getHashtagTextIds() {
        return this.newTicket.hashtags.map((ht) => ht.textId);
    }

    addDescription(description) {
        this.newTicket.description = this.newTicket.description
            ? `${this.newTicket.description}<br>${description}`
            : description;
    }

    addImage(image: Document) {
        this.newTicket.pictures.push(image);
    }

    finishDescription() {
        this.stepComplete$.next({ id: OPTIONS.DESCRIPTION_CONTINUE, text: '' });
    }

    setSimilarTickets(similarTickets: []) {
        this.similarTickets = similarTickets;
    }

    getSimilarTickets() {
        return this.similarTickets;
    }

    async reset() {
        this.newTicket = await this.emptyTicket();
        this.similarTickets = null;
        this.stepComplete$.next(undefined);
    }

    removeImage(imageIndex: number) {
        this.newTicket.pictures.splice(imageIndex, 1);
    }

    setFlatId(flatId) {
        this.newTicket.flatId = flatId;
    }

    setPropertyId(propertyId) {
        this.newTicket.propertyId = propertyId;
    }

    getPropertyId() {
        return this.newTicket.propertyId;
    }

    getTicket() {
        return this.newTicket;
    }

    setObjectType(objectType: string) {
        this.newTicket.objectType = objectType;
    }

    setOriginalLanguage(language: string) {
        this.newTicket.originalLanguage = language;
    }
}
