import { OPTIONS, STEP_CONTACT_METHOD, STEP_TYPE } from './ticket.const';
import { ChatMessage } from '../models/chat-message';
import { TicketNode } from '../models/ticket-node';

export const CHAT_STORNO_STEP = {
    CONFIRM: 'confirm',
    WHY: 'why',
};

export const STEP_WHY: TicketNode = {
    id: CHAT_STORNO_STEP.WHY,
    text: 'ticket-storno.question-why',
};

export const STEP_CONFIRM: TicketNode = {
    id: CHAT_STORNO_STEP.CONFIRM,
    text: 'ticket-storno.confirm',
    children: [
        {
            id: OPTIONS.CLOSE,
            text: 'chat.cancel',
        },
        {
            id: OPTIONS.CONTINUE,
            text: 'chat.continue',
        },
    ],
};

export const STEP_STORNO_SUCCESS = {
    id: '',
    text: 'ticket-storno.storno-success',
    children: [
        {
            id: OPTIONS.CLOSE,
            text: 'chat.close',
        },
    ],
};

export const STEP_STORNO_FAILED = {
    id: '',
    text: 'ticket-storno.storno-failed',
    children: [
        {
            id: OPTIONS.CLOSE,
            text: 'chat.close',
        },
    ],
};
