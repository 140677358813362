import { Injectable } from '@angular/core';
import { ElasticService } from '../elastic/elastic.service';
import { UserService } from '../user/user.service';
import { FlatService } from '../flat/flat.service';
import { PropertyService } from '../property/property.service';
import { DocumentService } from '../document/document.service';
import { NamespaceService } from '../namespace/namespace.service';
import { TicketsService } from '../tickets/tickets.service';
import { TextService } from '../text/text.service';
import { TicketTreeDataService } from '../ticket-tree-data/ticket-tree-data.service';
import { CloudMessagingService } from '../cloud-messaging/cloud-messaging.service';
import { ThemeService } from '../theme/theme.service';
import { BlackboardService } from '../blackboard/blackboard.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language/language.service';
import { GroupService } from '../group/group.service';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    appDataInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private elasticService: ElasticService,
        private userService: UserService,
        private blackboardService: BlackboardService,
        private flatService: FlatService,
        private propertyService: PropertyService,
        private documentService: DocumentService,
        private namespaceService: NamespaceService,
        private ticketService: TicketsService,
        private textService: TextService,
        private ticketTreeDataService: TicketTreeDataService,
        private cloudMessaging: CloudMessagingService,
        private themeService: ThemeService,
        private translate: TranslateService,
        private languageService: LanguageService,
        private groupService: GroupService
    ) {}

    async initializeAppData() {
        await this.textService.initialize();
        if (this.userService.namespaceFromUrl?.length) {
            await this.ticketTreeDataService.initialize();
        } else {
            const user = await this.userService.getUser();

            if (!user.language) {
                await this.userService.setLanguage(this.translate.currentLang);
                this.translate.resetLang(this.translate.currentLang);
                await this.translate.reloadLang(this.translate.currentLang).toPromise();
            } else {
                if (user.language === this.translate.currentLang) {
                    this.translate.use(null);
                }

                this.translate.resetLang(user.language);
                await this.translate.use(user.language);
                await this.userService.setLanguage(user.language, true);
            }

            await Promise.all([
                this.ticketService.initialize(),
                this.flatService.initialize(),
                this.propertyService.initialize(),
                this.documentService.initialize(),
                this.elasticService.initialize(),
                this.blackboardService.initialize(),
                this.ticketTreeDataService.initialize(),
                this.groupService.initialize(),
            ]);
        }

        await this.languageService.initialize();

        this.appDataInitialized$.next(true);
    }

    async initializeEssentialAppData() {
        await this.userService.getUser();
        return Promise.all([
            this.userService.getTheme().then((theme) => {
                this.themeService.setTheme(theme);
            }),
            this.namespaceService.initialize(),
        ]);
    }

    clearAppData() {
        this.ticketService.terminate();
        this.blackboardService.terminate();
        this.flatService.terminate();
        this.propertyService.terminate();
        this.documentService.terminate();
        this.namespaceService.terminate();
        this.textService.terminate();
        this.cloudMessaging.terminate();
        this.userService.terminate();
        this.groupService.terminate();

        this.appDataInitialized$.next(false);
    }
}
