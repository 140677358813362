import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NamespaceInfo } from '../../models/namespace-info';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor(private db: AngularFirestore, private userService: UserService) {}

    getElasticConfig() {
        return this.db
            .doc(`ns/${this.userService.getNamespace()}`)
            .get()
            .toPromise()
            .then((nsInfo) => {
                const namespaceInfo = nsInfo.data() as NamespaceInfo;
                return namespaceInfo.elastic;
            });
    }
}
