import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    exports: [IonicModule, TranslateModule, CommonModule, PipesModule, FormsModule],
})
export class ComponentsSharedModule {}
