import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { BasicCardComponent } from './basic-card.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [BasicCardComponent],
    exports: [BasicCardComponent],
})
export class BasicCardComponentModule {}
