import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ListWithChipComponent } from './list-with-chip.component';

@NgModule({
    imports: [ComponentsSharedModule, PipesModule],
    declarations: [ListWithChipComponent],
    exports: [ListWithChipComponent],
})
export class ListWithChipComponentModule {}
