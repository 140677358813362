import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class NsUtil {
    private ns;

    getNs() {
        return this.ns;
    }

    setNs(newNs) {
        this.ns = newNs;
    }
}
