import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../../services/analytics';
import { ANALYTICS } from '../../../const/analytics.const';

@Component({
    selector: 'app-ticket-contact-card',
    templateUrl: './ticket-contact-card.component.html',
    styleUrls: ['./ticket-contact-card.component.scss'],
})
export class TicketContactCardComponent {
    @Input() ticketContact: any;
    @Input() ticketFrom: any;
    @Input() ticketNo: number | string;

    constructor(private analytics: AnalyticsService) {}

    onExpand() {
        this.analytics.trackEvent(ANALYTICS.ACTIONS.ON_EXPAND_TICKET_CONTACT_CARD);
    }
}
