import * as Sentry from '@sentry/browser';

export class LoggerService {
    private readonly className = '';

    constructor(name) {
        this.className = name;
    }

    error(message, arg?) {
        console.error(`[${this.className}]: `, message, arg);
        Sentry.captureException(`[${this.className}]: ${message}\n${arg ? JSON.stringify(arg) : ''}`);
    }

    log(message, arg?) {
        console.log(`[${this.className}]: `, message, arg);
    }
}
