import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { ChatBubbleComponentModule } from '../../atoms/chat-bubble/chat-bubble.component.module';
import { ChatMessageImageComponent } from './chat-message-image.component';

@NgModule({
    imports: [ComponentsSharedModule, ChatBubbleComponentModule],
    declarations: [ChatMessageImageComponent],
    exports: [ChatMessageImageComponent],
})
export class ChatMessageImageComponentModule {}
