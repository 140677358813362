<div class="bubble-wrapper" [ngClass]="messageClass + (this.wasSent ? ' sent' : '')" @chatMessageAppear>
    <ion-item lines="none">
        <ion-avatar class="ion-margin-right" *ngIf="profilePicture"> <img [src]="profilePicture" /></ion-avatar>
        <ion-card [slot]="wasSent ? 'end' : ''">
            <ion-card-content>
                <ng-content></ng-content>
            </ion-card-content>
        </ion-card>
    </ion-item>
</div>
