export function getLastElement(array, searchField, searchedCondition) {
    let index = array
        .slice()
        .reverse()
        .findIndex((cm) => {
            return cm[searchField] === searchedCondition;
        });
    if (index > -1) {
        index = array.length - 1 - index;
    }

    return index;
}

export function arrayRemoveDuplicates(array, property) {
    return array.filter((prop, idx, arr) => {
        return arr.map((mapObj) => mapObj[property]).indexOf(prop[property]) === idx;
    });
}

export function convertFirestoreDate(json) {
    for (const field of Object.getOwnPropertyNames(json)) {
        if (json[field] && typeof json[field] === 'object' && typeof json[field].toDate === 'function') {
            json[field] = json[field].toDate();
        } else if (json[field] && typeof json[field] === 'object') {
            convertFirestoreDate(json[field]);
        }
    }
}

export function getIndexAndType(file: any, documents: any): { index: number; type: 'imgs' | 'pdfs' } {
    let index = -1;
    let documentType: 'imgs' | 'pdfs';

    if (file.mimetype === 'application/pdf') {
        documentType = 'pdfs';
        index = documents.pdfs.findIndex((doc) => doc.file === file.file);
    } else if (file.mimetype.indexOf('image/') === 0) {
        documentType = 'imgs';
        index = documents.imgs.findIndex((doc) => doc.file === file.file);
    }

    return { index, type: documentType };
}
