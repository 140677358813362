import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TicketTreeService } from '../../services/ticket-tree/ticket-tree.service';
import { TicketContactType, TicketGeneratorService } from '../../services/ticket-generator/ticket-generator.service';
import { PropertyService } from '../../services/property/property.service';
import { ProfileService } from '../../services/profile/profile.service';
import { Manager } from '../../models/manager';
import { TicketNode } from '../../models/ticket-node';
import { ManagerMessagePipe } from '../../pipes/chat-message/manager-message';
import { UserMessagePipe } from '../../pipes/chat-message/user-message';
import { Timeout } from '../../util/timeout';
import { Subscription } from 'rxjs';
import {
    CREATE_ERROR,
    CREATE_WAIT,
    CREATE_WAIT_DUPLICATES,
    INVALID_EMAIL,
    STEP_SUMMARY,
    OPTIONS,
    STEP_TYPE,
    WHICH_OBJECT_TICKET,
} from '../../const/ticket.const';
import { getLastElement } from '../../util/util';
import { Appointment } from '../../models/appointment';
import { AppointmentsComponent } from '../../modals/appointments/appointments.component';
import { FlatAccess } from '../../models/flat-access';
import { Contact } from '../../models/contact';
import { OtherPersonInfoComponent } from '../../modals/other-person-info/other-person-info.component';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { ValidationService } from '../../services/validation/validation.service';
import { BrowserService } from '../../services/browser/browser.service';
import { TicketEntitySelectionComponent } from '../../modals/ticket-entity-selection/ticket-entity-selection.component';
import { skip } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics';
import { ANALYTICS } from '../../const/analytics.const';
import { TicketsService } from '../../services/tickets/tickets.service';
import { InputOptions, InputType } from '../../const/input.const';

@Component({
    selector: 'app-create-ticket',
    templateUrl: './create-ticket.page.html',
    styleUrls: ['./create-ticket.page.scss'],
})
export class CreateTicketPage implements OnInit, OnDestroy {
    @ViewChild('managerText', { read: ElementRef })
    managerTextRef: ElementRef;
    manager: Manager;
    chatMessages: any[] = [];
    currentStep: TicketNode;
    similarTickets: { total: number; items: string[] };
    ticketType;
    private sub: Subscription;
    private filterDuplicateTickets: boolean;
    inputOptions: InputOptions = {
        visible: false,
        imageUpload: false,
        type: InputType.Text,
    };

    constructor(
        private ticketTreeService: TicketTreeService,
        private ticketGeneratorService: TicketGeneratorService,
        private propertyService: PropertyService,
        private profileService: ProfileService,
        private managerMessage: ManagerMessagePipe,
        private userMessage: UserMessagePipe,
        private ticketsService: TicketsService,
        private modalCtrl: ModalController,
        private validator: ValidationService,
        public navController: NavController,
        private browser: BrowserService,
        private translate: TranslateService,
        private analytics: AnalyticsService,
        private alertCtrl: AlertController
    ) {}

    async ngOnInit() {
        this.analytics.trackView(ANALYTICS.VIEWS.CREATE_TICKET_PAGE);
        this.initializeChat();
    }

    private async initializeChat() {
        await this.ticketGeneratorService.init();

        this.currentStep = await this.ticketTreeService.next(null);

        this.chatMessages.push(this.managerMessage.transform(this.currentStep, null));
        await Timeout(250);
        this.chatMessages.push(this.managerMessage.transform(WHICH_OBJECT_TICKET.children, null));
    }

    private async handleStepComplete(selected: TicketNode = { id: null, text: '' }) {
        if (selected.id === OPTIONS.RESTART) {
            await this.reset();
            return;
        }

        const nextStep: TicketNode =
            selected.id && selected.id.includes(OPTIONS.CONTINUE) && !this.ticketType
                ? STEP_SUMMARY
                : await this.ticketTreeService.next(selected.id);

        if (nextStep) {
            this.currentStep = nextStep;
            if (nextStep.config && nextStep.config.nlapi) {
                this.filterDuplicateTickets = true;
            }
            if (!this.chatMessages.length) {
                // initial wait
                await Timeout(250);
            } else {
                // delete last chat message (options)
                const indexToRemove = this.getLastMessageIndexWithButtons();
                if (indexToRemove !== -1) {
                    this.chatMessages.splice(indexToRemove, 1);
                }
                // add user response
                const userResponse = this.userMessage.transform(selected);
                if (userResponse) {
                    this.chatMessages.push(userResponse);
                    await Timeout(250);
                }
            }

            if (selected.id === OPTIONS.SEND) {
                this.chatMessages.push(this.managerMessage.transform(CREATE_WAIT, this.manager));
                this.chatMessages = this.chatMessages.slice();
                const createSuccess = await Promise.all([this.ticketGeneratorService.createTicket(), Timeout(250)])
                    .then(() => {
                        this.analytics.trackEvent(ANALYTICS.ACTIONS.TICKET_SUBMIT);
                        return true;
                    })
                    .catch(async (err) => {
                        await Timeout(250);
                        const message = CREATE_ERROR;
                        if (err.status === 520) {
                            message.text = `[520] ${this.translate.instant('general.contact-administration')}`;
                        }
                        this.chatMessages.push(this.managerMessage.transform(message, this.manager));
                        this.chatMessages.push(this.managerMessage.transform(message.children, null));

                        this.chatMessages = this.chatMessages.slice();
                        return null;
                    });
                if (!createSuccess) {
                    return;
                }
            } else if (selected.id === OPTIONS.DESCRIPTION_CONTINUE && this.filterDuplicateTickets) {
                this.chatMessages.push(this.managerMessage.transform(CREATE_WAIT_DUPLICATES, this.manager));

                const similarTicketsPromise = await Promise.all([
                    this.ticketsService.findSimilarTickets(
                        this.ticketGeneratorService.getPropertyId(),
                        this.ticketGeneratorService.getDescription(),
                        this.ticketGeneratorService.getHashtagTextIds()
                    ),
                    Timeout(250),
                ]);

                const similarTickets: any = similarTicketsPromise[0];

                if (similarTickets.length) {
                    this.ticketGeneratorService.setSimilarTickets(similarTickets);
                    this.handleStepComplete({
                        id: STEP_TYPE.DUPLICATE,
                        text: '',
                    });
                    return Promise.resolve();
                }
            }

            // write manager text
            this.chatMessages.push(this.managerMessage.transform(nextStep, this.manager));
            // trigger ngOnChanges in ChatViewComponent
            this.chatMessages = this.chatMessages.slice();
            if (nextStep.children && nextStep.children.length) {
                await Timeout(250);
                // write options
                this.chatMessages.push(this.managerMessage.transform(nextStep.children, null));
                // trigger ngOnChanges in ChatViewComponent
                this.chatMessages = this.chatMessages.slice();
            }

            this.handleInputOptionsForNextStep(nextStep);
        } else {
            this.close();
        }
    }

    async optionSelected(option: TicketNode) {
        if (option.id === OPTIONS.PICK_TICKET_OBJECT) {
            const ticketObject = await this.pickTicketObject();
            await this.processPickedTicketObject(ticketObject);
        } else if (option.id === OPTIONS.DESCRIPTION_CONTINUE) {
            this.inputOptions.visible = false;
            this.ticketGeneratorService.finishDescription();
        } else if (option.id === OPTIONS.CREATE_APPOINTMENT) {
            const appointments = await this.getAppointments();
            if (appointments) {
                this.ticketGeneratorService.setAppointments(appointments);
            }
        } else if (option.id === OPTIONS.NO_APPOINTMENT) {
            this.ticketGeneratorService.setAppointments([]);
        } else if (option.id === OPTIONS.PHONE || option.id === OPTIONS.NO_CONTACT) {
            this.ticketGeneratorService.setContactMethod(option);
        } else if (
            option.id === OPTIONS.SEND ||
            option.id === OPTIONS.RESTART ||
            option.id === OPTIONS.NO_APPOINTMENT ||
            option.id === OPTIONS.CONTINUE
        ) {
            this.ticketGeneratorService.next(option);
        } else if (option.id === OPTIONS.EMAIL_CORRECT) {
            this.inputOptions.visible = false;
            this.ticketGeneratorService.setContactEmail();
        } else if (option.id === OPTIONS.PHONE_CORRECT) {
            this.inputOptions.visible = false;
            this.ticketGeneratorService.setContactPhone();
        } else if (option.id === OPTIONS.EMAIL_MANAGER) {
            await this.browser.sendMail(this.manager.email);
        } else {
            this.ticketGeneratorService.hashSelected(option);
        }
    }

    textEntered(text) {
        if (this.currentStep.id === STEP_TYPE.DESCRIPTION) {
            // there can be multiple description messages added, therefore we need to add them here.
            // handleStepComplete is only triggered when the user clicks "continue", but he needs to
            // see the messages as soon as he enters them
            if (text) {
                this.chatMessages.splice(this.chatMessages.length - 1, 0, {
                    type: STEP_TYPE.TEXT,
                    text,
                    wasSent: true,
                });
                this.ticketGeneratorService.addDescription(text);
            } else {
                this.inputOptions.visible = false;
                this.ticketGeneratorService.finishDescription();
            }
        } else if (this.currentStep.id === STEP_TYPE.EMAIL) {
            if (!text) {
                this.inputOptions.visible = false;
                this.ticketGeneratorService.setContactEmail();
            } else if (this.validator.isValidEmail(text)) {
                this.inputOptions.visible = false;
                // message is added in handleStepComplete->this.userMessage.transform()
                this.ticketGeneratorService.setContactEmail(text);
            } else {
                // write manager text
                this.chatMessages.push(this.managerMessage.transform(INVALID_EMAIL, this.manager, null, true));
            }
        } else if (this.currentStep.id === STEP_TYPE.PHONE) {
            this.inputOptions.visible = false;
            // message is added in handleStepComplete->this.userMessage.transform()
            this.ticketGeneratorService.setContactPhone(text);
        }
        this.chatMessages = this.chatMessages.slice(); // trigger ngOnChanges in ChatViewComponent
    }

    private async getAppointments(): Promise<Appointment[]> {
        const modal = await this.modalCtrl.create({
            component: AppointmentsComponent,
            cssClass: 'appointments-modal',
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data as Appointment[];
    }

    private async getOtherPersonInfo(): Promise<{
        access: FlatAccess;
        otherContact: Contact;
    }> {
        const modal = await this.modalCtrl.create({
            component: OtherPersonInfoComponent,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data as { access: FlatAccess; otherContact: Contact };
    }

    private getLastMessageIndexWithButtons() {
        return getLastElement(this.chatMessages, 'type', STEP_TYPE.SELECTION);
    }

    imagesUploaded(images) {
        for (const image of images) {
            if (typeof image === 'string') {
                // mobile app
                this.chatMessages.splice(this.chatMessages.length - 1, 0, {
                    type: STEP_TYPE.IMAGE,
                    src: image,
                    wasSent: true,
                });
                this.ticketGeneratorService.addImage({
                    name: `${new Date().getTime().toString()}.jpeg`,
                    file: image,
                    mimetype: 'image/jpeg',
                });
            } else {
                // web/desktop
                this.chatMessages.splice(this.chatMessages.length - 1, 0, {
                    type: STEP_TYPE.IMAGE,
                    src: image.file,
                    wasSent: true,
                });
                this.ticketGeneratorService.addImage(image);
            }
        }
        this.chatMessages = this.chatMessages.slice();
    }

    private handleInputOptionsForNextStep(nextStep: TicketNode) {
        switch (nextStep.id) {
            case STEP_TYPE.DESCRIPTION:
                this.inputOptions = {
                    visible: true,
                    imageUpload: true,
                    type: InputType.Text,
                };
                break;
            case STEP_TYPE.EMAIL:
                this.inputOptions = {
                    visible: true,
                    imageUpload: false,
                    type: InputType.Text,
                };
                break;
            case STEP_TYPE.PHONE:
                this.inputOptions = {
                    visible: true,
                    imageUpload: false,
                    type: InputType.Phone,
                };
                break;
            case STEP_TYPE.ADDRESS:
                this.inputOptions = {
                    visible: true,
                    imageUpload: false,
                    type: InputType.GooglePlaces,
                };
                break;
        }
    }

    imageDeleted(messageIndex: number) {
        const imageToDeleteIndex = this.chatMessages.findIndex((cm) => cm.type === STEP_TYPE.IMAGE);
        this.ticketGeneratorService.removeImage(messageIndex - imageToDeleteIndex);
        this.chatMessages.splice(messageIndex, 1);
        this.chatMessages = this.chatMessages.slice();
    }

    private async pickTicketObject() {
        const modal = await this.modalCtrl.create({
            component: TicketEntitySelectionComponent,
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        return data;
    }

    private async processPickedTicketObject(ticketObject) {
        if (!ticketObject) {
            return;
        }

        this.manager = await this.profileService.getManagerProfile(ticketObject.propertyManagerIds[0]);

        let nextStep;

        if (ticketObject.propertyId) {
            // must be a flat
            this.ticketType = ticketObject.type;
            this.ticketGeneratorService.setFlatId(ticketObject.id);
            this.ticketGeneratorService.setPropertyId(ticketObject.propertyId);
            nextStep = this.currentStep.children.find((ch) => {
                return ch.config.flatType === ticketObject.type;
            });
            this.ticketGeneratorService.setContactType(TicketContactType.USER);
        } else {
            // must be a property
            this.ticketGeneratorService.setContactType(TicketContactType.PROPERTY);
            this.ticketGeneratorService.setPropertyId(ticketObject.id);
            nextStep = this.currentStep.children.find((ch) => {
                return !ch.config.flatType; // property has no flat type
            });
        }

        if (ticketObject.formattedLabel) {
            nextStep.text = ticketObject.formattedLabel;
        } else if (ticketObject.propertyId) {
            nextStep.text = `${ticketObject.street}, ${ticketObject.city}${
                ticketObject.floor ? `, ${ticketObject.floor}` : ''
            } (${this.translate.instant(`general.flat-types.${ticketObject.type}`)})`;
        } else {
            nextStep.text = `${ticketObject.street}, ${ticketObject.city}`;
        }

        this.sub = this.ticketGeneratorService.stepComplete$.pipe(skip(1)).subscribe((selectedId) => {
            this.handleStepComplete(selectedId);
        });

        this.ticketGeneratorService.next(nextStep);
    }

    async closePopup() {
        if (this.currentStep.id != 'complete') {
            const alert = await this.alertCtrl.create({
                message: this.translate.instant('chat.quit-message'),
                buttons: [
                    {
                        text: this.translate.instant('chat.cancel'),
                    },
                    {
                        text: this.translate.instant('chat.quit'),
                        handler: () => {
                            this.close();
                        },
                    },
                ],
            });
            alert.present();
        } else {
            this.close();
        }
    }

    close() {
        this.navController.navigateBack('main/tickets', {
            animated: true,
            animationDirection: 'back',
        });
    }

    private async reset() {
        await this.resetData();
        this.sub.unsubscribe();
        await this.initializeChat();
    }

    private async resetData() {
        this.manager = null;
        this.chatMessages = [];
        this.similarTickets = null;
        this.filterDuplicateTickets = false;
        await this.ticketGeneratorService.reset();
        this.ticketTreeService.reset();
    }

    ngOnDestroy(): void {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.resetData();
    }
}
