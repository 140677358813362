import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { STEP_TYPE } from '../../../const/ticket.const';
import { ANIMATIONS } from '../../../util/animations';

@Component({
    selector: 'app-chat-bubble',
    templateUrl: './chat-bubble.component.html',
    styleUrls: ['./chat-bubble.component.scss'],
    animations: [ANIMATIONS.CHAT_MESSAGE_APPEAR],
})
export class ChatBubbleComponent implements OnChanges {
    STEP_TYPE = STEP_TYPE;
    @Input() wasSent: boolean;
    @Input() messageType: STEP_TYPE;
    @Input() profilePicture: string;
    messageClass = '';

    private updateClasses() {
        switch (this.messageType) {
            case STEP_TYPE.SELECTION:
                this.messageClass = 'selection';
                break;
            case STEP_TYPE.DESCRIPTION:
                this.messageClass = 'description';
                break;
            default:
                this.messageClass = '';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateClasses();
    }
}
