import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class PopupService {
    progress = 0;

    constructor(
        private toastController: ToastController,
        private alertController: AlertController,
        private loadingController: LoadingController,
        private translate: TranslateService
    ) {}

    async showToast(message, isError?, options?: { duration?: number; position?: 'top' | 'bottom' | 'middle' }) {
        const opts: {
            message: string;
            duration?: number;
            position?: 'top' | 'bottom' | 'middle';
            color: string;
        } = {
            message,
            duration: 3000,
            color: isError ? 'danger' : undefined,
            position: 'top',
        };
        if (options) {
            if (options.duration) {
                opts.duration = options.duration;
            }
            if (options.position) {
                opts.position = options.position;
            }
        }
        const tc = await this.toastController.create(opts);

        await tc.present();
        return tc;
    }

    async showAlert(title, message, buttons) {
        const alert = await this.alertController.create({
            header: title,
            buttons,
            message,
            backdropDismiss: false,
        });
        return alert.present();
    }

    async showConfirmation(title: string, message: string) {
        return new Promise<boolean>(async (resolve) => {
            const alert = await this.alertController.create({
                header: title,
                message: message,
                backdropDismiss: false,
                buttons: [
                    {
                        text: this.translate.instant('general.cancel'),
                        role: 'cancel',
                        handler: () => {
                            resolve(false);
                        },
                    },
                    {
                        text: this.translate.instant('general.yes'),
                        role: 'ok',
                        handler: () => {
                            resolve(true);
                        },
                    },
                ],
            });

            await alert.present();
            await alert.onDidDismiss();
        });
    }

    async showInfo(message: string) {
        return new Promise<boolean>(async (resolve) => {
            const alert = await this.alertController.create({
                message: this.translate.instant(message),
                backdropDismiss: false,
                buttons: [
                    {
                        text: this.translate.instant('general.ok'),
                        role: 'ok',
                        handler: () => {
                            resolve(true);
                        },
                    },
                ],
            });

            await alert.present();
            await alert.onDidDismiss();
        });
    }

    async showInputAlert(title, message, buttons, inputs) {
        const alert = await this.alertController.create({
            header: title,
            buttons,
            message,
            inputs,
        });
        return alert.present();
    }

    // async showProgressPopup(transfer: { transfer: FileTransferObject; download: Promise<any> }) {
    // let percentage = 0;
    // const loading = await this.loadingController.create({
    //     message: `${this.translate.instant('general.loading')}: ${percentage}%`,
    // });
    //
    // await loading.present();
    // const finish = (e?) => {
    //     this.progress = 0;
    //     loading.dismiss();
    //
    //     if (e && e instanceof Error) {
    //         throw e;
    //     }
    // };
    //
    // transfer.transfer.onProgress((progress) => {
    //     this.progress = progress.loaded / progress.total;
    //     percentage = this.progress * 100 - ((this.progress * 100) % 1);
    //     loading.message = `${this.translate.instant('general.loading')}: ${percentage}%`;
    // });
    //
    // transfer.download.then(finish).catch(finish);
    // }
}
