import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api/api.service';

@Injectable()
export class DocumentsRequests {
    constructor(private http: HttpClient, private apiService: ApiService) {}
    async upload(objectName: string, objectId: string, files: any[]) {
        const formData = new FormData();
        for (const file of files) {
            const name = file.editedName && file.editedName.length ? file.editedName : file.name;
            formData.append(name, file.file, name);
        }
        return this.http.post(`${environment.apiBase}${objectName}/${objectId}/documents`, formData).toPromise();
    }

    async rename(objectName: string, objectId: string, docType: 'imgs' | 'pdfs', index: number, docName: string) {
        return this.http
            .post(`${environment.apiBase}${objectName}/${objectId}/documents/${docType}/${index}/rename`, {
                name: docName,
            })
            .toPromise();
    }

    async delete(objectName: string, objectId: string, docType: 'imgs' | 'pdfs', index: number) {
        return this.http
            .delete(`${environment.apiBase}${objectName}/${objectId}/documents/${docType}/${index}`)
            .toPromise();
    }

    async updateContentTypeBySignedUrl(signedUrl: string) {
        return await this.apiService.post(`documents/updateContentType`, {
            signedUrl,
        });
    }
}
