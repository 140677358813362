import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { convertFirestoreDate } from 'src/app/util/util';

@Injectable({
    providedIn: 'root',
})

/**
 * Service class for legal documents to handle data access with the api and firestore
 *
 */
export class LegalDocumentsService {
    legalDocumentsModalRef: HTMLIonModalElement;

    private documentNames = ['termsOfUse', 'privacyPolicy', 'userTermsOfUse', 'evoteTermsOfUse'];

    constructor(private firestore: AngularFirestore, private apiService: ApiService) {}

    /**
     * Used to get observable of legal documents
     * Dates in the result will already be converted
     *
     * @returns Observable of the "legalDocuments" document in firestore
     */
    public getLegalDocumentsObservable() {
        return this.firestore
            .collection('public', (ref) => ref.where('id', '==', 'legalDocuments'))
            .valueChanges()
            .pipe(
                map((documentArray: any[]) => {
                    const document = documentArray[0];
                    convertFirestoreDate(document);
                    return document;
                })
            );
    }

    public async getLatestLegalDocuments() {
        return await new Promise((resolve) => {
            const subscription = this.getLatestLegalDocumentsObservable().subscribe((value) => {
                subscription.unsubscribe();
                return resolve(value);
            });
        });
    }

    /**
     * Get latest version of legalDocuments
     *
     * @returns Observable of the "legalDocuments" (latest version)
     */
    public getLatestLegalDocumentsObservable() {
        return this.getLegalDocumentsObservable().pipe(
            map((legalDocument) => {
                for (const documentName of this.documentNames) {
                    if (legalDocument[documentName]) {
                        legalDocument[documentName] =
                            legalDocument[documentName][legalDocument[documentName].length - 1];
                    }
                }

                for (const domain of ['woonig', 'cip']) {
                    for (const documentName of this.documentNames) {
                        if (legalDocument[domain][documentName]) {
                            legalDocument[domain][documentName] =
                                legalDocument[domain][documentName][legalDocument[domain][documentName].length - 1];
                        }
                    }
                }

                return legalDocument;
            })
        );
    }

    /**
     * Send a post request to the api to accept the given  legal documents
     * Data will be sent as JSON
     *
     * @param acceptedLegalDocuments object of accepted legal documents
     * @param accepted
     */
    public async setLegalDocumentsAccepted(acceptedLegalDocuments: any, accepted?: string[]) {
        const body: any = {
            legalDocuments: acceptedLegalDocuments,
        };

        if (accepted?.length) {
            for (const doc of Object.keys(body.legalDocuments)) {
                if (!accepted.includes(doc)) {
                    delete body.legalDocuments[doc];
                }
            }
        }

        await this.apiService.post('legalDocuments/accept', body);
    }

    /**
     * Will generate new version number based on current version
     *
     * @param currentVersion current version of a legal document ex. 1.1
     *
     * @returns new version as number with one decimal point
     */
    getNewVersionNumber(currentVersion: number) {
        return (currentVersion + 0.1).toFixed(1);
    }
}
