import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { ShowMoreComponentModule } from '../../molecules/show-more/show-more.module';
import { OriginalTextComponentModule } from '../../molecules/original-text/original-text.module';
import { FormsModule } from '@angular/forms';
import { TicketTextComponent } from './ticket-text.component';

@NgModule({
    imports: [ComponentsSharedModule, PipesModule, ShowMoreComponentModule, OriginalTextComponentModule, FormsModule],
    declarations: [TicketTextComponent],
    exports: [TicketTextComponent],
})
export class TicketTextComponentModule {}
