import { Injectable } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { DEFAULT_PROFILE_PICTURE } from '../../const/app.const';

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    readonly optionsCamera: CameraOptions = {
        quality: 50,
        destinationType: this.camera.DestinationType.DATA_URL,
        sourceType: this.camera.PictureSourceType.CAMERA,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        allowEdit: false,
        correctOrientation: true,
    };

    readonly optionsGallery: CameraOptions = {
        quality: 50,
        destinationType: this.camera.DestinationType.DATA_URL,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        allowEdit: false,
    };

    constructor(private camera: Camera, private webView: WebView) {}

    async getImageFromGallery() {
        if (window['cordova']) {
            const image = await this.camera.getPicture(this.optionsGallery);
            return `data:image/jpeg;base64,${image}`;
        } else {
            return null;
        }
    }

    async takePhoto() {
        try {
            if (window['cordova']) {
                const image = await this.camera.getPicture(this.optionsCamera);
                return `data:image/jpeg;base64,${image}`;
            } else {
                return null;
            }
        } catch (e) {
            console.log('error camera!', e);
            return null;
        }
    }
}
