import { Pipe, PipeTransform } from '@angular/core';
import { TicketNode } from '../../models/ticket-node';
import { CustomTranslationPipe } from '../custom-translation/custom-translation';
import { OPTIONS, STEP_TYPE } from '../../const/ticket.const';
import { TranslateService } from '@ngx-translate/core';
import { TicketGeneratorService } from '../../services/ticket-generator/ticket-generator.service';

@Pipe({
    name: 'userMessage',
})
export class UserMessagePipe implements PipeTransform {
    constructor(
        private customTranslation: CustomTranslationPipe,
        private translate: TranslateService,
        private ticketGenerator: TicketGeneratorService
    ) {}

    transform(textObj: string | TicketNode) {
        const constructedMessage = {
            type: STEP_TYPE.TEXT,
            wasSent: true,
            options: [],
            text: '',
        };
        if (typeof textObj === 'string') {
            constructedMessage.text = textObj;
        } else {
            const to = textObj as TicketNode;
            if (to.id === OPTIONS.DESCRIPTION_CONTINUE) {
                const description = this.ticketGenerator.getDescription();
                if (!description) {
                    constructedMessage.text = this.translate.instant('ticket-create.continue-without-description');
                } else {
                    // the description messages are already appended the the chatMessages
                    // in the chat-view component, therefore we don't need to re-add them
                    return null;
                }
            } else if (to.id === OPTIONS.CREATE_APPOINTMENT) {
                for (const app of this.ticketGenerator.getAppointments()) {
                    constructedMessage.text += `
                ${this.translate.instant('days.' + app.day)}: ${this.translate.instant('days.' + app.time)}<br>`;
                }
            } else if (to.id === OPTIONS.NO_APPOINTMENT) {
                constructedMessage.text = this.translate.instant('ticket-create.continue-without-appointments');
            } else if (to.id === STEP_TYPE.DUPLICATE) {
                return null;
            } else if (to.id === OPTIONS.PRESENT_MYSELF || to.id === OPTIONS.PRESENT_OTHER) {
                const contact = this.ticketGenerator.getContact();
                if (contact === OPTIONS.PRESENT_MYSELF) {
                    constructedMessage.text = this.translate.instant('ticket-create.present-myself');
                } else {
                    constructedMessage.text = this.buildCanEnterMessage(contact);
                }
            } else if (to.id === OPTIONS.EMAIL_CORRECT) {
                constructedMessage.text = to.text || this.translate.instant('ticket-create.correct');
            } else if (to.id === OPTIONS.PHONE_CORRECT) {
                constructedMessage.text = to.text || this.translate.instant('ticket-create.correct');
            } else if (to.id === OPTIONS.NO_CONTACT) {
                constructedMessage.text = this.translate.instant('ticket-create.no-contact-only-app');
            } else {
                constructedMessage.text = textObj.text;
            }
        }

        return constructedMessage;
    }

    private buildCanEnterMessage(contact): string {
        let message = '';
        if (contact.canEnter.includes('manager')) {
            message += this.translate.instant('other-person-info.response-manager');
        }
        if (contact.canEnter.includes('other')) {
            message += ` ${this.translate.instant('other-person-info.response-other', {
                name: contact.otherPerson.name,
                tel: contact.otherPerson.mobile,
            })}`;
        }
        return message;
    }
}
