<app-basic-card (expansion)="onExpand()" [header]="'ticket.appointments-service-provider-title'">
    <ion-row>
        <ion-col>
            <div class="service-provider">
                {{ offers?.name }}
            </div>
            <!-- Provider Contact Information -->
            <div>
                <span *ngIf="provider?.field">
                    {{ provider.field }}
                </span>
            </div>
            <div>
                <span *ngIf="provider?.street && provider?.city && provider?.zipcode">
                    {{ provider?.street }},
                    {{ provider?.city }}
                    {{ provider?.zipcode }}
                </span>
            </div>
            <div class="contact-details">
                <span *ngIf="provider?.email">
                    {{ 'service-providers.headers.email' | translate }}:
                    <a href="mailto:{{ provider?.email }}">{{ provider?.email }}</a>
                </span>
            </div>
            <div class="contact-details">
                <span *ngIf="provider?.phone">
                    {{ 'service-providers.headers.phone' | translate }}:
                    <a href="tel:{{ provider?.phone }}">{{ provider?.phone }}</a>
                </span>
            </div>
        </ion-col>
    </ion-row>
</app-basic-card>
