import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../../services/popup/popup.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
    selector: 'app-photo-viewer',
    templateUrl: './photo-viewer.component.html',
    styleUrls: ['./photo-viewer.component.scss'],
})
export class PhotoViewerComponent implements OnInit {
    @ViewChild(IonSlides) slides: IonSlides;
    @Input() images = [];
    @Input() index = 0;
    @Input() imageDeleteAllowed = true;

    leftIcon: string;

    slideOpts = {
        initialSlide: 0,
        centeredSlides: true,
        spaceBetween: 10,
        zoom: true,
    };

    constructor(
        public modalController: ModalController,
        private popupSerice: PopupService,
        private translate: TranslateService,
        private iab: InAppBrowser
    ) {}

    ngOnInit() {
        this.slideOpts.initialSlide = this.index;
        this.leftIcon = this.imageDeleteAllowed ? 'trash' : 'save-outline';
    }

    async leftFunction() {
        if (this.imageDeleteAllowed) {
            await this.deleteImage();
        } else {
            await this.download();
        }
    }

    async download() {
        const index = await this.slides.getActiveIndex();
        this.iab.create(this.images[index]?.file, '_system');
    }

    async deleteImage() {
        const result = await this.popupSerice.showConfirmation(
            this.translate.instant('ticket-pictures.confirm-delete.title'),
            this.translate.instant('ticket-pictures.confirm-delete.message')
        );

        if (result) {
            await this.modalController.dismiss(await this.slides.getActiveIndex());
        }
    }
}
