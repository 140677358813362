import { Component, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { IonSegment, IonSlides } from '@ionic/angular';

@Component({
    selector: 'app-segment-slider',
    templateUrl: './segment-slider.component.html',
    styleUrls: ['./segment-slider.component.scss'],
})
export class SegmentSliderComponent implements AfterViewInit {
    // Remove workaround with IONIC 5.1 production update: https://github.com/ionic-team/ionic/issues/20356
    public didInit = false;

    private readonly INITIAL_SLIDE = 0;

    @Input() headerData;
    @Input() customTheme: boolean;

    @Output() slideChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(IonSegment, { static: false }) segment: IonSegment;
    @ViewChild(IonSlides) slider: IonSlides;

    slideOpts = {
        initialSlide: this.INITIAL_SLIDE,
        speed: 300,
        threshold: 50,
    };

    @Input() set initialSlide(initSlide: number) {
        if (initSlide !== this.INITIAL_SLIDE) {
            this.slideOpts.initialSlide = initSlide;
            this.slideChanged.emit(this.slideOpts.initialSlide);
        }
    }

    ngAfterViewInit() {
        this.didInit = true;
        this.segment.value = String(this.INITIAL_SLIDE);
    }

    /**
     * Used by parent components to change slider value
     * @param index
     */
    async slideTo(index: number) {
        if (this.slider) {
            await this.slider.slideTo(index);
            await this.onSlideChange();
        }
    }

    async changeSliderValue(event: any) {
        await this.slider.slideTo(event.detail.value);
    }

    async onSlideChange() {
        const currentSlideIndex: number = await this.slider.getActiveIndex();
        this.segment.value = currentSlideIndex.toString();
        this.slideChanged.emit(currentSlideIndex);
    }
}
