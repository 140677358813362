import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'show-more',
    templateUrl: 'show-more.html',
})
export class ShowMoreComponent {
    isClosed = true;
    @Input() text = '';
    @Input() buttonColor = 'medium';
    @Input() limit = 150;

    @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();

    toggleText($event) {
        this.isClosed = !this.isClosed;
        if (this.isClosed) {
            this.closed.emit(true);
            this.opened.emit(false);
        } else {
            this.opened.emit(true);
            this.closed.emit(false);
        }
        $event.stopPropagation();
    }

    open() {
        this.isClosed = false;
        this.opened.emit(true);
        this.closed.emit(false);
    }

    close() {
        this.isClosed = true;
        this.closed.emit(true);
        this.opened.emit(false);
    }
}
