import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Ticket } from 'src/app/models/ticket';
import { LoadingController, ModalController } from '@ionic/angular';
import { TicketsService } from 'src/app/services/tickets/tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from 'src/app/services/popup/popup.service';
import { ApiService } from 'src/app/services/api/api.service';
import { TicketAppointment, TicketAppointmentStatus, TicketAppointmentType } from '../../../models/ticket-appointment';
import { UserService } from '../../../services/user/user.service';
import { OtherPersonInfoComponent } from '../../../modals/other-person-info/other-person-info.component';
import * as moment from 'moment';
import { DEFAULT_PROFILE_PICTURE } from '../../../const/app.const';

@Component({
    selector: 'app-ticket-appointments-card',
    templateUrl: './ticket-appointments-card.component.html',
    styleUrls: ['./ticket-appointments-card.component.scss'],
})
export class TicketAppointmentsCardComponent implements OnInit, OnDestroy {
    @Input() data: Ticket;
    @Input() open: boolean;

    public DEFAULT_PROFILE_PICTURE = DEFAULT_PROFILE_PICTURE;
    private subscription;
    public appointments = [];
    public appointment;
    public loading = false;
    public reasonTitle;

    expanded;

    TicketAppointmentStatus = TicketAppointmentStatus;
    TicketAppointmentType = TicketAppointmentType;
    Object = Object;

    constructor(
        private modalController: ModalController,
        private ticketsService: TicketsService,
        private translate: TranslateService,
        private popupService: PopupService,
        private loadingController: LoadingController,
        private api: ApiService,
        private userService: UserService
    ) {}

    async ngOnInit() {
        if (this.open) {
            this.onExpansion(true);
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    async onExpansion(expanded) {
        this.expanded = expanded;
        if (expanded && !this.subscription) {
            this.loading = true;
            this.subscription = this.ticketsService
                .getTicketAppointmentsObservable(this.data.id)
                .subscribe((appointments: TicketAppointment[]) => {
                    for (const appointment of appointments) {
                        appointment.createdOn = appointment.createdOn.toDate();
                        appointment.updatedOn = appointment.updatedOn.toDate();
                        for (const option of appointment.recommendations) {
                            option.start = option.start.toDate();
                            option.end = option.end.toDate();
                        }
                        for (const option of appointment.accepted) {
                            option.start = option.start.toDate();
                            option.end = option.end.toDate();
                        }

                        if (appointment.accepted.length) {
                            appointment.hasStarted = moment(appointment.accepted[0].start).isBefore(moment());
                        }
                    }
                    this.appointments = appointments.sort((a, b) => {
                        if (
                            !(
                                a.status === TicketAppointmentStatus.CANCELED ||
                                a.status === TicketAppointmentStatus.ENDED
                            ) &&
                            (b.status === TicketAppointmentStatus.CANCELED ||
                                b.status === TicketAppointmentStatus.ENDED)
                        ) {
                            return -1;
                        } else if (
                            (a.status === TicketAppointmentStatus.CANCELED ||
                                a.status === TicketAppointmentStatus.ENDED) &&
                            !(
                                b.status === TicketAppointmentStatus.CANCELED ||
                                b.status === TicketAppointmentStatus.ENDED
                            )
                        ) {
                            return 1;
                        } else if (a.createdOn.getTime() < b.createdOn.getTime()) {
                            return 1;
                        } else if (a.createdOn.getTime() > b.createdOn.getTime()) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                    this.appointments = appointments;
                    this.loading = false;
                });
        }
    }

    async acceptRecommendation(id: string, index: number) {
        const appointment = this.appointments.find((app) => app.id === id);
        this.popupService.showAlert(
            this.translate.instant('ticket-appointments.accept-title'),
            this.translate.instant('ticket-appointments.accept-info'),
            [
                this.translate.instant('general.cancel'),
                {
                    text: this.translate.instant('general.accept'),
                    handler: async () => {
                        const loading = await this.loadingController.create();
                        await loading.present();
                        appointment.accepted = [appointment.recommendations[index]];
                        appointment.status = 'accepted';
                        await this.api.put('appointments', appointment);
                        await loading.dismiss();
                    },
                },
            ]
        );
    }

    async laterAppointments(id: string) {
        const appointment = this.appointments.find((app) => app.id === id);
        this.popupService.showAlert(
            this.translate.instant('ticket-appointments.decline-title'),
            this.translate.instant('ticket-appointments.decline-info'),
            [
                this.translate.instant('general.cancel'),
                {
                    text: this.translate.instant('general.decline'),
                    handler: async () => {
                        const loading = await this.loadingController.create();
                        await loading.present();
                        appointment.status = 'declined';
                        await this.api.put('appointments', appointment);
                        await loading.dismiss();
                    },
                },
            ]
        );
    }

    async cancelAppointment(id: string) {
        const appointment = this.appointments.find((app) => app.id === id);
        this.popupService.showInputAlert(
            this.translate.instant('ticket-appointments.cancel-title'),
            this.translate.instant('ticket-appointments.cancel-info'),
            [
                this.translate.instant('general.cancel'),
                {
                    text: this.translate.instant('ticket-appointments.cancel'),
                    handler: async (input) => {
                        const loading = await this.loadingController.create();
                        await loading.present();
                        appointment.status = TicketAppointmentStatus.CANCELED;
                        if (input?.reason) {
                            await this.api.put(`appointments`, {
                                ...appointment,
                                reason: {
                                    value: input.reason,
                                    lang: this.userService.userLanguage$.getValue(),
                                },
                            });
                        } else {
                            await this.api.put(`appointments`, appointment);
                        }
                        await loading.dismiss();
                    },
                },
            ],
            [
                {
                    name: 'reason',
                    type: 'text',
                    placeholder: this.translate.instant('ticket-appointments.cancel-reason'),
                },
            ]
        );
    }

    async onEditPresentPerson(appointmentId: string) {
        const appointment = this.appointments.find((appointment) => appointment.id === appointmentId);

        const modal = await this.modalController.create({
            component: OtherPersonInfoComponent,
            componentProps: {
                presentPerson: appointment?.presentPerson || null,
            },
        });

        await modal.present();
        const result = await modal.onDidDismiss();

        if (result.role === 'submit' && result.data) {
            try {
                await this.api.put(`appointments`, {
                    ...appointment,
                    presentPerson: result.data.presentPerson,
                });
            } catch (err) {
                this.popupService.showToast(this.translate.instant('ticket-appointments.representation.error'), true);
                console.error(err);
            }
        }
    }

    appointmentLastAuthorKey(appointment) {
        if (appointment?.updatedBy?.model === 'manager') {
            return 'ticket-appointments.manager';
        } else if (appointment?.updatedBy?.model === 'user') {
            return 'ticket-appointments.tenant';
        } else if (appointment?.updatedBy?.model === 'provider') {
            return 'ticket-appointments.provider';
        }
        return null;
    }
}
