import { NgModule } from '@angular/core';
import { VoteDelegationModalComponent } from './vote-delegation-modal.component';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsSharedModule } from '../../components/components.shared.module';
import { ShowAttachmentsComponentModule } from '../../components/molecules/show-attachments/show-attachments.component.module';
import { VoteRepresentationAuthorityComponentModule } from '../vote-representation-authority/vote-representation-authority.component.module';
import { BasicCardComponentModule } from '../../components/molecules/basic-card/basic-card.component.module';
import { ChecklistItemComponentModule } from '../../components/molecules/checklist-item/checklist-item.component.module';
import { SimpleCardComponentModule } from '../../components/molecules/simple-card/simple-card.component.module';
import { UserListItemComponentModule } from '../../components/molecules/user-list-item/user-list-item.component.module';
import { AttachmentsAddComponentModule } from 'src/app/components/molecules/attachments-add/attachments-add.component.module';

@NgModule({
    declarations: [VoteDelegationModalComponent],
    exports: [VoteDelegationModalComponent],
    imports: [
        TopbarComponentModule,
        VoteRepresentationAuthorityComponentModule,
        TranslateModule,
        ComponentsSharedModule,
        ShowAttachmentsComponentModule,
        ChecklistItemComponentModule,
        BasicCardComponentModule,
        SimpleCardComponentModule,
        UserListItemComponentModule,
        AttachmentsAddComponentModule,
    ],
})
export class VoteDelegationModalComponentModule {}
