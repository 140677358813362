import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { Flat } from '../../models/flat';
import { UserService } from '../user/user.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { BaseDataService } from '../../util/base-data-service';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class FlatService implements BaseDataService {
    private sub: Subscription = new Subscription();
    flats$: BehaviorSubject<Flat[]> = new BehaviorSubject<Flat[]>([]);

    constructor(private userService: UserService, private db: AngularFirestore, private apiService: ApiService) {}

    initialize() {
        const user = this.userService.user;
        const observables = [];

        for (const flatId of user.activeFlats) {
            observables.push(this.getObservableForFlat(user.ns, flatId));
        }

        this.sub = combineLatest(observables).subscribe((flats: Flat[]) => {
            this.flats$.next(flats.filter((flat) => flat));
        });
    }

    private getObservableForFlat(ns, flatId): Observable<Flat> {
        return this.db
            .doc(`ns/${ns}/flats/${flatId}`)
            .snapshotChanges()
            .pipe(
                map((flatDocSnap) => {
                    return flatDocSnap.payload.data() as Flat;
                })
            );
    }

    getFlat(flatId: string) {
        return this.flats$ && this.flats$.getValue() ? this.flats$.getValue().find((flat) => flat.id === flatId) : null;
    }

    async getFlatsByIds(ids: string[]) {
        return (await this.apiService.get(`flats?id=${ids.join(',')}`)) as any[];
    }

    isOwnerOfFlatById(flatId: string): boolean {
        return this.userService.user.ownedFlats.some((id) => id === flatId);
    }

    terminate() {
        this.flats$.next(null);
        this.sub.unsubscribe();
    }
}
