import { DocumentsRequests } from '../../util/documents-requests';
import { DocumentUtilService } from '../document-util/document-util.service';
import { ApiService } from '../api/api.service';
import { SearchEntity } from '../../models/search-entity.enum';
import { ElasticService } from '../elastic/elastic.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseClass } from '../../util/base-class';
import { BlackboardMessage } from '../../models/blackboard-message';
import { ProfileService } from '../profile/profile.service';
import { NamespaceService } from '../namespace/namespace.service';
import { BaseDataService } from '../../util/base-data-service';
import { User } from '../../models/user';
import { convertFirestoreDate, getIndexAndType } from 'src/app/util/util';

@Injectable({
    providedIn: 'root',
})
export class BlackboardService extends BaseClass implements BaseDataService {
    private readMessagesSub: Subscription = new Subscription();
    private messagesSub: Subscription = new Subscription();

    messages$: BehaviorSubject<BlackboardMessage[]> = new BehaviorSubject<BlackboardMessage[]>(null);
    newMessageCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    lastMessageDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

    private lastReadMessageIds: string[] = [];
    private currentMessageIds: string[] = [];

    private globalBlackboardMessagesListener: Subscription = new Subscription();

    constructor(
        private db: AngularFirestore,
        private userService: UserService,
        private profileService: ProfileService,
        private namespaceService: NamespaceService,
        private elasticService: ElasticService,
        private apiService: ApiService,
        private documentUtilService: DocumentUtilService,
        private documentsRequests: DocumentsRequests
    ) {
        super('BlackboardService');
    }

    async initialize() {
        const user: User = this.userService.user;

        if (!user || !user.activeFlats || !user.activeFlats.length) {
            this.logger.error('Invalid User when initializing BlackboardService:', user);
            return null;
        }

        // Listen for changes in blackboard collection (new documents, updated documents and removed documents)
        this.globalBlackboardMessagesListener = this.db
            .collection(this.getCollectionPath(), (ref) => ref.limit(1).orderBy('createdOn', 'desc'))
            .snapshotChanges()
            .subscribe(async () => {
                const blackboardMessagesResult: any = await new Promise((resolve) =>
                    setTimeout(() => {
                        resolve(
                            this.elasticService.searchBlackboard(
                                '',
                                250,
                                0,
                                {
                                    createdOn: 'desc',
                                },
                                {
                                    'uids.keyword': [user.id],
                                    'flatIds.keyword': user.activeFlats,
                                    'propertyIds.keyword': user.properties,
                                    'interests.keyword': user.interests,
                                    'type.keyword': 'BROADCAST',
                                },
                                { showOnBlackboard: false, active: false },
                                [],
                                true,
                                true
                            )
                        );
                    }, 500)
                );

                if (blackboardMessagesResult?.total) {
                    const blackboardMessageIds: string[] = blackboardMessagesResult.source
                        .filter((message: any) => {
                            return (
                                Boolean(message.showOnBlackboard && !message.excludedRecipients?.includes(user.id)) && // Do not show blackboard message if current user is in the excludedRecipients list
                                Boolean(
                                    message.propertyIds.some((propertyId: string) =>
                                        user.properties.includes(propertyId)
                                    ) ||
                                        message.flatIds.some((flatId: string) => user.activeFlats.includes(flatId)) ||
                                        message.uids.some((uid: string) => uid === user.id) ||
                                        message.type === 'BROADCAST'
                                ) &&
                                Boolean(
                                    !message.interests?.length ||
                                        message.interests.some((interestId: string) =>
                                            user.interests.includes(interestId)
                                        )
                                ) &&
                                Boolean(!message.userType || message.userType === user.type)
                            );
                        })
                        .map((message: any) => message.id);

                    if (!blackboardMessageIds.length) {
                        this.messages$.next([]);
                    }

                    const messagesObservables: any[] = blackboardMessageIds.map((id: string) =>
                        this.db.doc(`${this.getCollectionPath()}/${id}`).valueChanges()
                    );

                    this.messagesSub = combineLatest(messagesObservables)
                        .pipe(map((latestValues: any) => latestValues.flat(1)))
                        .pipe(
                            map((messages: any[]) => {
                                for (const message of messages) {
                                    if (message) {
                                        convertFirestoreDate(message);
                                    }
                                }

                                return messages.filter(
                                    (message) =>
                                        message &&
                                        message.validFrom.getTime() <= Date.now() &&
                                        message.validTo.getTime() >= Date.now()
                                );
                            })
                        )

                        .subscribe((messages: BlackboardMessage[]) => {
                            if (messages && messages[0]) {
                                this.lastMessageDate$.next(messages[0].validFrom);
                            } else {
                                this.lastMessageDate$.next(null);
                            }

                            this.currentMessageIds = messages.map((message) => message.id);

                            this.updateNewMessageCount();

                            const ns = this.namespaceService.getName();

                            for (const message of messages) {
                                message.namespace = ns;
                            }

                            this.messages$.next(messages);
                        });
                } else {
                    // Elasticsearch found no blackboard messages
                    this.messages$.next([]);
                }
            });

        this.readMessagesSub = this.db
            .doc(`ns/${user.ns}/readMessages/${this.userService.user.id}`)
            .valueChanges()
            .subscribe((value: { messageIds }) => {
                if (value && value.messageIds) {
                    this.lastReadMessageIds = value.messageIds;
                } else {
                    this.lastReadMessageIds = [];
                }
                this.updateNewMessageCount();
            });
    }

    public async saveBlackboardMessage(
        recipientIds: string[],
        message: {
            id?: string;
            title: string;
            description: string;
            validFrom: Date;
            validTo: Date;
            type: SearchEntity;
        },
        documents: any
    ): Promise<any> {
        if (this.userService.user && recipientIds?.length) {
            const messageDto: any = {
                type: message.type,
                title: {
                    original: {
                        value: message.title,
                        language: this.userService.userLanguage$.value,
                    },
                },
                text: {
                    original: {
                        value: message.description,
                        language: this.userService.userLanguage$.value,
                    },
                },
                validFrom: message.validFrom,
                validTo: message.validTo,
                showOnBlackboard: true,
                //showOnBlackboard: Boolean(message.id) || (!documents.imgs.length && !documents.pdfs.length),
                channels: {
                    useEmails: true,
                    useApps: true,
                },
            };

            switch (message.type) {
                case SearchEntity.PROPERTY:
                    messageDto.propertyIds = recipientIds;
                    break;
                case SearchEntity.FLAT:
                    messageDto.flatIds = recipientIds;
                    break;
            }

            let blackboardId = message.id;
            if (blackboardId) {
                const updatedBulletinEntry = await this.updateBulletinEntry(blackboardId, messageDto);

                await this.renameFiles(blackboardId, documents);

                await this.documentUtilService.deleteFilesFromObject(
                    blackboardId,
                    'blackboard',
                    updatedBulletinEntry.documents,
                    documents
                );
            } else {
                documents = await this.documentUtilService.prepareDocuments(documents);
                const blackboardModel = await this.createBulletinEntry(
                    {
                        ...messageDto,
                        creator: {
                            id: this.userService.user.id,
                            name: this.userService.getUserFullName(),
                            profilePicture: this.userService.user.profilePicture || null,
                            type: 'user',
                        },
                    },
                    [...documents.imgs, ...documents.pdfs]
                );
                blackboardId = blackboardModel.id;
            }

            if (message.id && (documents.imgs.length || documents.pdfs.length)) {
                documents = await this.documentUtilService.prepareDocuments(documents);
                await this.documentsRequests.upload('blackboard', blackboardId, [...documents.imgs, ...documents.pdfs]);
            }
            return { ...message, blackboardId };
        }
    }

    private async createBulletinEntry(message: any, files: any): Promise<any> {
        const formData = new FormData();
        formData.append('model', JSON.stringify(message));

        if (files.length) {
            for (const file of files) {
                const name = file.editedName && file.editedName.length ? file.editedName : file.name;
                formData.append(name, file.file, name);
            }
        }

        return await this.apiService.post('blackboard', formData);
    }

    private async updateBulletinEntry(blackboardId: string, message: any): Promise<any> {
        return await this.apiService.put('blackboard', {
            ...message,
            id: blackboardId,
        });
    }

    async deleteMessage(id: string) {
        return await this.apiService.delete(`blackboard/${id}`);
    }

    /**
     * Saves the current ids of all valid (and read) messages if they have changed
     */
    async saveCurrentlyReadMessageIds() {
        // Check if lastreadMessages and currentMessages are different.
        // If so, save the new array of readMessageIds for the user.
        let changed = false;
        if (this.currentMessageIds.length !== this.lastReadMessageIds.length) {
            changed = true;
        } else {
            for (let x = 0, l = this.currentMessageIds.length; x < l; x++) {
                if (this.lastReadMessageIds.indexOf(this.currentMessageIds[x]) === -1) {
                    changed = true;
                    break;
                }
            }
        }
        if (changed && this.userService.user) {
            try {
                await this.db
                    .doc(`ns/${this.userService.getNamespace()}/readMessages/${this.userService.user.id}`)
                    .set({
                        messageIds: this.currentMessageIds,
                    });
            } catch (error) {
                console.error(error);
            }
        }
    }

    async renameFiles(blackboardId: string, documents: any) {
        const filesToRename = [
            ...documents.imgs.filter((img) => img.isAlreadyUploaded && img.editedName),
            ...documents.pdfs.filter((pdf) => pdf.isAlreadyUploaded && pdf.editedName),
        ];

        if (filesToRename.length) {
            for (const file of filesToRename) {
                const fileIndexing = getIndexAndType(file, documents);

                if (fileIndexing.index >= 0) {
                    // eslint-disable-next-line no-await-in-loop
                    await this.documentsRequests.rename(
                        'blackboard',
                        blackboardId,
                        fileIndexing.type,
                        fileIndexing.index,
                        file.editedName
                    );
                }
            }
        }
    }

    public async countBlackboardComments(blackboardId: string) {
        return this.elasticService.searchBlackboardComments({
            size: 0,
            filter: {
                'blackboardId.keyword': blackboardId,
            },
        });
    }

    observeBlackboardComments(blackboardId: string, withTexts = false) {
        return this.db
            .collection(`ns/${this.userService.getNamespace()}/blackboardComments`, (ref) =>
                ref.where('blackboardId', '==', blackboardId)
            )
            .valueChanges()
            .pipe(
                switchMap((comments: any) => {
                    if (!comments?.length) {
                        return of([]);
                    }

                    convertFirestoreDate(comments);
                    comments.sort((a, b) => {
                        a = new Date(a.createdOn);
                        b = new Date(b.createdOn);
                        return b - a;
                    });

                    if (withTexts) {
                        return combineLatest(
                            comments.map((comment) => {
                                return this.observeCommentTexts(blackboardId, null, comment.id).pipe(
                                    map((texts: any[]) => {
                                        for (const text of texts) {
                                            this.applyTextToComment(comment, text);
                                        }
                                        return comment;
                                    })
                                );
                            })
                        );
                    } else {
                        return comments;
                    }
                })
            );
    }

    public getBlackboardCommentTemplate(blackboardId: string, text: string) {
        return {
            blackboardId,
            text,
            createdOn: new Date(),
            creator: {
                type: 'user',
                id: this.userService.user.id,
                name: this.userService.getUserFullName(this.userService.user),
            },
        };
    }

    public async createBlackboardComment(dto: any, documents: any) {
        const comment = await this.apiService.post(`blackboard/${dto.blackboardId}/comments`, dto);
        if (documents) {
            documents = await this.documentUtilService.prepareDocuments(documents);
            if (documents.imgs.length || documents.pdfs.length) {
                await this.documentsRequests.upload(`blackboard/${comment.blackboardId}/comments`, comment.id, [
                    ...documents.imgs,
                    ...documents.pdfs,
                ]);
            }
        }
    }

    public async updateBlackboardComment(dto: any, documents: any) {
        const comment = await this.apiService.put(`blackboard/${dto.blackboardId}/comments`, dto);
        if (documents) {
            documents = await this.documentUtilService.prepareDocuments(documents);
            if (documents.imgs.length || documents.pdfs.length) {
                await this.documentsRequests.upload(`blackboard/${comment.blackboardId}/comments`, comment.id, [
                    ...documents.imgs,
                    ...documents.pdfs,
                ]);
            }
        }
    }

    public async deleteBlackboardComment(blackboardId: string, commentId: string) {
        await this.apiService.delete(`blackboard/${blackboardId}/comments/${commentId}`);
    }

    subscribeBlackboardCommentsCount(blackboardId: string, callback: any) {
        return this.db
            .collection(`ns/${this.userService.getNamespace()}/blackboardComments`, (ref) =>
                ref.where('blackboardId', '==', blackboardId).orderBy('createdOn', 'desc').limit(10)
            )
            .valueChanges()
            .subscribe(callback);
    }

    public async hasUnreadBlackboardComments(blackboardId: string) {
        const result = await this.elasticService.searchBlackboardComments({
            size: 0,
            filter: {
                'blackboardId.keyword': blackboardId,
            },
            excludes: {
                'readBy.keyword': this.userService.user.id,
            },
        });

        return !!result.total;
    }

    observeCommentTexts(blackboardId: string, type: string, commentId: string) {
        return this.db
            .collection(`ns/${this.userService.getNamespace()}/ticketTexts`, (ref) =>
                ref.where('key', '==', `blackboard.${blackboardId}.comment.${commentId}`)
            )
            .valueChanges();
    }

    private applyTextToComment(comment: any, text: any) {
        if (!comment) {
            comment = {};
        }

        if (!comment.text || typeof comment.text !== 'object') {
            comment.text = {};
        }

        if (text.type === 'original') {
            comment.text.original = {
                language: text.lang,
                value: text.value,
            };
            comment.text[text.lang] = text.value;
        } else {
            if (!comment.text[text.lang]) {
                comment.text[text.lang] = text.value;
            }
        }

        return comment;
    }

    /**
     * Counts the difference between new messages and the last read messageIds
     */
    private updateNewMessageCount() {
        let count = 0;
        for (const id of this.currentMessageIds) {
            if (this.lastReadMessageIds.indexOf(id) === -1) {
                ++count;
            }
        }
        this.newMessageCount$.next(count);
    }

    private getCollectionPath() {
        return `ns/${this.userService.getNamespace()}/blackboard`;
    }

    terminate() {
        this.messages$.next([]);
        this.newMessageCount$.next(0);
        this.lastMessageDate$.next(null);

        const subscriptions = [this.messagesSub, this.readMessagesSub, this.globalBlackboardMessagesListener];

        for (const subscription of subscriptions) {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
    }
}
