import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { AttachmentsAddComponent } from './attachments-add.component';
import { PhotoViewComponentModule } from '../../../modals/photo-viewer/photo-viewer.component.module';
import { DesktopFilepickerService } from '../../../services/desktop-filepicker/desktop-filepicker.service';

@NgModule({
    imports: [ComponentsSharedModule, PhotoViewComponentModule],
    declarations: [AttachmentsAddComponent],
    providers: [DesktopFilepickerService],
    exports: [AttachmentsAddComponent],
})
export class AttachmentsAddComponentModule {}
