<ion-list lines="full">
    <ion-item *ngFor="let item of items" (click)="itemClicked(item.id)">
        <ion-label *ngIf="!disableRowTitle" class="item-key" [color]="item.selected ? 'primary' : ''">
            {{ item.text | translate }}
        </ion-label>
        <ion-icon
            color="primary"
            name="checkmark"
            *ngIf="item.selected && (!item.chips || !item.chips.length)"
            slot="end"></ion-icon>
        <ion-label
            class="chips"
            [class.flexChips]="disableRowTitle"
            [slot]="disableRowTitle ? '' : 'end'"
            *ngIf="item.chips?.length">
            <ion-chip
                *ngFor="let chip of item.chips"
                [hidden]="chip?.hide"
                [outline]="!chip.selected"
                [class.chipActive]="chip.selected"
                [class.chipInactive]="!chip.selected"
                (click)="chipSelected(item.id, chip['id'], $event)">
                <ion-label>
                    <ion-icon *ngIf="chip['icon']" [name]="chip['icon']"></ion-icon>{{ chip['text'] | translate }}
                </ion-label>
                <ion-icon *ngIf="canClose" color="primary" name="close"></ion-icon>
            </ion-chip>
        </ion-label>
    </ion-item>
</ion-list>
