import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseDataService } from '../../util/base-data-service';
import { BaseClass } from '../../util/base-class';

@Injectable({
    providedIn: 'root',
})
export class CloudMessagingService extends BaseClass implements BaseDataService {
    messages$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private subscriptions: Subscription[] = [];

    constructor(
        public firebaseNative: FirebaseX,
        public afs: AngularFirestore,
        private platform: Platform,
        private api: ApiService
    ) {
        super('CloudMessagingService');
    }

    async getToken() {
        let token;

        if (this.platform.is('cordova')) {
            token = await this.firebaseNative.getToken();

            if (this.platform.is('ios') && !(await this.firebaseNative.hasPermission())) {
                await this.firebaseNative.grantPermission().catch((err) => {
                    this.logger.error('Push permission denied');
                    return Promise.reject();
                });
            }

            return this.saveTokenToFirestore(token);
        }
    }

    monitorTokenRefresh() {
        if (this.platform.is('cordova')) {
            return this.firebaseNative.onTokenRefresh().pipe(tap((token) => this.saveTokenToFirestore(token)));
        } else {
            // PWA implementation
            return of(null);
        }
    }

    listenToNotifications() {
        if (this.platform.is('cordova')) {
            return this.firebaseNative.onMessageReceived();
        } else {
            // PWA implementation
            return of(null);
        }
    }

    async subscribeTo(topic: string, uid: string) {
        if (this.platform.is('cordova')) {
            // this subscribe is NOT an observable
            // returns promise
            await this.firebaseNative.subscribe(topic);
        }

        const topics = { [topic]: true };
        return this.afs.collection('users').doc(uid).set({ topics }, { merge: true });
    }

    async unsubscribeFrom(topic: string, uid: string) {
        if (this.platform.is('cordova')) {
            // unsubscribe
            await this.firebaseNative.unsubscribe(topic);
        }

        const topics = { [topic]: false };
        return this.afs.collection('users').doc(uid).set({ topics }, { merge: true });
    }

    private async saveTokenToFirestore(token, web = false) {
        if (!token) {
            return;
        }
        return this.api.post('devices', {
            token,
            web,
            app: 'tenant',
            platform: this.platform.platforms().join(','),
        });
    }

    private async removeTokenFromFirestore() {
        if (this.platform.is('cordova')) {
            const token = await this.firebaseNative.getToken();
            if (token) {
                return this.api.delete(`devices/${token}`);
            }
        }
    }

    async initialize() {
        if (this.platform.is('cordova')) {
            try {
                this.clearSubscriptions();
                await this.getToken();

                // update token on refresh
                this.subscriptions.push(this.monitorTokenRefresh().subscribe());

                // listen to incoming messages
                this.subscriptions.push(
                    this.listenToNotifications()
                        .pipe(
                            tap((msg) => {
                                // show a toast
                                if (msg && msg.body) {
                                    this.messages$.next(msg.body);
                                }
                            })
                        )
                        .subscribe()
                );
            } catch (err) {
                return Promise.resolve();
            }
        }
    }

    terminate() {
        this.clearSubscriptions();
        this.removeTokenFromFirestore();
    }

    private clearSubscriptions() {
        if (this.subscriptions.length) {
            for (const sub of this.subscriptions) {
                sub.unsubscribe();
            }
            this.subscriptions = [];
        }
    }
}
