import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Document } from '../../models/document';
import { environment } from '../../../environments/environment';
import { BaseClass } from '../../util/base-class';
import { DocumentsRequests } from '../../util/documents-requests';
import { PdfComponent } from '../../modals/pdf-viewer/pdf-viewer.component';
import { PhotoViewerComponent } from '../../modals/photo-viewer/photo-viewer.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

const b64Prefix = 'data:image/jpeg;base64,';
// const b64PngPrefix = 'data:image/png;base64,';
// const b64JpgPrefix = 'data:image/jpg;base64,';
// const b64PdfPrefix = 'data:application/pdf;base64,';

@Injectable({
    providedIn: 'root',
})
export class DocumentUtilService extends BaseClass {
    imgs: string[] = [];

    constructor(
        private camera: Camera,
        private storage: AngularFireStorage,
        private platform: Platform,
        private documentsRequests: DocumentsRequests,
        private modalController: ModalController,
        private iab: InAppBrowser,
        private loadingController: LoadingController
    ) {
        super('DocumentUtilService');
    }

    getImgFromDevice(sourceType: number): Promise<Document> {
        return new Promise(async (resolve) => {
            const options: CameraOptions = {
                quality: 50,
                destinationType: this.camera.DestinationType.DATA_URL,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                sourceType,
            };
            let img = await this.camera.getPicture(options);
            img = b64Prefix + img;

            const doc: Document = {
                name: `${new Date().getTime().toString()}.jpeg`,
                file: img,
                mimetype: 'image/jpeg',
            };
            resolve(doc);
        });
    }

    getCroppedImg(targetWidth: number, targetHeight: number, sourceType: number): Promise<any> {
        const options = {
            allowEdit: false,
            quality: 50,
            targetWidth,
            targetHeight,
            sourceType,
            destinationType: 0,
            correctOrientation: true,
        };
        return this.camera.getPicture(options);
    }

    documentUpload(doc, path, type) {
        return new Promise(async (resolve, reject) => {
            const blob = typeof doc == 'string' ? await (await fetch(`data:image/png;base64,${doc}`)).blob() : doc;
            const fileRef = this.storage.ref(path);
            const task = fileRef.put(blob);

            task.snapshotChanges()
                .pipe(
                    finalize(() => {
                        fileRef.getDownloadURL().subscribe((url) => {
                            resolve(url);
                        });
                    })
                )
                .subscribe();
            task.catch((err) => {
                reject(err);
            });
        });
    }

    async openDocument(document, dismissLoader = false): Promise<any> {
        if (document.mimetype.startsWith('image') && this.platform.is('cordova')) {
            const modal = await this.modalController.create({
                component: PhotoViewerComponent,
                componentProps: {
                    images: [document],
                    index: 0,
                    imageDeleteAllowed: false,
                },
            });
            await modal.present();
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }
            return null;
        } else if (document.mimetype.startsWith('application/pdf')) {
            // loader gets dismissed in PdfComponent
            const modal = await this.modalController.create({
                component: PdfComponent,
                componentProps: {
                    document,
                },
                cssClass: 'fullscreen',
            });
            await modal.present();
            return null;
        } else if (document.file.startsWith('data:')) {
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }

            const pdfWindow = window.open('');
            pdfWindow.document.write(`<iframe width="100%" height="100%" src="${encodeURI(document.file)}"></iframe>`);

            return null;
        } else if (
            document.file.startsWith('https') &&
            document.file.indexOf(environment.firebase.storageBucket) !== -1
        ) {
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }
            window.open(document.file);
            return null;
        } else {
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }
            this.logger.error('Unknown Document', document.file);
            return null;
        }
    }
    openInBrowser(url) {
        this.iab.create(url, '_system');
    }

    readFile(file, name, type): Promise<Document> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                const doc: Document = {
                    name,
                    file: reader.result.toString(),
                    mimetype: type,
                };
                resolve(doc);
            };
            reader.onerror = () => {
                reject('Error at Reading File.');
            };
        });
    }

    async prepareDocuments(documents) {
        const imgs = [];
        const pdfs = [];

        for (const img of documents.imgs) {
            if (!img.isAlreadyUploaded) {
                imgs.push(this.downloadCheckIfFileOrReturnBlob(img));
            }
        }
        for (const pdf of documents.pdfs) {
            if (!pdf.isAlreadyUploaded) {
                pdfs.push(this.downloadCheckIfFileOrReturnBlob(pdf));
            }
        }

        return { imgs: await Promise.all(imgs), pdfs: await Promise.all(pdfs) };
    }

    async downloadCheckIfFileOrReturnBlob(file) {
        if (file && typeof file.file === 'string') {
            const blob = await (
                await fetch(file.file, {
                    method: 'GET',
                })
            ).blob();

            return { name: file.name, file: blob };
        }
        return file;
    }

    async deleteFilesFromObject(objectId: string, objectKey: string, oldDocuments: any, newDocuments: any) {
        if ((oldDocuments?.pdfs || oldDocuments?.pdfs) && (newDocuments?.pdfs || newDocuments?.imgs)) {
            const fileToDelete = this.getDeletedFile(oldDocuments, newDocuments);

            if (fileToDelete) {
                const type = fileToDelete.mimetype.includes('pdf') ? 'pdfs' : 'imgs';
                for (let i = 0; i < oldDocuments[type].length; i++) {
                    if (oldDocuments[type][i].name === fileToDelete.name) {
                        // eslint-disable-next-line no-await-in-loop
                        const result: any = await this.documentsRequests.delete(objectKey, objectId, type, i);

                        // eslint-disable-next-line no-await-in-loop
                        await this.deleteFilesFromObject(objectId, objectKey, result.documents, newDocuments);
                    }
                }
            }
        }
    }

    private getDeletedFile(oldDocuments: any, newDocuments: any) {
        const mergedTypesOld = [...oldDocuments.pdfs, ...oldDocuments.imgs];
        const mergedTypesNew = [...newDocuments.pdfs, ...newDocuments.imgs];

        const deletedFiles = mergedTypesOld.filter(
            (oldDoc) =>
                !mergedTypesNew.find((newDoc) => newDoc.name === oldDoc.name && newDoc.mimetype === oldDoc.mimetype)
        );
        return deletedFiles[0];
    }

    /**
     * Dismiss loader triggered from documents-detail
     *
     * @private
     */
    private async dismissLoaderFromDocumentsDetail() {
        try {
            await this.loadingController.dismiss(null, null, 'document-loading');
        } catch {
            // loader not present
        }
    }
}
