import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { RecordingComponent } from './recording.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [RecordingComponent],
    exports: [RecordingComponent],
})
export class RecordingComponentModule {}
