import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filePreview',
})
export class FilePreviewPipe implements PipeTransform {
    transform(value: any) {
        return new Promise((resolve) => {
            if (value && typeof value === 'object') {
                const fileReader = new FileReader();
                fileReader.onload = (event: any) => {
                    resolve(event.target.result);
                };
                fileReader.readAsDataURL(value);
            } else {
                resolve(value);
            }
        });
    }
}
