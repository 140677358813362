import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { SimpleCardComponent } from './simple-card.component';

@NgModule({
    imports: [ComponentsSharedModule, PipesModule],
    declarations: [SimpleCardComponent],
    exports: [SimpleCardComponent],
})
export class SimpleCardComponentModule {}
