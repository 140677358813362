import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { ElasticService } from '../elastic/elastic.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private notificationsCollection = `ns/${this.userService.getNamespace()}/notifications`;
    public currentEvoteNotifications = [];
    public currentDocumentNotifications = [];
    public evoteNotificationCount = 0;
    public documentNotificationCount = 0;

    constructor(
        private firestore: AngularFirestore,
        private userService: UserService,
        private api: ApiService,
        private elastic: ElasticService
    ) {}

    observeLatestNotifications(count = 10) {
        return this.firestore
            .collection(this.notificationsCollection, (ref) =>
                ref.where('uid', '==', this.userService.user.id).orderBy('createdOn', 'desc').limit(count)
            )
            .snapshotChanges();
    }

    public observeNotificationById(id: string) {
        return this.firestore.collection(this.notificationsCollection).doc(id).valueChanges();
    }

    observeUnreadNotifications(count = 10) {
        if (!this.userService.user?.id) {
            return new Observable<any>(null);
        }

        return this.firestore
            .collection(this.notificationsCollection, (ref) =>
                ref.where('uid', '==', this.userService.user.id).where('read', '==', false).limit(count)
            )
            .valueChanges();
    }

    async removeNotifications(ids: string[]) {
        return this.api.post('notifications/bulk/delete', {
            notifications: ids,
        });
    }

    async removeAllNotifications() {
        return this.api.post('notifications/deleteAll');
    }

    async toggleRead(ids: string[], read: boolean) {
        return this.api.post('notifications/bulk/update', {
            notifications: ids.map((id) => {
                return {
                    id,
                    read,
                };
            }),
        });
    }

    async updateReadAll(read: boolean) {
        return this.api.post('notifications/updateReadAll', { read });
    }

    async handleNotifications() {
        const result = await this.elastic.getNotifications({ read: false }, 0, 999, true);

        this.currentEvoteNotifications = result?.docs?.filter((src) => src?.contentPlaceholder?.ASSEMBLY_ID) || [];

        this.currentDocumentNotifications =
            result?.docs?.filter((src) => src?.contentPlaceholder?.DOCUMENTS_NAME) || [];

        this.evoteNotificationCount =
            [...new Set(this.currentEvoteNotifications.map((not) => not.contentPlaceholder.ASSEMBLY_ID))].length || 0;

        this.documentNotificationCount = 0;
        for (const docNot of this.currentDocumentNotifications) {
            this.documentNotificationCount += docNot.contentPlaceholder.DOCUMENTS_NAME.split(',').length;
        }
    }
}
