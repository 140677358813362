import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-hashtag',
    templateUrl: './hashtag.component.html',
    styleUrls: ['./hashtag.component.scss'],
})
export class HashtagComponent {
    @Input() text: string;
    @Input() active: boolean;
    @Output() onClick: EventEmitter<any> = new EventEmitter();

    hashtagClicked() {
        this.onClick.emit();
    }

    isString(value: any) {
        return typeof value === 'string';
    }
}
