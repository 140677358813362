<ion-header *ngIf="fromProfile">
    <app-topbar
        [left]="'arrow-back'"
        [title]="'auth.namespaceChooser.header' | translate"
        (leftClicked)="closePage()"></app-topbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-row
        [class.ion-align-items-center]="!fromProfile"
        [class.ion-justify-content-center]="!fromProfile"
        [class.h-100]="!fromProfile">
        <ion-col *ngIf="tokens.length" class="ion-no-padding ion-align-self-center">
            <ion-row *ngIf="!fromProfile" class="logo ion-padding-vertical ion-justify-content-center">
                <ion-col size="6">
                    <img [src]="logoUrl" />
                </ion-col>
            </ion-row>

            <ion-row class="title ion-justify-content-center">
                <ion-col>
                    {{ 'auth.namespaceChooser.intro' | translate }}
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-list style="width: 100%">
                    <ion-item *ngFor="let token of tokens" class="namespace" (click)="signIn(token)" detail>
                        <ion-avatar slot="start">
                            <img [src]="token.namespaceLogo || 'assets/icon/favicon.ico'" />
                        </ion-avatar>
                        <ion-label>
                            <h3>{{ token.namespaceName }}</h3>
                            <p>{{ token.username }}</p>
                        </ion-label>
                    </ion-item>
                </ion-list>
            </ion-row>
        </ion-col>
    </ion-row>
</ion-content>
