<div *ngIf="loading" class="spinner-wrapper">
    <ion-spinner></ion-spinner>
</div>
<ion-content>
    <header
        class="head"
        *ngIf="!urlParams.embedded"
        [hidden]="isMobileLayout"
        [style.background-color]="nsData?.multichannel?.theme?.backgroundColorHeader || '#000000'"
        [style.color]="nsData?.multichannel?.theme?.fontColorHeader || '#000000'">
        <div class="divLogo">
            <ion-img class="logo" [src]="nsLogoAsObjectUrl || '../../../assets/imgs/logo.png'"></ion-img>
        </div>
        <div class="head-title texts">
            <p class="texts" *ngIf="texts" [innerHTML]="texts['landing.header']"></p>
        </div>
        <div class="language" *ngIf="!urlParams.hideLanguageIcon">
            <div (click)="changeLanguage()" class="language-box">
                <ion-icon
                    [style.fill]="nsData?.multichannel?.theme?.languageSwitch || '#223343'"
                    name="globe"></ion-icon>
            </div>
        </div>
    </header>

    <header
        class="head-mobile"
        *ngIf="!urlParams.embedded"
        [hidden]="!isMobileLayout"
        [style.background-color]="nsData?.multichannel?.theme?.backgroundColorHeader || '#000000'"
        [style.color]="nsData?.multichannel?.theme?.fontColorHeader || '#000000'">
        <div class="divLogo">
            <ion-img class="logo" [src]="nsLogoAsObjectUrl || '../../../assets/imgs/logo.png'"></ion-img>
        </div>
        <div class="language-mobile" *ngIf="!urlParams.hideLanguageIcon" [hidden]="!isMobileLayout">
            <div (click)="changeLanguage()" class="language-box-mobile">
                <ion-icon
                    [style.fill]="nsData?.multichannel?.theme?.languageSwitch || '#223343'"
                    name="globe"></ion-icon>
            </div>
        </div>
    </header>

    <ion-grid
        [hidden]="loading"
        [ngClass]="{
            'content-grid': nsData?.multichannel?.landingPage?.showDocuments === true,
            'content-grid-no-docs': nsData?.multichannel?.landingPage?.showDocuments === false
        }">
        <ion-col
            *ngIf="!urlParams.embedded"
            class="grid-info space"
            [ngStyle]="{ 'background-color': getInfoBackgroundColor(), color: getInfoFontColor() }"
            [class.center]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.information === 'center' &&
                nsData?.multichannel?.theme?.fontColorCenter
            "
            [class.left]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.information === 'left' &&
                nsData?.multichannel?.theme?.fontColorLeft
            "
            [class.right]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.information === 'right' &&
                nsData?.multichannel?.theme?.fontColorRight
            ">
            <p class="heading" [ngClass]="{ desktop: !isMobileLayout }">
                <span *ngIf="texts" [innerHTML]="texts['landing.heading']"></span>
            </p>
            <div class="texts">
                <span *ngIf="texts" [innerHTML]="texts['landing.info']"></span>

                <ion-row *ngIf="options.appIcons" class="icons">
                    <ion-col class="icons-wrapper" size="12">
                        <a [href]="badges?.playStore?.url">
                            <img [src]="badges?.playStore?.img" />
                        </a>

                        <a [href]="badges?.appStore?.url"><img class="apple" [src]="badges?.appStore?.img" /></a>
                    </ion-col>
                </ion-row>
                <p *ngIf="texts">
                    <a
                        (click)="goToContactForm()"
                        [style.color]="nsData?.multichannel?.theme?.fontColorLeft || '#000000'"
                        [innerHTML]="texts['landing.info.link']"
                        class="clickable">
                    </a>
                </p>
            </div>

            <ion-col size="6" size-lg="6" class="ion-align-self-center">
                <img
                    [src]="multichannelImage"
                    *ngIf="options.showMultichannelImage"
                    [hidden]="isMobileLayout"
                    class="image"
                    alt="" />
            </ion-col>
        </ion-col>
        <ion-col
            class="grid-eservices space"
            [ngStyle]="{ 'background-color': getButtonsBackgroundColor(), color: getButtonsFontColor() }"
            [class.center]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.buttons === 'center' &&
                nsData?.multichannel?.theme?.fontColorCenter
            "
            [class.left]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.buttons === 'left' &&
                nsData?.multichannel?.theme?.fontColorLeft
            "
            [class.right]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.buttons === 'right' &&
                nsData?.multichannel?.theme?.fontColorCenter
            ">
            <p class="heading" [ngClass]="{ desktop: !isMobileLayout }">
                <span *ngIf="texts" [innerHTML]="texts['landing.heading.services']"></span>
            </p>
            <p class="texts">
                <span *ngIf="texts" [innerHTML]="texts['landing.or']"></span>
            </p>
            <ion-col
                size="12"
                size-lg="6"
                class="buttons-container"
                [ngStyle]="{
                    'margin-top': !urlParams.hideLanguageIcon ? '10px' : '0'
                }">
                <ion-card
                    *ngIf="options.login"
                    [class.ion-card-center]="this.urlParams.centerButtons || !urlParams.embedded"
                    [class.portal-button]="urlParams.noMargin"
                    button="true"
                    [style.background-color]="nsData?.multichannel?.theme?.cards || '#FFFFFF'"
                    [style.color]="nsData?.multichannel?.theme?.fontColorMultichannelButtons || '#FFFFFF'"
                    [style.max-width]="urlParams.buttonsMaxWidth"
                    (click)="goToLogin(true)">
                    <ion-row #card class="ion-justify-content-center inner-col">
                        <ion-col size="3">
                            <ion-icon [style.color]="cardContentColor" name="exit"></ion-icon>
                        </ion-col>
                        <ion-col size="9">
                            <ion-label
                                *ngIf="texts"
                                [innerHTML]="texts['landing.login-data']"
                                [style.color]="cardContentColor">
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-card>

                <ion-card
                    *ngIf="options.createTicket"
                    [class.ion-card-center]="this.urlParams.centerButtons || !urlParams.embedded"
                    [class.portal-button]="urlParams.noMargin"
                    button="true"
                    [style.background-color]="nsData?.multichannel?.theme?.cards || '#FFFFFF'"
                    [style.color]="nsData?.multichannel?.theme?.fontColorMultichannelButtons || '#FFFFFF'"
                    [style.max-width]="urlParams.buttonsMaxWidth"
                    (click)="goToContactForm()">
                    <ion-row #card class="ion-justify-content-center inner-col">
                        <ion-col size="3">
                            <ion-icon [style.color]="cardContentColor" name="flash"></ion-icon>
                        </ion-col>
                        <ion-col size="9">
                            <ion-label
                                [style.color]="cardContentColor"
                                *ngIf="texts"
                                [innerHTML]="texts['landing.create-ticket-form']">
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-card>

                <ion-card
                    *ngIf="options.voiceRecording"
                    [class.ion-card-center]="this.urlParams.centerButtons || !urlParams.embedded"
                    [class.portal-button]="urlParams.noMargin"
                    button="true"
                    [style.background-color]="nsData?.multichannel?.theme?.cards || '#FFFFFF'"
                    [style.color]="nsData?.multichannel?.theme?.fontColorMultichannelButtons || '#FFFFFF'"
                    [style.max-width]="urlParams.buttonsMaxWidth"
                    (click)="goToVoiceRecording()">
                    <ion-row #card class="ion-justify-content-center inner-col">
                        <ion-col size="3">
                            <ion-icon [style.color]="cardContentColor" name="mic"></ion-icon>
                        </ion-col>
                        <ion-col size="9">
                            <ion-label
                                [style.color]="cardContentColor"
                                *ngIf="texts"
                                [innerHTML]="texts['landing.create-voice-ticket-form']">
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-card>

                <ion-card
                    *ngIf="nsData?.mail"
                    [class.ion-card-center]="this.urlParams.centerButtons || !urlParams.embedded"
                    [class.portal-button]="urlParams.noMargin"
                    button="true"
                    [style.background-color]="nsData?.multichannel?.theme?.cards || '#FFFFFF'"
                    [style.color]="nsData?.multichannel?.theme?.fontColorMultichannelButtons || '#FFFFFF'"
                    [style.max-width]="urlParams.buttonsMaxWidth"
                    (click)="sendMail()">
                    <ion-row #card class="ion-justify-content-center inner-col">
                        <ion-col size="3">
                            <ion-icon [style.color]="cardContentColor" name="mail"></ion-icon>
                        </ion-col>
                        <ion-col size="9">
                            <ion-label [style.color]="cardContentColor" *ngIf="texts" class="no-wrap">
                                {{ texts['landing.mailTo'] }}
                                <span>{{ nsData?.mail }}</span>
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-card>

                <ion-card
                    *ngIf="nsData?.mobile"
                    [class.ion-card-center]="this.urlParams.centerButtons || !urlParams.embedded"
                    [class.portal-button]="urlParams.noMargin"
                    button="true"
                    [style.background-color]="nsData?.multichannel?.theme?.cards || '#FFFFFF'"
                    [style.color]="nsData?.multichannel?.theme?.fontColorMultichannelButtons || '#FFFFFF'"
                    [style.max-width]="urlParams.buttonsMaxWidth"
                    (click)="callUser()">
                    <ion-row #card class="ion-justify-content-center inner-col">
                        <ion-col size="3">
                            <ion-icon [style.color]="cardContentColor" name="phone-portrait"></ion-icon>
                        </ion-col>
                        <ion-col size="9">
                            <ion-label [style.color]="cardContentColor" *ngIf="texts">
                                {{ texts['landing.callUs'] }}
                                <br />
                                {{ nsData?.mobile }}
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-card>
            </ion-col>
        </ion-col>

        <ion-col
            *ngIf="options.showDocuments"
            class="grid-documents space"
            [ngStyle]="{ 'background-color': getDocumentsBackgroundColor(), color: getDocumentsFontColor() }"
            [class.center]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents === 'center' &&
                nsData?.multichannel?.theme?.fontColorCenter
            "
            [class.left]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents === 'left' &&
                nsData?.multichannel?.theme?.fontColorLeft
            "
            [class.right]="
                nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents === 'right' &&
                nsData?.multichannel?.theme?.fontColorRight
            ">
            <p class="heading" [ngClass]="{ desktop: !isMobileLayout }">
                <span *ngIf="texts" [innerHTML]="texts['landing.documents']"></span>
            </p>

            <div class="separator-line"></div>

            <ion-col class="documents-wrapper" size="12">
                <div
                    [ngClass]="{
                        documents:
                            nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents === 'center',
                        'documents-side':
                            nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents === 'left' ||
                            nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents === 'right'
                    }">
                    <div *ngFor="let btn of nsData?.multichannel?.landingPage?.multichannelButtons" class="buttonsLink">
                        <button
                            [style.color]="nsData?.multichannel?.theme?.fontColorButtonsDocs || '#FFFFFF'"
                            (click)="buttonNavigate(btn.url)">
                            <ion-row #card class="inner-col">
                                <ion-col size="3">
                                    <ion-icon
                                        [style.color]="cardContentColor"
                                        class="documents-icon"
                                        name="link"></ion-icon>
                                </ion-col>
                                <br />
                                <ion-col size="9" class="text">
                                    <ion-label [style.color]="cardContentColor">
                                        {{ btn.label }}
                                    </ion-label>
                                </ion-col>
                            </ion-row>
                        </button>
                    </div>
                </div>
            </ion-col>
        </ion-col>
    </ion-grid>
</ion-content>
