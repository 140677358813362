<ion-header>
    <app-topbar
        [title]="'ticket-pictures.title' | translate"
        [left]="leftIcon"
        (leftClicked)="leftFunction()"
        [right]="'close'"
        (rightClicked)="modalController.dismiss()"></app-topbar>
</ion-header>

<ion-content>
    <ion-row class="h-100 ion-align-items-center">
        <ion-slides pager="true" [options]="slideOpts" class="h-100">
            <ion-slide *ngFor="let image of images">
                <div class="swiper-zoom-container">
                    <img [src]="image.file" />
                </div>
            </ion-slide>
        </ion-slides>
    </ion-row>
</ion-content>
