export interface InputOptions {
    visible: boolean;
    imageUpload: boolean;
    type: InputType;
}

export enum InputType {
    Text = 'text',
    Phone = 'phone',
    Number = 'number',
    Email = 'email',
    GooglePlaces = 'googleplaces',
    Address = 'address',
    NS = 'address',
    ALL = 'googleplaces',
    NONE = 'text',
}
