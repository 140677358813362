<ion-tabs (ionTabsDidChange)="tabsChanged()">
    <ion-tab-bar *ngIf="isTokenLogin">
        <ion-tab-button
            *ngIf="
                showTicketTab &&
                ((userService?.moduleSubscription?.value || []).includes('tickets') ||
                    (userService?.moduleSubscription?.value || []).includes('handover'))
            "
            tab="guest">
            <ion-icon name="flash"></ion-icon>
            <ion-label>{{ 'menu.requests' | translate }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
            *ngIf="!isRestrictedTokenLogin && (userService?.moduleSubscription?.value || []).includes('communication')"
            tab="blackboard">
            <ion-icon name="clipboard"></ion-icon>
            <ion-label>{{ 'menu.blackboard' | translate }}</ion-label>
            <ion-badge *ngIf="messages > 0">
                {{ messages > 9 ? '9+' : messages }}
            </ion-badge>
        </ion-tab-button>
        <ion-tab-button *ngIf="!isRestrictedTokenLogin" tab="guest-documents">
            <ion-icon name="document"></ion-icon>
            <ion-label>{{ 'menu.documents' | translate }}</ion-label>
        </ion-tab-button>
        <ion-tab-button *ngIf="!isRestrictedTokenLogin" tab="guest-profile">
            <ion-icon name="person"></ion-icon>
            <ion-label>{{ 'menu.profile' | translate }}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>

    <ion-tab-bar *ngIf="!isTokenLogin" slot="bottom">
        <ion-tab-button
            *ngIf="
                (userService?.moduleSubscription?.value || []).includes('tickets') ||
                (userService?.moduleSubscription?.value || []).includes('handover')
            "
            tab="tickets">
            <ion-icon name="flash"></ion-icon>
            <ion-label>{{ 'menu.requests' | translate }}</ion-label>
            <ion-badge *ngIf="ticketsCount?.OPEN + ticketsCount?.IN_WORK > 0">
                {{ ticketsCount?.OPEN + ticketsCount?.IN_WORK }}
            </ion-badge>
        </ion-tab-button>

        <ion-tab-button
            *ngIf="(userService?.moduleSubscription?.value || []).includes('communication')"
            tab="blackboard">
            <ion-icon name="clipboard"></ion-icon>
            <ion-label>{{ 'menu.blackboard' | translate }}</ion-label>
            <ion-badge *ngIf="messages > 0">
                {{ messages > 9 ? '9+' : messages }}
            </ion-badge>
        </ion-tab-button>

        <ion-tab-button tab="documents">
            <ion-icon name="document"></ion-icon>
            <ion-label>{{ 'menu.documents' | translate }}</ion-label>
            <ion-badge *ngIf="notificationsService?.documentNotificationCount > 0">
                {{
                    notificationsService.documentNotificationCount > 9
                        ? '9+'
                        : notificationsService.documentNotificationCount
                }}
            </ion-badge>
        </ion-tab-button>

        <ion-tab-button
            *ngIf="
                userService?.user?.type === 'owner' &&
                (userService?.moduleSubscription?.value || []).includes('assembly')
            "
            tab="evote">
            <span class="material-icons"> how_to_vote </span>
            <ion-label>{{ 'menu.evote' | translate }}</ion-label>
            <ion-badge
                *ngIf="notificationsService?.evoteNotificationCount > 0"
                class="material-badge"
                style="left: calc(50% + 6px) !important; top: 4px !important">
                {{
                    notificationsService.evoteNotificationCount > 9 ? '9+' : notificationsService.evoteNotificationCount
                }}
            </ion-badge>
        </ion-tab-button>

        <ion-tab-button
            *ngIf="
                userService?.user?.type === 'owner' &&
                (userService?.moduleSubscription?.value || []).includes('assemblyV2')
            "
            tab="evote2">
            <span class="material-icons"> how_to_vote </span>
            <ion-label>{{ 'menu.evote' | translate }}</ion-label>
            <ion-badge
                *ngIf="notificationsService?.evoteNotificationCount > 0"
                class="material-badge"
                style="left: calc(50% + 6px) !important; top: 4px !important">
                {{
                    notificationsService.evoteNotificationCount > 9 ? '9+' : notificationsService.evoteNotificationCount
                }}
            </ion-badge>
        </ion-tab-button>

        <ion-tab-button tab="news">
            <ion-icon name="notifications"></ion-icon>
            <ion-label>{{ 'menu.news' | translate }}</ion-label>
            <ion-badge *ngIf="notifications > 0">
                {{ notifications > 9 ? '9+' : notifications }}
            </ion-badge>
        </ion-tab-button>

        <ion-tab-button tab="profile">
            <ion-icon name="person"></ion-icon>
            <ion-label>{{ 'menu.profile' | translate }}</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs>
