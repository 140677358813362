import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Ticket } from '../../models/ticket';
import { Manager } from '../../models/manager';
import { ProfileService } from '../../services/profile/profile.service';
import { Timeout } from '../../util/timeout';
import { ChatMessage } from '../../models/chat-message';
import { OPTIONS } from '../../const/ticket.const';
import { STEP_CONFIRM, STEP_STORNO_FAILED, STEP_STORNO_SUCCESS, STEP_WHY } from '../../const/chat-storno';
import { ManagerMessagePipe } from '../../pipes/chat-message/manager-message';
import { UserMessagePipe } from '../../pipes/chat-message/user-message';
import { TicketsService } from '../../services/tickets/tickets.service';
import { AnalyticsService } from '../../services/analytics';
import { ANALYTICS } from '../../const/analytics.const';
import { PropertyService } from '../../services/property/property.service';
import { InputOptions, InputType } from '../../const/input.const';

@Component({
    selector: 'app-ticket-storno',
    templateUrl: './ticket-storno.component.html',
    styleUrls: ['./ticket-storno.component.scss'],
})
export class TicketStornoComponent implements OnInit {
    @Input() ticket: Ticket;
    chatMessages: ChatMessage[] = [];
    manager: Manager;
    inputOptions: InputOptions = {
        visible: false,
        imageUpload: false,
        type: InputType.Text,
    };
    stornoComment = '';
    cancelComplete = false;

    constructor(
        public modalController: ModalController,
        private profileService: ProfileService,
        private managerMessage: ManagerMessagePipe,
        private userMessage: UserMessagePipe,
        private ticketService: TicketsService,
        private analytics: AnalyticsService,
        private propertyService: PropertyService
    ) {}

    async ngOnInit() {
        this.analytics.trackView(ANALYTICS.VIEWS.CANCEL_TICKET_MODAL);
        this.manager =
            this.ticket.manager || (await this.propertyService.getManager(this.ticket.propertyManagerIds[0]));
        this.inputOptions.visible = true;

        await Timeout(1000);
        this.chatMessages.push(this.managerMessage.transform(STEP_WHY, this.manager));
    }

    async textEntered(text) {
        this.stornoComment = text;
        this.chatMessages.push(this.userMessage.transform(text));
        this.inputOptions.visible = false;
        await Timeout(1000);

        this.chatMessages.push(this.managerMessage.transform(STEP_CONFIRM, this.manager));
        this.chatMessages.push(this.managerMessage.transform(STEP_CONFIRM.children, null));
    }

    async optionSelected(option) {
        if (option.id === OPTIONS.CONTINUE) {
            this.chatMessages.splice(this.chatMessages.length - 1, 1);
            this.chatMessages.push(this.userMessage.transform(option.text));
            try {
                await this.ticketService.cancelTicket(this.ticket.id, this.stornoComment);
                this.analytics.trackEvent(ANALYTICS.ACTIONS.TICKET_CANCELED);
                this.chatMessages.push(this.managerMessage.transform(STEP_STORNO_SUCCESS, this.manager));
                this.chatMessages.push(this.managerMessage.transform(STEP_STORNO_SUCCESS.children, null));
                this.cancelComplete = true;
            } catch (err) {
                // handle error
                console.error('cancel error', err);
                this.chatMessages.push(this.managerMessage.transform(STEP_STORNO_FAILED, this.manager));
                this.chatMessages.push(this.managerMessage.transform(STEP_STORNO_FAILED.children, null));
            }
        } else {
            this.modalController.dismiss(this.cancelComplete);
        }
    }
}
