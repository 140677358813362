import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../../services/analytics';
import { ANALYTICS } from '../../../const/analytics.const';

@Component({
    selector: 'app-ticket-appointment-card',
    templateUrl: './ticket-appointment-card.component.html',
    styleUrls: ['./ticket-appointment-card.component.scss'],
})
export class TicketAppointmentCardComponent {
    @Input() data;
    constructor(private analytics: AnalyticsService) {}

    onExpand() {
        this.analytics.trackEvent(ANALYTICS.ACTIONS.ON_EXPAND_TICKET_APPOINTMENT_CARD);
    }
}
