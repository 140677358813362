import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
    selector: 'app-token-profile',
    templateUrl: './token-documents.page.html',
    styleUrls: ['./token-documents.page.scss'],
})
export class TokenDocumentsPage implements OnInit {
    public registrationLink;
    constructor(private userService: UserService) {}

    async ngOnInit() {
        try {
            this.registrationLink = await this.userService.getRegistrationLink();
        } catch (error) {
            console.error(error);
        }
    }
}
