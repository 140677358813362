import { ApiService } from '../api/api.service';
import { UserService } from '../user/user.service';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class ApiTranslationService {
    currentNs = null;
    currentLang = null;

    constructor(
        private translate: TranslateService,
        private translateService: TranslateService,
        private userService: UserService,
        private apiService: ApiService
    ) {
        this.userService.getNamespaceObservable().subscribe(async (ns) => {
            const lang = this.translateService.currentLang;

            if (this.currentNs !== ns && lang) {
                this.currentNs = ns;
                const translations = await this.getTranslation(lang).toPromise();
                this.translate.setTranslation(lang, translations, false);
            }
        });
    }

    public getTranslation(lang: string): Observable<any> {
        this.currentLang = lang;

        return this.userService.getNamespaceObservable().pipe(
            map((ns) => {
                return ns;
            }),
            switchMap((ns: string) => {
                return from(
                    this.getTranslationsFromApi(
                        [lang],
                        ['tenant', 'pushNotifications'],
                        undefined,
                        ns ? ns : 'default',
                        true
                    )
                );
            }),
            map((translations) => {
                const result = {};
                for (const [domain, domainTranslations] of Object.entries(translations)) {
                    for (const key of Object.getOwnPropertyNames(domainTranslations[lang])) {
                        if (domain === 'tenant') {
                            result[key] = domainTranslations[lang][key];
                        } else {
                            result[`${domain}:${key}`] = domainTranslations[lang][key];
                        }
                    }
                }

                return result;
            })
        );
    }

    async getTranslationsFromApi(
        languages?: string[],
        domains?: string[],
        keys?: string[],
        otherNamespaceId = '',
        mergeDefault = false
    ) {
        const queryParams = new URLSearchParams({
            languages: languages ? languages.join(',') : '',
            domains: domains ? domains.join(',') : '',
            keys: keys ? keys.join(',') : '',
            ns: otherNamespaceId,
            mergeDefault: '' + mergeDefault,
        });

        return this.apiService.get(`translations?${decodeURIComponent(queryParams.toString())}`);
    }
}
