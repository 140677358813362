import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoadingController, Platform, ModalController } from '@ionic/angular';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { UserService } from '../user/user.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../logger/logger.service';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../api/api.service';
import { BuildInfo } from '@ionic-native/build-info/ngx';
import { ForceUpdateModalComponent } from 'src/app/modals/force-update/force-update-modal.component';

@Injectable({
    providedIn: 'root',
})
export class UpdateService {
    private logger: LoggerService;
    private oldNsMaintenance = false;
    private oldMaintenance = false;
    private forceUpdateModalRef;

    constructor(
        private platform: Platform,
        private deploy: Deploy,
        private userService: UserService,
        private splashScreen: SplashScreen,
        private loadingController: LoadingController,
        private translate: TranslateService,
        private auth: AuthService,
        private api: ApiService,
        private modalController: ModalController
    ) {
        this.logger = new LoggerService('UpdateService');
    }

    public async initialize() {
        await this.platform.ready;

        // Check for updates when resuming app
        this.platform.resume.subscribe(async () => {
            if (await this.otaActive()) {
                this.checkAndInstallUpdates();
            }
        });

        // Check for updates after ns maintenance
        this.auth.nsMaintenance$.subscribe((nsMaintenance) => {
            if (!nsMaintenance && this.oldNsMaintenance) {
                this.checkAndInstallUpdates();
            }
            this.oldNsMaintenance = nsMaintenance;
        });

        // Check for updates after global maintenance
        this.auth.maintenance$.subscribe(async (maintenance) => {
            if (!maintenance && this.oldMaintenance) {
                if (await this.otaActive()) {
                    this.checkAndInstallUpdates();
                }
            }
            this.oldMaintenance = maintenance;
        });

        // Check for updates on app start
        if (await this.otaActive()) {
            await this.checkAndInstallUpdates();
        }
    }

    async otaActive() {
        try {
            if (this.forceUpdateModalRef) {
                await this.forceUpdateModalRef.dismiss();
                this.forceUpdateModalRef = null;
            }

            if (this.platform.is('cordova')) {
                const platformName = this.platform.is('android') ? 'android' : 'ios';
                const updateInfo = await this.api.get('updateInformation');
                const minVersionGlobal = updateInfo?.forceUpdate[BuildInfo.prototype.packageName] || '0.0.0';
                const minVersionPlatform =
                    updateInfo?.forceUpdate[platformName][BuildInfo.prototype.packageName] || '0.0.0';
                const minVersion = minVersionGlobal > minVersionPlatform ? minVersionGlobal : minVersionPlatform;

                if (updateInfo) {
                    if (minVersion > BuildInfo.prototype.version) {
                        this.forceUpdateModalRef = await this.modalController.create({
                            component: ForceUpdateModalComponent,
                            cssClass: 'fullscreen',
                            showBackdrop: false,
                            backdropDismiss: false,
                        });

                        this.forceUpdateModalRef.present();
                        return false;
                    } else {
                        return Boolean(updateInfo?.otaActive);
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    /**
     * This function checks and installs OTA Updates. It is by default configured to
     * pull from the Dev channel, but we install the plugin with the correct channel
     * on the CI server before building the App. Since we directly depend on the plugin's
     * properties, we need it installed by default to get the App to compile.
     */
    private async checkAndInstallUpdates() {
        if (this.platform.is('cordova')) {
            try {
                const storeLink = this.getStoreLink();

                await this.deploy.configure({ channel: environment.name });
                const hasUpdate = await this.deploy.checkForUpdate();
                if (hasUpdate.available) {
                    await this.waitForTranslation('general.update.loading');
                    const loading = await this.loadingController.create({
                        message: this.translate.instant('general.update.loading', {
                            percentage: String(0),
                            STORE_LINK: storeLink,
                        }),
                    });
                    this.splashScreen.hide();

                    await loading.present();
                    await this.deploy.sync({ updateMethod: 'auto' }, (percentDone) => {
                        loading.message = this.translate.instant('general.update.loading', {
                            percentage: String(percentDone),
                            STORE_LINK: storeLink,
                        });
                    });
                }
            } catch (err) {
                this.logger.error('Check for App update failed', err);
            }
        }
    }

    private getStoreLink() {
        try {
            return this.platform.is('android')
                ? `<a href="${environment.store_link.android}" target="_system">Play Store</a>`
                : `<a href="${environment.store_link.ios}">App Store</a>`;
        } catch (e) {
            return '';
        }
    }

    /**
     *  This code might be executed before the translations were initialized, therefore we
     *  need to await them.
     */
    private async waitForTranslation(translationKey) {
        return new Promise<void>((resolve) => {
            this.userService.userLanguage$.subscribe((nl) => {
                const sub = this.translate.stream(translationKey).subscribe((trans) => {
                    if (trans !== translationKey) {
                        resolve();
                        if (sub) {
                            sub.unsubscribe();
                        }
                    }
                });
            });
        });
    }
}
