<app-segment-slider
    #slider
    customTheme="true"
    class="h-100"
    [headerData]="sliderHeaderData"
    [initialSlide]="sliderInitialSlide"
    (slideChanged)="onSlideChanged($event)">
    <app-vote-view left [ownerShipAssemblies]="assembliesOpen"></app-vote-view>
    <app-vote-view middleleft [ownerShipAssemblies]="assembliesClosed"></app-vote-view>
</app-segment-slider>
