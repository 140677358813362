import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import { BaseClass } from '../../util/base-class';
import { Manager } from '../../models/manager';
import { PropertyService } from '../property/property.service';
import { DEFAULT_PROFILE_PICTURE } from '../../const/app.const';

@Injectable({
    providedIn: 'root',
})
export class ProfileService extends BaseClass {
    managers: { string?: Manager } = {};
    constructor(
        private afs: AngularFirestore,
        private propertyService: PropertyService,
        private userService: UserService
    ) {
        super('ProfileService');
    }

    getManagerProfilePicture(managerId) {
        if (this.managers[managerId] && this.managers[managerId].profilePicture) {
            return Promise.resolve(this.managers[managerId].profilePicture);
        }
        return this.getManagerProfile(managerId).then((managerData: Manager) => {
            if (managerData) {
                this.managers[managerId].profilePicture = managerData.profilePicture;
                return managerData.profilePicture;
            }
            return null;
        });
    }

    async getManagerProfile(managerId): Promise<Manager> {
        if (this.managers[managerId]) {
            return this.managers[managerId];
        }
        return this.afs
            .collection('ns')
            .doc(this.userService.user.ns)
            .collection('propertyManagers')
            .doc(managerId)
            .get()
            .toPromise()
            .then((doc) => {
                if (doc.exists) {
                    const manager = doc.data() as Manager;
                    if (!manager.profilePicture) {
                        manager.profilePicture = DEFAULT_PROFILE_PICTURE;
                    }
                    this.managers[managerId] = manager;
                    return manager;
                }
                return null;
            });
    }
}
