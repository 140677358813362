import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Language } from '../../models/language';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language/language.service';
import { AnalyticsService } from '../../services/analytics';
import { ANALYTICS } from '../../const/analytics.const';

@Component({
    selector: 'app-language-selection',
    templateUrl: './language-selection.component.html',
    styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent {
    selectedLanguage = null;
    languages: Language[] = [];
    favoriteLanguage: any;

    constructor(
        private modalCtrl: ModalController,
        private languageService: LanguageService,
        private translate: TranslateService,
        private analytics: AnalyticsService
    ) {
        const language = this.translate.currentLang;
        if (language) {
            this.selectedLanguage = language;
        } else {
            this.selectedLanguage = 'de';
        }
        this.languages = this.languageService.searchLanguage();
        this.favoriteLanguage = this.languages.filter((lang) => {
            return lang.iso639_1 === this.selectedLanguage;
        })[0];
    }

    setLanguage(lang) {
        this.analytics.trackEvent(ANALYTICS.ACTIONS.CHANGE_LANGUAGE);
        this.modalCtrl.dismiss(lang);
    }

    close() {
        this.modalCtrl.dismiss();
    }

    searchLanguage(searchTerm: string = '') {
        this.languages = this.languageService.searchLanguage(searchTerm);
    }
}
