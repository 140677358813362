<ion-row class="h-100 w-100 ion-justify-content-center ion-align-items-center">
    <ion-col size="8" size-sm="6" size-lg="4">
        <img class="maintenance-logo" [src]="maintenanceLogo" alt="maintenance logo" />

        <h1 class="dots-animation ion-text-center">
            {{ 'maintenance.text' | translate }}<span>.</span><span>.</span><span>.</span>
        </h1>

        <img src="assets/imgs/maintenance-content.svg" alt="Maintenance" />
    </ion-col>
</ion-row>
