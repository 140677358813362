import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

export enum NoDataComponentSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}

@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent {
    @Input() text = 'No Data';
    @Input() icon = 'information-circle-outline';
    @Input() size: NoDataComponentSize = NoDataComponentSize.LARGE;
    @Input() routerLink: string;
    NoDataComponentSize = NoDataComponentSize;

    constructor(private navController: NavController) {}

    async navigate() {
        if (this.routerLink) {
            await this.navController.navigateForward(this.routerLink);
        }
    }
}
