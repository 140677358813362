import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavController, ModalController, Platform } from '@ionic/angular';
import { LanguageSelectionComponent } from '../../modals/language-selection/language-selection.component';
import { PopupService } from '../../services/popup/popup.service';
import { ApiService } from '../../services/api/api.service';
import { BrowserService } from '../../services/browser/browser.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';
import { skip, take } from 'rxjs/operators';
import { ThemeService } from '../../services/theme/theme.service';
import { ContactFormPage } from '../contact-form/contact-form.page';
import { VoiceRecordPage } from '../voice-record/voice-record.page';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.page.html',
    styleUrls: ['./landing.page.scss'],
})
export class LandingPage implements OnInit {
    public badges;

    public nsData: {
        logo: string;
        mobile: string;
        mail: string;
        language: string;
        texts: any;
        id?: string;
        theme;
        multichannel?: {
            email: string;
            password: string;
            image: string;
            theme;
            landingPage;
            maxRecordingTimeInSeconds: number;
        };
    };
    public options = {
        appIcons: true,
        voiceRecording: false,
        login: false,
        createTicket: false,
        showMultichannelImage: true,
        showDocuments: false,
    };
    public urlParams = {
        embedded: false,
        centerButtons: false,
        mailTargetBlank: false,
        loginTargetBlank: false,
        hideLanguageIcon: false,
        buttonsMaxWidth: null,
        noMargin: false,
        namespace: null,
        lang: null,
    };
    public texts: any[];
    private language: string;
    public isMobileLayout: boolean;

    public nsLogoAsObjectUrl: string;
    public loading = true;
    public multichannelImage: string;
    multichannelButtons: Array<{ label: string; url: string }> = [];

    themeConfigurationColors = [
        { id: 'backgroundColorLeft', translation: 'backgroundColorLeft' },
        { id: 'backgroundColorRight', translation: 'backgroundColorRight' },
        { id: 'cards', translation: 'cards' },
        { id: 'languageSwitch', translation: 'languageSwitch' },
        { id: 'fontColor', translation: 'fontColor' },
    ];
    public cardContentColor = '';
    public headingTextColor = '';
    public fontColor = '';

    constructor(
        private translate: TranslateService,
        private browser: BrowserService,
        private navController: NavController,
        private location: Location,
        private api: ApiService,
        private popupService: PopupService,
        private userService: UserService,
        private modalCtrl: ModalController,
        private themeService: ThemeService,
        private platform: Platform
    ) {}

    async ngOnInit() {
        const urlParams = new URLSearchParams(window.location.search);
        this.urlParams.namespace = urlParams?.get('ns');
        this.urlParams.centerButtons = Boolean(urlParams.get('centerButtons'));
        this.urlParams.loginTargetBlank = Boolean(urlParams.get('loginTargetBlank'));
        this.urlParams.mailTargetBlank = Boolean(urlParams.get('mailTargetBlank'));
        this.urlParams.embedded = Boolean(urlParams.get('embedded'));
        this.urlParams.buttonsMaxWidth = urlParams.get('buttonsMaxWidth') ? urlParams.get('buttonsMaxWidth') : '350px';
        this.urlParams.hideLanguageIcon = Boolean(urlParams.get('hideLanguageIcon'));
        this.urlParams.noMargin = Boolean(urlParams.get('noMargin'));
        this.urlParams.lang = urlParams?.get('lang') || this.translate.getBrowserLang();
        this.language = this.urlParams.lang;

        this.isMobileLayout = Boolean(window.innerWidth <= 992);

        this.platform.resize.subscribe(() => {
            this.isMobileLayout = this.platform.width() <= 992;
        });

        try {
            await this.userService.setLanguage(this.language, true);
        } catch (err) {
            // do nothing
        } finally {
            await this.translate.use(this.language).pipe(take(1), skip(0)).toPromise();

            if (this.urlParams.namespace) {
                try {
                    this.nsData = await this.api.get(
                        `namespaces/info/${this.urlParams.namespace}?language=${this.language}`
                    );

                    this.texts = this.nsData.texts[this.language];
                    this.nsData.id = this.urlParams.namespace;
                    this.badges = await this.userService.getBadges();

                    if (this.nsData.logo) {
                        this.nsLogoAsObjectUrl = URL.createObjectURL(await (await fetch(this.nsData.logo)).blob());
                    }

                    this.multichannelImage =
                        this.nsData.multichannel.image ||
                        'https://storage.googleapis.com/woonig-test.appspot.com/WoonigMultichannelSmall.png';

                    this.options = this.nsData.multichannel.landingPage;
                } catch (error) {
                    await this.showNamespaceNotFoundAlert();
                    await this.goToLogin();
                }

                this.themeService.setTheme(this.nsData.theme);

                for (const configColor of this.themeConfigurationColors) {
                    if (this.nsData.multichannel.theme[configColor.id]) {
                        this.nsData.theme[configColor.id] = this.nsData.multichannel.theme[configColor.id];
                    }
                }

                this.loading = false;
            } else {
                await this.showNamespaceNotFoundAlert();
                await this.goToLogin();
                this.loading = false;
            }
        }

        if (this.urlParams.hideLanguageIcon) {
            this.appendParamToURL('lang', this.language);
        }
    }

    ionViewDidEnter() {
        document
            .querySelector('ion-content')
            .shadowRoot.querySelector('#background-content')
            .setAttribute('style', 'background-color: transparent');
    }

    appendParamToURL(param, value) {
        const url = window.location.href;
        const newUrl = new RegExp(param).test(url) ? url : `${url}&${param}=${value}`;

        if (url != newUrl) {
            window.location.href = newUrl;
        }
    }

    private async showNamespaceNotFoundAlert() {
        return await this.popupService.showAlert('', this.translate.instant('landing.error.ns-not-found'), [
            {
                text: this.translate.instant('ok'),
            },
        ]);
    }

    async changeLanguage() {
        const languageModal = await this.modalCtrl.create({
            component: LanguageSelectionComponent,
        });

        await languageModal.present();
        const { data } = await languageModal.onDidDismiss();

        if (data) {
            this.language = data;
            const nsData = await this.api.get(`namespaces/info/${this.urlParams.namespace}?language=${this.language}`);
            this.translate.resetLang(data);

            try {
                this.translate.use(data);
                // Use NS specific translations for new language
                this.texts = nsData.texts[this.language];
            } catch (err) {
                await this.popupService.showToast(this.translate.instant('profile.change-language.error'));
            }
        }
    }

    callUser() {
        this.browser.callPhone(this.nsData.mobile);
    }

    async sendMail() {
        if (this.urlParams.mailTargetBlank) {
            window.open(`mailto:${this.nsData.mail}`, '_blank');
        } else {
            await this.browser.sendMail(this.nsData.mail, `${this.translate.instant('landing.support-mail.subject')}`);
        }
    }

    public async goToLogin(param = false) {
        if (this.urlParams.loginTargetBlank) {
            window.open(`${location.protocol}//${location.host}/login?ns=${this.nsData.id}`, '_blank').focus();
        } else {
            await this.navController.navigateRoot(`login${param ? '?ns=' + this.nsData.id : ''}`);
        }
    }

    public async goToContactForm() {
        const modal = await this.modalCtrl.create({
            component: ContactFormPage,
            componentProps: {
                nsData: this.nsData,
                language: this.language,
                embedded: this.urlParams.embedded,
            },
            backdropDismiss: false,
            cssClass: 'contact-form-modal',
        });

        await modal.present();
    }

    public async goToVoiceRecording() {
        const modal = await this.modalCtrl.create({
            component: VoiceRecordPage,
            componentProps: {
                nsData: this.nsData,
                language: this.language,
            },
            backdropDismiss: false,
            cssClass: 'voice-record-modal',
        });

        await modal.present();
    }

    buttonNavigate(url: string): void {
        window.open(url, '_blank');
    }

    getInfoBackgroundColor() {
        const info = this.nsData?.multichannel?.landingPage?.multichannelSettingsPosition.information;
        return this.getBackgroundColor(info);
    }

    getButtonsBackgroundColor() {
        const info = this.nsData?.multichannel?.landingPage?.multichannelSettingsPosition.buttons;
        return this.getBackgroundColor(info);
    }

    getDocumentsBackgroundColor() {
        const info = this.nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents;
        return this.getBackgroundColor(info);
    }

    getBackgroundColor(info: string) {
        if (info === 'center') {
            return this.nsData?.multichannel?.theme?.backgroundColorCenter || '#FFFFFF';
        }
        if (info === 'left') {
            return this.nsData?.multichannel?.theme?.backgroundColorLeft || '#FFFFFF';
        }
        if (info === 'right') {
            return this.nsData?.multichannel?.theme?.backgroundColorRight || '#FFFFFF';
        }
        return '#FFFFFF';
    }

    getInfoFontColor() {
        const info = this.nsData?.multichannel?.landingPage?.multichannelSettingsPosition.information;
        return this.getFontColor(info);
    }

    getButtonsFontColor() {
        const info = this.nsData?.multichannel?.landingPage?.multichannelSettingsPosition.buttons;
        return this.getFontColor(info);
    }

    getDocumentsFontColor() {
        const info = this.nsData?.multichannel?.landingPage?.multichannelSettingsPosition.documents;
        return this.getFontColor(info);
    }

    getFontColor(info: string) {
        if (info === 'center') {
            return this.nsData?.multichannel?.theme?.fontColorCenter || '#000000';
        }
        if (info === 'left') {
            return this.nsData?.multichannel?.theme?.fontColorLeft || '#000000';
        }
        if (info === 'right') {
            return this.nsData?.multichannel?.theme?.fontColorRight || '#000000';
        }
        return '#000000';
    }
}
