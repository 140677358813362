import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { TopbarComponent } from './topbar.component';

@NgModule({
    imports: [ComponentsSharedModule],
    declarations: [TopbarComponent],
    exports: [TopbarComponent],
})
export class TopbarComponentModule {}
