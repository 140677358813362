<ion-item lines="none" class="textarea-wrapper">
    <ion-textarea
        [debounce]="300"
        [(ngModel)]="autocomplete.input"
        appAutoFocus
        (ionChange)="updateSearchResults()"
        [placeholder]="'google-places-search-placeholder' | translate"></ion-textarea>
    <ion-fab-button [disabled]="!hasSelected" class="ion-margin-left" size="small" (click)="submit()">
        <ion-icon name="send"></ion-icon>
    </ion-fab-button>
</ion-item>
<ion-list [hidden]="autocompleteItems.length === 0">
    <ion-item *ngFor="let item of autocompleteItems" tappable (click)="selectSearchResults(item)">
        <ion-icon name="location-outline"></ion-icon>
        {{ item.description }}
    </ion-item>
    <ion-item lines="none">
        <img src="assets/imgs/powered_by_google_on_white.png" />
    </ion-item>
</ion-list>
