import { SearchComponent } from './search.component';
import { NgModule } from '@angular/core';
import { ComponentsSharedModule } from '../../components.shared.module';
import { ListWithChipComponentModule } from '../../organisms/list-with-chip/list-with-chip.component.module';
import { LocaleDatePipe } from 'src/app/pipes/locale-date/locale-date.pipe';

@NgModule({
    imports: [ComponentsSharedModule, ListWithChipComponentModule],
    declarations: [SearchComponent],
    providers: [LocaleDatePipe],
    exports: [SearchComponent],
})
export class SearchComponentModule {}
