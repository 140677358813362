import { Component } from '@angular/core';
import { APPOINTMENTS } from '../../const/appointments.const';
import { Appointment } from '../../models/appointment';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-appointments',
    templateUrl: './appointments.component.html',
    styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent {
    setAppointments: Appointment[] = [];
    days = [];
    times = [];
    selectedDay: string;
    selectedTime: string;

    constructor(public modalController: ModalController) {
        for (const day of APPOINTMENTS.DAYS) {
            this.days.push({ id: day.id, text: day.text, chips: [] });
        }
        for (const time of APPOINTMENTS.TIMES) {
            this.times.push({ id: time.id, text: time.text });
        }
    }

    daySelected(id: string) {
        for (const d of this.days) {
            d.selected = false;
        }
        this.selectedDay = id;

        if (this.selectedTime) {
            this.removeExistingAppointmentForDay();

            this.setAppointments.push({
                day: this.selectedDay,
                time: this.selectedTime,
            });

            this.handleChips();

            this.clearSelection();
        } else {
            this.days.find((d) => d.id === id).selected = true;
        }
    }

    timeSelected(id: string) {
        for (const t of this.times) {
            t.selected = false;
        }
        this.selectedTime = id;

        if (this.selectedDay) {
            this.removeExistingAppointmentForDay();
            this.setAppointments.push({
                day: this.selectedDay,
                time: this.selectedTime,
            });

            this.handleChips();
            this.clearSelection();
        } else {
            this.times.find((t) => t.id === id).selected = true;
        }
    }

    chipClicked(data: { dayId: string; chipId: string }) {
        this.days.find((d) => d.id === data.dayId).chips = [];
    }

    private handleChips() {
        const day = this.days.find((d) => d.id === this.selectedDay);
        // might need this later when multiple chips become an option
        // if (
        //     day.chips.findIndex((ch) => ch.id === this.selectedTime) === -1
        // ) {
        //     day.chips.push(
        //         this.times.find((t) => t.id === this.selectedTime)
        //     );
        // }

        day.chips = [this.times.find((t) => t.id === this.selectedTime)];
    }

    submitAppointments() {
        this.modalController.dismiss(this.setAppointments);
    }

    private removeExistingAppointmentForDay() {
        const existingAppointmentForDay = this.setAppointments.findIndex((exa) => {
            return exa.day === this.selectedDay;
        });
        if (existingAppointmentForDay > -1) {
            this.setAppointments.splice(existingAppointmentForDay, 1);
        }
    }

    private clearSelection() {
        for (const t of this.times) {
            t.selected = false;
        }
        for (const d of this.days) {
            d.selected = false;
        }
        this.selectedDay = null;
        this.selectedTime = null;
    }
}
