import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { VoiceRecordPage } from './voice-record.page';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TopbarComponentModule } from '../../components/molecules/topbar/topbar.component.module';
import { TranslateModule } from '@ngx-translate/core';
import { AudioComponentModule } from '../../components/organisms/audio/audio.component.module';
import { RecordingComponentModule } from '../../components/organisms/recording/recording.component.module';
import { StepperComponentModule } from '../../components/organisms/stepper/stepper.component.module';

const routes: Routes = [
    {
        path: '',
        component: VoiceRecordPage,
    },
];

@NgModule({
    declarations: [VoiceRecordPage],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        IonicModule,
        TopbarComponentModule,
        TranslateModule,
        AudioComponentModule,
        RecordingComponentModule,
        StepperComponentModule,
    ],
})
export class VoiceRecordModule {}
