import { animate, style, transition, trigger } from '@angular/animations';

const SHRINK_LEAVE = trigger('shrinkLeave', [
    transition(':leave', [animate(100, style({ width: 0, height: 0, opacity: 0 }))]),
]);

const CHAT_MESSAGE_APPEAR = trigger('chatMessageAppear', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translate3d(0px, 10px, 0px)' }),
        animate('.1s ease-out', style({ opacity: 1, transform: 'translate3d(0px, 0px, 0px)' })),
    ]),
]);

export const ANIMATIONS = {
    SHRINK_LEAVE,
    CHAT_MESSAGE_APPEAR,
};
