<div class="legal">
    <ion-list>
        <ion-item *ngIf="currentLegalDocuments?.privacyPolicy">
            <ion-label class="ion-text-wrap">
                {{ 'legal-documents.accept.privacy-policy' | translate }}

                <br />

                <a (click)="openLegalDocumentByURL(currentLegalDocuments.privacyPolicy.url)">
                    {{ 'legal-documents.name.privacy-policy' | translate }} v{{
                        currentLegalDocuments.privacyPolicy.version
                    }}
                </a>
            </ion-label>

            <ion-toggle
                color="primary"
                [(ngModel)]="legalCheckboxes.privacyPolicy"
                (ionChange)="onChange()"></ion-toggle>
        </ion-item>
        <ion-item *ngIf="currentLegalDocuments?.evoteTermsOfUse">
            <ion-label class="ion-text-wrap">
                {{ 'legal-documents.accept.evoteTermsOfUse' | translate }}

                <br />

                <a (click)="openLegalDocumentByURL(currentLegalDocuments.evoteTermsOfUse.url)">
                    {{ 'legal-documents.name.evoteTermsOfUse' | translate }} v{{
                        currentLegalDocuments.evoteTermsOfUse.version
                    }}
                </a>

                <br />

                <p *ngIf="showOptional" style="font-style: italic">
                    {{ 'legal-documents.accept.evoteTermsOfUse.optional' | translate }}
                </p>
            </ion-label>

            <ion-toggle
                color="primary"
                [(ngModel)]="legalCheckboxes.evoteTermsOfUse"
                (ionChange)="onChange()"></ion-toggle>
        </ion-item>

        <ion-item *ngIf="currentLegalDocuments?.userTermsOfUse">
            <ion-label class="ion-text-wrap">
                {{ 'legal-documents.accept.userTermsOfUse' | translate }}

                <br />

                <a (click)="openLegalDocumentByURL(currentLegalDocuments.userTermsOfUse.url)">
                    {{ 'legal-documents.name.userTermsOfUse' | translate }} v{{
                        currentLegalDocuments.userTermsOfUse.version
                    }}
                </a>
            </ion-label>

            <ion-toggle
                color="primary"
                [(ngModel)]="legalCheckboxes.userTermsOfUse"
                (ionChange)="onChange()"></ion-toggle>
        </ion-item>
    </ion-list>
</div>
