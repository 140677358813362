import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Chip } from 'src/app/models/chip';

@Component({
    selector: 'app-list-with-chip',
    templateUrl: './list-with-chip.component.html',
    styleUrls: ['./list-with-chip.component.scss'],
})
export class ListWithChipComponent {
    @Input() items: {
        id: string;
        text: string;
        selected: boolean;
        chips: Chip[];
    }[];
    @Input() disableRowTitle: boolean = false;
    @Input() canClose: boolean = true;
    @Output() itemSelected: EventEmitter<string> = new EventEmitter<string>();
    @Output() chipClicked: EventEmitter<{
        dayId: string;
        chipId: string;
    }> = new EventEmitter<{
        dayId: string;
        chipId: string;
    }>();

    activeItemId: string;

    itemClicked(id: string) {
        this.activeItemId = id;
        this.itemSelected.emit(id);
    }

    chipSelected(dayId: string, chipId: string, event) {
        event.stopPropagation();
        this.chipClicked.emit({ dayId, chipId });
    }
}
