import { Injectable } from '@angular/core';
import { Document } from '../../models/document';
import { DocumentUtilService } from '../document-util/document-util.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetController, Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class DesktopFilepickerService {
    constructor(
        private platform: Platform,
        private actionSheet: ActionSheetController,
        private translate: TranslateService,
        private docService: DocumentUtilService
    ) {}

    pickOne(fileTypes: string, size?: { width: number; height: number }): Promise<Document> {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', fileTypes);
            input.setAttribute('multiple', 'false');
            input.addEventListener('change', async (event: any) => {
                if (event.target.files && event.target.files.length) {
                    resolve(this.readFile(event.target.files[0]));
                } else {
                    reject();
                }
                input.remove();
            });
            input.style.display = 'none';
            document.querySelector('body').appendChild(input);
            input.click();
        });
    }

    pickMultiple(fileTypes): Promise<Document[]> {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', fileTypes);
            input.setAttribute('multiple', 'true');
            input.addEventListener('change', async (event: any) => {
                if (event.target.files && event.target.files.length) {
                    resolve(Object.keys(event.target.files).map((key) => this.readFile(event.target.files[key])));
                } else {
                    reject();
                }
                input.remove();
            });
            input.style.display = 'none';
            document.querySelector('body').appendChild(input);
            input.click();
        });
    }

    async getImageFromSelection() {
        let source;
        const dialogue = await this.actionSheet.create({
            buttons: [
                {
                    text: this.translate.instant('profile.profile-picture-camera'),
                    handler: () => {
                        source = 1;
                    },
                },
                {
                    text: this.translate.instant('profile.profile-picture-album'),
                    handler: () => {
                        source = 2;
                    },
                },
                {
                    text: this.translate.instant('chat.cancel'),
                    role: 'cancel',
                },
            ],
        });
        await dialogue.present();
        await dialogue.onDidDismiss();
        if (source === 1 || source === 2) {
            const file = await this.docService.getImgFromDevice(source);
            return [file];
        }
        return [];
    }

    private readFile(file) {
        return {
            name: file.name,
            file: file,
            mimetype: file.type,
        };
    }

    calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    downsizeImage(imageAsDataUrl: string, type, width: number, height: number): Promise<string> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageAsDataUrl;
            image.onload = () => {
                const aspectSize = this.calculateAspectRatioFit(image.width, image.height, width, height);

                const canvas = document.createElement('canvas');
                canvas.width = aspectSize.width;
                canvas.height = aspectSize.height;

                const canvas2DContext = canvas.getContext('2d');
                canvas2DContext.drawImage(image, 0, 0, aspectSize.width, aspectSize.height);
                resolve(canvas2DContext.canvas.toDataURL(type));
            };
        });
    }
}
